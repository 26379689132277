import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Container,
  Grid,
  makeStyles,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
  Tooltip,
  Avatar,
  TextField,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Popover,
  TableCell,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Badge,
	FormControl,
	InputLabel,
	Input
} from '@material-ui/core';
import { ButtonGroup } from '@atlaskit/button';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Sales from './Sales';
import ShipmentsConfirmed from './ShipmentsConfirmed';
import SlaCarriers from './SlaCarriers';
import Toolbar from './Toolbar';
import BlockMoney from './BlockMoney';
import BlockReview from './BlockReview';
import BlockDeadline from './BlockDeadline';
import ReactStars from "react-rating-stars-component";
import BlockMap from './BlockMap';
import BlockMapVector from './BlockMapVector';
import StatusGeralBlock from './StatusGeralBlock';
import Header from 'src/layouts/DashboardLayout/Header';
import StatusGeralBlockGrouped from './StatusGeralBlockGrouped';
import { Message } from '@material-ui/icons';
import BlockMapVectorPrazo from './BlockMapVectorPrazo';
import PercentageByCarrier from './PercentageByCarrier';
import EmptyState from '@atlaskit/empty-state';
import Button from '@atlaskit/button/standard-button';
import Select  from '@atlaskit/select';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';
import KpiByCarrier from './KpiByCarrier';
import { isSuperUser } from 'src/common/auth';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [since, setSince] = useState(30);
  const [dateStart, setDateStart] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
  
  const [shipmentConfirmed, setShipmentConfirmed] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [ordersConfirmed, setOrdersConfirmed] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [amountFreight, setAmountFreight] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [amountFreightTicket, setAmountFreightTicket] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [totalOrders, setTotalOrders] = useState([]);
  const [reviewCounter, setReviewCounter] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataBarCarriers, setDataBarCarriers] = useState(null);
  const [delayed, setDelayed] = useState({});
  const [alerts, setAlerts] = useState({});
  const apiService = new ApiService();
  const [carriers, setCarriers] = useState([]);
  const [selectedCarriers, setSelectedCarriers] = useState(null);
  const [percentageByCarrier, setPercentageByCarrier] = useState(null)

  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
 const [sla, setSla] = useState([]);
 const [kpiData, setKpiData] = useState([]);
 const [kpiMetric, setKpiMetric] = useState([]);

 const [deadline, setDeadline] = useState(0);
 const [stateSla, setStateSla] = useState([]);
 const [isLoading, setIsLoading] = useState(false);
 const alertMap = {
  1: {label: 'Avaria', description: 'Uma avaria foi identificada.'},
  2: {label: 'Extravio', description: 'Uma extravio foi identificado.'},
  3: {label: 'Mercadoria Retida', description: 'Aparentemente a mercadoria está retida na fiscalização.'},
  4: {label: 'outros', description: 'Aparentemente existe algum alerta no rastreio do pedido que merece verificação'}
}
  const getCarriers = () => {
    apiService
      .getCarriers()
      .then((res) => setCarriers(res.data));
  };
  
  const changeCarrier = (ev, t) => {
    setSelectedCarriers(ev.map((e) => e.value));
  };

  const [downloading, setDownloading] = useState(false);

  const downloadFile = (data, fileName, type = "text/plain") => {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  const newOrder = () => {
    const apiService = new ApiService();
    setDownloading(true);
    apiService.generateReport('orders', {date_start: dateStart, date_end: dateEnd, carrier: selectedCarriers})
    .then(function() {
      enqueueSnackbar('Relatório solicitado com sucesso, você receberá ele por e-mail em instantes.', {
        variant: 'success',
      });
    });
  };

  const newOrderSku = () => {
    setDownloading(true);
    const apiService = new ApiService();
    apiService.generateReport('orders', {date_start: dateStart, date_end: dateEnd, carrier: selectedCarriers, group_by_sku: true})
    .then(function () {
      enqueueSnackbar('Relatório solicitado com sucesso, você receberá ele por e-mail em instantes.', {
        variant: 'success',
      });
    });
  };

  const reloadReviews = (newValue) => {
    setReviews([]);
    setLoading(true);
    apiService
      .getReviews({
        rate: (5 - newValue),
	    date_start: dateStart,
	    date_end: dateEnd,
		carrier: selectedCarriers
      })
      .then((res) => {
        setReviews(res.data);
        setLoading(false);
      });
  }

  const handleClickOpen = () => {
    setOpen(true);
    reloadReviews(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    reloadReviews(newValue);
  };

  useEffect(() => {
  	getCarriers();
  }, []);
  
  const applyFilters = () => {
	  reloadDashboards();
  };
  
  const reloadDashboards = () => {
	  setIsLoading(true);
    apiService
      .getDashboard({date_start: dateStart, date_end: dateEnd, carrier: selectedCarriers})
      .then((res) => {
        setOrdersConfirmed(res.orders_confirmed);
        setAmountFreight(res.amount_freight);
        setAmountFreightTicket(res.amount_freight_ticket);
        setTotalOrders(res.shipments_per_day);
        setShipmentConfirmed(res.shipments_confirmed);
        setReviewCounter(res.review_counter);
		setIsLoading(false);
      });

 

    apiService
      .getOrders({
        deadline: 'delayed',
		date_start: dateStart,
		date_end: dateEnd,
		carrier: selectedCarriers
      }, 20, 1)
      .then((res) => {
        setDelayed(res);
      })
      .catch(() => setDelayed({}));

      apiService
      .getOrders({
        alerts: '99',
		date_start: dateStart,
		date_end: dateEnd,
		carrier: selectedCarriers
      }, 50, 1)
      .then((res) => {
        setAlerts(res);
      })
      .catch(() => setAlerts({}));

    apiService
      .getShipmentsByStatus({ date_start: dateStart, date_end: dateEnd, carrier: selectedCarriers })
      .then((res) => {
        setDataBar(res.orders);
        setDataBarCarriers(res.by_carrier);
		setPercentageByCarrier(res.percentage_by_carrier);
      });

      apiService
        .getCarriersSla({
  		  date_start: dateStart,
  		  date_end: dateEnd,
  		  carrier: selectedCarriers
        })
        .then((res) => {
          setSla(res.sla);
		  setDeadline(res.deadline_avg);
        });
		
      apiService
        .getSlaState({
          date_start: dateStart,
			date_end: dateEnd,
			carrier: selectedCarriers
        })
        .then((res) => {
          setStateSla(res.states ? res.states : []);
        });

           apiService
              .getKpiCarriers({
                date_start: dateStart,
                date_end: dateEnd
              })
              .then((res) => {
                setKpiData(res);
                if (res) {
                  let first = Object.keys(res)[0];
                  setKpiMetric(Object.keys(res[first])); 
                }
              });
          
   };
  


  useEffect(() => {
	  reloadDashboards();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard - Flixlog"
    >
      <Header
        title="Dashboard"
        description="Aqui você encontra os principais indicadores e ações a serem tomadas na sua operação."
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          }
        ]}
      />

      <Container maxWidth={false}>

        <Grid
          container
          spacing={3}
          style={{position: 'sticky', top: 0, background: '#fff', boxShadow: '0 0 5px #ddd', width: '100%', margin: 0, zIndex: 2, marginBottom: 20}}
        >
		  <Grid item lg={9}>
  	 <div style={{ marginRight: 20,  display: 'flex',  alignItems: 'center' }}>
		<strong style={{paddingRight: 20}}>Período</strong> 
		
  <div style={{display: 'flex'}}> 
        <TextField
          name={"date_start"}
		type="date"
		value={dateStart}
          placeholder={"Data inicial"}
          onChange={(ev) => setDateStart(ev.target.value)}
           style={{ border: '1px solid rgb(221 221 221) !important;', marginRight: 20 }}
        />
        <TextField
          name={"date_end"}
		type="date"
          placeholder={"Data Final"}
		value={dateEnd}
		onChange={(ev) => setDateEnd(ev.target.value)}
		max={"2023-05-30"}
           style={{ border: '1px solid rgb(221 221 221) !important' }}
        /></div>
		   
		   
<div style={{ marginRight: 20, width: 300 }}>
                  <Select
                    isOptionSelected
		   			isMulti
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    options={carriers.map((carrier) => ({ value: carrier.id, label: `${carrier.id} - ${carrier.canonical_name.toUpperCase()}` }))}
                    style={{marginLeft: 10, border: '1px solid rgb(221 221 221) !important' }}
                    isClearable
                    placeholder={"Selecione as transportadoras"}
                    onChange={changeCarrier}

                  /></div>
<div style={{ marginRight: 20 }}>
				<Button onClick={applyFilters} isDisabled={isLoading} appearance={'primary'}>{isLoading ? 'Carregando ...' : 'Aplicar Filtros'}</Button>
				</div>
	</div> 
		   
		   
        
		   
		  </Grid>
          <Grid item lg={3}>
          <ButtonGroup>
            <Button onClick={newOrder} appearance="primary" style={{float: 'right'}} isDisabled={downloading} >{'Exportar pedidos'}</Button>
            <Button onClick={newOrderSku} appearance="primary" style={{float: 'right'}} isDisabled={downloading} >{'Exportar com SKUs'}</Button>
          </ButtonGroup>
        	 
			 
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ShipmentsConfirmed
              title="PEDIDOS CONFIRMADOS"
              values={ordersConfirmed}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <ShipmentsConfirmed
              title="VOLUMES CONFIRMADOS"
              values={shipmentConfirmed}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockMoney
              title="GASTO COM FRETE"
              values={amountFreight}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockMoney
              title="TICKET MÉDIO POR PEDIDO"
              values={amountFreightTicket}
              since={since}
            />
          </Grid>



          <Grid
            item
              lg={5}
              xs={12}
          >
            <StatusGeralBlock data={dataBar} />
          </Grid>
          <Grid
            item
            lg={7}
            xs={12}
          >
            <StatusGeralBlockGrouped title="Pedidos - Transportadora x Status" description="" categories={dataBarCarriers?.categories} series={dataBarCarriers?.series} />
          </Grid>
		 
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
          >
            <Card>
              <CardHeader
                title={<>{"Avarias & Extravios"} <Badge color="secondary" style={{ marginLeft: 30, color: 'red' }} badgeContent={alerts?.total} /></>}
              />
              <Divider />
              <CardContent style={{ height: 300, overflow: 'auto' }}>
                {alerts?.total ?

                  <Table>
                    <TableHead>
                      <TableCell>Pedido</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Tipo de alerta</TableCell>
                    </TableHead>
                    <TableBody>
                      {alerts?.data?.map((order) => <>
                        <TableRow>
                          <TableCell><a href={"/orders/" + order.id} target="_blank"><strong>{order.reference}</strong></a></TableCell>
                          <TableCell>{order.to_address.name} <br /> <small>{order.to_address.city}/{order.to_address.state}</small></TableCell>
                          <TableCell> 
                          <StatusIcon
                type={'removed'}
                title={alertMap[order.order_alert_type].label}
                description={alertMap[order.order_alert_type].description} /> </TableCell>
                        </TableRow>
                      </>)}
                    </TableBody>
                  </Table>
                  : <EmptyState
                    header="Nenhum alerta identificado :)"
                    description="Nosso sistema identifica possíveis alertas com base nas últimas informações de rastreio do pedido."
                    tertiaryAction={<a href="/orders" target="_blank">Ir para pedidos</a>}
                  />}


              </CardContent>
            </Card>

          </Grid>

        <Grid
          item
            lg={4}
            xs={12}
        >
          <PercentageByCarrier data={percentageByCarrier} />
        </Grid>

        
        

          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
          >
            <Card>
              <CardHeader
                title={<>{"Entregas Atrasadas"} <Badge color="secondary" style={{ marginLeft: 30, color: 'red' }} badgeContent={delayed?.total} /></>}
              />
              <Divider />
              <CardContent style={{ height: 300, overflow: 'auto' }}>
                {delayed?.total ?

                  <Table>
                    <TableHead>
                      <TableCell>Pedido</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Estimativa de Entrega</TableCell>
                    </TableHead>
                    <TableBody>
                      {delayed?.data?.map((order) => <>
                        <TableRow>
                          <TableCell><a href={"/orders/" + order.id} target="_blank"><strong>{order.reference}</strong></a></TableCell>
                          <TableCell>{order.to_address.name} <br /> <small>{order.to_address.city}/{order.to_address.state}</small></TableCell>
                          <TableCell> <strong>{moment(order?.delivery?.delivery_date).format('DD/MM')}</strong> </TableCell>
                        </TableRow>
                      </>)}
                    </TableBody>
                  </Table>
                  : <EmptyState
                    header="Nenhum pedido atrasado :)"
                    description="Sempre que um pedido estiver atrasado e não tiver sido entregue ainda ele aparecerá ele aparecerá aqui."
                    tertiaryAction={<a href="/orders" target="_blank">Ir para pedidos</a>}
                  />}


              </CardContent>
            </Card>

          </Grid>

          {isSuperUser() ? <Grid
          item
            lg={8}
            xs={12}
        >
          <KpiByCarrier data={kpiData} metrics={kpiMetric} />
        </Grid> : null}
           

          
          <Grid
            item
            lg={4}
            xs={6}
          >
            <SlaCarriers sla={sla} />
          </Grid>

          <Grid
            item
            lg={6}
            xs={12}
          >
            <BlockMapVector carrier={selectedCarriers} />
          </Grid>

          
          <Grid
            item
            lg={6}
            xs={12}
          >
            <Sales totalOrders={totalOrders} />
          </Grid>


          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockDeadline deadline={deadline} />

          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xl={3}
            xs={12}
          >
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              maxWidth={'lg'}

            >
              <DialogTitle id="scroll-dialog-title">Avaliações de entregas</DialogTitle>

              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label={<ReactStars
                  value={5}
                  size={20}
                  edit={false}
                  activeColor="#ffcc00"
                />} />

                <Tab label={<ReactStars
                  value={4}
                  size={20}
                  edit={false}
                  activeColor="#ffcc00"
                />} />

                <Tab label={<ReactStars
                  value={3}
                  size={20}
                  edit={false}
                  activeColor="#ffcc00"
                />} />

                <Tab label={<ReactStars
                  value={2}
                  size={20}
                  edit={false}
                  activeColor="#ffcc00"
                />} />

                <Tab label={<ReactStars
                  value={1}
                  size={20}
                  edit={false}
                  activeColor="#ffcc00"
                />} />
              </Tabs>

              <DialogContent>
                <DialogContentText
                  tabIndex={-1}
                >

                  <ul class="timeline message">
                    {reviews.map((review) => {
                      return (<li>

                        <span style={{ display: 'flex', width: '50%', alignItems: 'center', textAlign: 'left' }}>
                          <Tooltip enterDelay={300} title={review.user.name} key={review.id}>
                            <Avatar className={classes.purple}>{review.user.name ? review.user.name.substring(0, 1) : ''}</Avatar>
                          </Tooltip>

                          <small style={{ color: '#5a5a5a', fontWeight: 'normal', marginLeft: 10 }} >
                            <a href={`/orders/${review.shipment.order_id}`} style={{ color: '#000', fontWeight: 'bold' }} target="_blank">#{review.shipment.reference}</a> <br />
                            {review.message ? review.message : 'Não deixou mensagem...'}
                          </small>
                        </span>
                        <span class="float-right">{moment(review.created_at).format('DD/MM/YYYY HH:MM')}</span>
                      </li>);
                    })}

                    {loading == false && reviews.length == 0 ? <li>Nenhuma avaliação com {5 - value} estrelas</li> : null}
                    {loading == true ? <li><strong>Carregando mensagens ...</strong></li> : null}
                  </ul>

                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Fechar
                </Button>
              </DialogActions>
            </Dialog>

            <BlockReview
              style={{ cursor: 'pointer' }}
              onClick={handleClickOpen}
              reviews={reviewCounter}
              since={since}
            />

          </Grid>


          <Grid
            item

            xs={12}
          >
            <BlockMap sla={stateSla}/>

          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
