import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewContingencyTablesView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Nova solicitação"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton={true} />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Form
                initialValues={
                  {
                    type: '',
                    weights: [
                      {
                        id: 1,
                        weight: 30,
                        cargo_value: 200
                      },
                      {
                        id: 2,
                        weight: 100,
                        cargo_value: 600
                      }
                    ],
                    zip_origin: '',
                    increase_price_type: null,
                    increase_price_value: 0
                  }
                }
                submitText="ENVIAR"
                onSubmit={(values, weights) => {
                  let vals =  {
                    ...values,
                    increase_price: {
                      type: values.increase_price_type,
                      value: values.increase_price_value,
                    },
                    weights: weights
                  };

                  delete vals['increase_price_type'];
                  delete vals['increase_price_value'];
                  
                  const apiService = new ApiService();
                  return apiService
                    .newBackgroundTask('GenerateContingencyFreightTable', vals)
                    .then((res) => {
                      enqueueSnackbar('Processo iniciado, assim que finalizado, você receberá um e-mail com as instruções', {
                        variant: 'success',
                      });
                      navigate('/settings/contingency-tables');
                    })
                    .catch((res) => {
                      enqueueSnackbar('Ocorreu um erro:' + res.message, {
                        variant: 'error',
                      });
                    });

                  return true;
                }}
              />
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewContingencyTablesView);
