import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  TextareaAutosize,
  InputLabel,
  FormControl
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import ReactStars from 'react-rating-stars-component';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RateDeliveryView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const params = useParams();
  const token = params.token;
  const trackingCode = params.trackingCode;
  const [message, setMessage] = useState('');
  const [rate, setRate] = useState(null);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    apiService
      .tracking(trackingCode)
      .then((res) => {
        setOrder(res);
      }).catch(() => {
        enqueueSnackbar('Não foi encontrado nenhum pedido com o código informado', {
          variant: 'error',
        });
      });
  }, [trackingCode]);

  return (
    <Page
      className={classes.root}
      title="Gostaríamos de saber a sua opnião sobre a entrega."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              rate: 0,
              message: ''
            }}
            onSubmit={(values) => {
              return apiService
                .rateDelivery(token, rate, values.message)
                .then(() => {
                  enqueueSnackbar('Obrigado! Avaliação enviada com sucesso :)', {
                    variant: 'success',
                  });
                }).catch(() => {
                  enqueueSnackbar('Não foi encontrado nenhum pedido com o código informado', {
                    variant: 'error',
                  });
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    {order ? (
                      <Typography
                        color="textPrimary"
                        variant="h2"
                        style={{ fontWeight: 100 }}
                      >
                        Olá {order.to_address.name}, <br />
                      Gostaríamos muito de saber a sua percepção sobre a entrega da transportadora <strong>{order.carrier.name}</strong> 😃.
                      </Typography>
                    ) : null}
                  </Box>


                  <ReactStars
                    value={values.rate}
                    size={24}
                    edit
                    onChange={(ev) => {
                      console.log(ev);
                      setRate(ev);
                    }}
                    activeColor="#ffcc00"
                  />
                  <Typography
                    color="textPrimary"
                    variant="span"
                    style={{ fontWeight: 100 }}
                  >Clique na estrelinha para dar uma nota</Typography>

                  <br />
                  <br />

                  <TextareaAutosize
                    error={Boolean(touched.message && errors.message)}
                    fullWidth
                    helperText={touched.message && errors.message}
                    margin="normal"
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.message}
                    variant="outlined"
                    placeholder="Deixe um comentário (Opcional)"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled
                      fullWidth
                      disabled={isSubmitting || rate < 1}

                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ENVIAR AVALIAÇÃO
                    </Button>
                  </Box>
                </form>
              )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(RateDeliveryView);
