import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ReactStars from "react-rating-stars-component";

const useStyles = makeStyles(() => ({
  root: {}
}));

const BlockReview = ({ className, reviews, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  
  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Avaliações de Entregas"
      />
      <Divider />
      <CardContent>

      

        <Grid container>
          {reviews.sort(function (x, y) {
                return y.total - x.total;
            }).map((rev) => <><Grid item xs={12} style={{display: 'flex',  alignContent: 'center', justifyContent: 'space-between', alignItems: 'center'}}><ReactStars
            value={rev.rate}
            size={24}
            edit={false}
            activeColor="#ffcc00"
          /> <strong>{rev.total} avaliações</strong></Grid></>)}
        </Grid>
      </CardContent>
    </Card>
  );
};

BlockReview.propTypes = {
  className: PropTypes.string
};

export default BlockReview;
