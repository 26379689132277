import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { getOptions } from 'highcharts';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const PercentageByCarrier = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const series = data ? Object.keys(data.percentages) : [];
  const options =  {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 300
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false
    },
	
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Percentual',
        colorByPoint: true,
        data: series.map((item) => {
			return { name: item.toUpperCase(), y: data.percentages[item] }
        })
    }]
};

 

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Distribuição de pedidos por transportadora"
      />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <HighchartsReact
            options={options}
            highcharts={Highcharts}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
            containerProps={{ className: 'chartContainer' }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

PercentageByCarrier.propTypes = {
  className: PropTypes.string
};

export default PercentageByCarrier;
