import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField
} from '@material-ui/core';
import { Edit } from 'react-feather';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  successButton: {
    backgroundColor: '#8BC34A'
  }
}));



const EditDriver = props => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState(props.name);
  const [phone, setPhone] = useState(props.phone);
  const apiService = new ApiService();

  const send = () => {
    apiService
      .changeDriver(props.reference, name, phone, props.isShipment)
      .then((res) => {
        props.props.enqueueSnackbar('Motorista alterado com sucesso', { 
          variant: 'success',
        });
        props.refreshOrder();
      });
  };

  return (
    <div> 
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"Cadastrar motorista de coleta"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField id="driver-name" value={name} onChange={(e) => setName(e.target.value)} label="Nome" variant="outlined" />
            <TextField id="driver-name" value={phone} onChange={(e) => setPhone(e.target.value)} label="Telefone" variant="outlined" />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fechar
          </Button>
          <Button onClick={() => send()} color="primary" autoFocus>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Button color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
          className={classes.successButton}><Edit /> {props.label ? props.label : "Editar"}</Button>
    </div>
  );
};

export default EditDriver;
