import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ReactStars from "react-rating-stars-component";
import ApiService from 'src/common/apiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const BlockDeadline = ({ className, deadline, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Prazo médio de entrega geral"
      />
      <Divider />
      <CardContent>
        
        <Grid style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <Typography
              color="textPrimary"
              gutterBottom
              variant="h1"
            >{deadline}</Typography>

             <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >DIAS ÚTEIS</Typography>
        </Grid>
     
          
      </CardContent>
    </Card>
  );
};

BlockDeadline.propTypes = {
  className: PropTypes.string
};

export default BlockDeadline;
