import * as actionsTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  token: null,
  user: null,
  authenticated: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionsTypes.SET_AUTHENTICATION_REQUESTED:
          return {
              ...state,
              loading: true,
          };

          case actionsTypes.SET_AUTHENTICATION_SUCCEED:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
                token: action.payload.token,
                authenticated: true
            };

          case actionsTypes.SET_AUTHENTICATION_FAILED:
            return {
                ...state,
                loading: false,
            };
  
      default:
          return state;
  }
};
