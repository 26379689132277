import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditOrderView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cds, setCds] = useState([]);
  const [order, setOrder] = useState(null);
  const apiService = new ApiService();
  const params = useParams();
  const orderId = params.id;


  const reloadCdList = (filters) => {
    apiService.getDistributionCenters(filters).then((res) => {
      setCds(res.data);
    });
  };

  const refreshOrder = () => {
    apiService
      .getOrder(orderId)
      .then((_order) => {
        setOrder(_order);
      });
  }


  useEffect(() => {
    reloadCdList();
    refreshOrder();
  }, []);

  return order ? (
    <Page
      className={classes.root}
      title="Editar Pedido"
    >
      <Container maxWidth={false}>
        <Box>

          <Form
            title={`Editar pedido #${order ? order.reference : '...'}`}
            cds={cds}
            initialValues={{
              id: order.id,
              reference: order.reference,
              address_residential: order.to_address.residential ? 1 : 0,
              is_drop_shipping: order.is_drop_shipping,
              from_cd_reference: order.distribution_center.reference,
              address_name: order.to_address.name,
              address_federal_tax_id: order.to_address.federal_tax_id,
              address_phone: order.to_address.phone,
              address_email: order.to_address.email,
              address_zip: order.to_address.zip,
              address_street1: order.to_address.street1,
              address_number: order.to_address.number,
              address_street2: order.to_address.street2,
              address_neighborhood: order.to_address.neighborhood,
              address_city: order.to_address.city,
              address_state: order.to_address.state,
              address_country: 'BRA',
              shipments: order.shipments.map((res) => {
                return {
                  id: res.id,
                  from_cd_reference: res.distribution_center.reference,
                  width: res.width * 100,
                  height: res.height * 100,
                  length: res.length * 100,
                  weight: res.weight,
                  quantity: res.quantity,
                  cargo_value: res.cargo_value
                };
              })
            }}
            submitText="SALVAR"
            onSubmit={(values, parcels, products) => {
              var hasParcel = parcels.filter((parcel) => {
                if (products[parcel.reference]) {
                  return true;
                }
                return parcel.width && parcel.length && parcel.height && parcel.weight && parcel.cargo_value && parcel.quantity;
              });

              if (!hasParcel.length) {
                enqueueSnackbar('Preencha todos os dados dos volumes.', {
                  variant: 'error',
                });
                return true;
              }

              if (values.is_drop_shipping) {
                var hasParcelWithoutOrigin = parcels.filter((parcel) => {
                  if (!parcel.from_cd_reference) {
                    return true;
                  }
                  return false;
                });

                if (hasParcelWithoutOrigin.length) {
                  enqueueSnackbar('Selecione a origem de cada volume!', {
                    variant: 'error',
                  });
                  return true;
                }
              }

              const apiService = new ApiService();

              return apiService
                .updateOrder({
                  id: values.id,
                  reference: values.reference,
                  from_cd_reference: values.from_cd_reference,
                  shipments: parcels.map((parcel) => {
                    if (!parcel['reference']) {
                      delete parcel['reference'];
                    }



                    return {
                      id: parcel['id'],
                      reference: parcel['reference'],
                      from_cd_reference: parcel['from_cd_reference'],
                      quantity: parseFloat(parcel['quantity']),
                      width: parseFloat(parcel['width'] / 100),
                      height: parseFloat(parcel['height'] / 100),
                      length: parseFloat(parcel['length'] / 100),
                      weight: parseFloat(parcel['weight']),
                      cargo_value: parseFloat(parcel['cargo_value'])
                    };
                  }),
                  to_address: {
                    residential: values.address_residential,
                    name: values.address_name,
                    federal_tax_id: values.address_federal_tax_id,
                    phone: values.address_phone,
                    email: values.address_email,
                    zip: values.address_zip,
                    street1: values.address_street1,
                    number: values.address_number,
                    street2: values.address_street2,
                    neighborhood: values.address_neighborhood,
                    city: values.address_city,
                    state: values.address_state,
                    country: 'BRA'
                  }
                })
                .then((res) => {
                  enqueueSnackbar('Pedido atualizado com sucesso', {
                    variant: 'success',
                  });
                  localStorage.removeItem('lastQuote');
                  navigate(`/orders/${values.id}`);
                })
                .catch((res) => {
                  enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                    variant: 'error',
                  });
                });
            }}
          />

        </Box>
      </Container>
    </Page>
  ) : null;
};

export default withSnackbar(EditOrderView);
