import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import ApiService from 'src/common/apiService';
import { JsonToTable } from "react-json-to-table";
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#000',
    height: 48
  },
  toolbar: {
    minHeight: 48,
    '& .MuiToolbar-regular': {
      minHeight: 48
    }
  },
  avatar: {
    width: 60,
    height: 60
  },
  logo: {
    height: 15
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const apiService = new ApiService();

  const loadNotifications = () => {
    setOpenDrawer(true);
    setLoadingNotifications(true);
    apiService.getNotifications({}, 20, 1).then((res) => {
      setNotifications(res.data);
      setLoadingNotifications(false);
    });
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/dashboard">
          <Logo className={classes.logo} />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>

          <IconButton color="inherit">
            <NotificationsIcon style={{ color: '#fff' }} onClick={() => loadNotifications()} />
          </IconButton>

          <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
            {loadingNotifications ? 'Carregando...' : null}
            <List>
              {notifications.map((notification) => (
                <ListItem>
                  <ListItemText primary={<JsonToTable json={notification.property}></JsonToTable>} secondary={moment(notification.created_at).format('DD/MM/YYYY HH:mm:ss')} />                  
                </ListItem>)
              )}
            </List>
          </Drawer>

          <RouterLink to="/logout">
            <IconButton color="inherit">
              <InputIcon style={{ color: '#fff' }} />
            </IconButton>
          </RouterLink>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
