import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import ApiService from 'src/common/apiService';

import Page from 'src/components/Page';
import StepOne from './StepOne';
import Sheet from './Sheet';
import Toolbar from './Toolbar';
import NewCarrier from './NewCarrier';
import { useSnackbar, withSnackbar } from 'notistack';

import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FreightTablesView = ({enqueueSnackbar}) => {

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [file, setFile] = useState();
  const [carrier, setCarrier] = useState(null);
  const [cd, setCd] = useState(null);
  const [region, setRegion] = useState(null);
  const [cubage, setCubage] = useState(300);
  const [applyIcms, setApplyIcms] = useState(true);
  const [readjustment, setReadjustment] = useState(null);
  const [tipo, setTipoCorredor] = useState('rodo');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [submiting, setSubmiting] = useState(false);

  const submitTable = () => {

    const errors = {
      file: 'Selecione um arquivo para enviar',
      carrier: 'Selecione a transportadora',
      region: 'Selecione a região',
      cubage: 'Digite o fator de cubagem',
    };

    if (!file) {
      enqueueSnackbar(errors['file'], { variant: 'error' });
      return;
    }
    if (!carrier) {
      enqueueSnackbar(errors['carrier'], { variant: 'error' });
      return;
    }
    if (!region) {
      enqueueSnackbar(errors['region'], { variant: 'error' });
      return;
    }
    if (!cubage) {
      enqueueSnackbar(errors['cubage'], { variant: 'error' });
      return;
    }

    setSubmiting(true);

    const apiService = new ApiService();

    apiService.importFreightTable(
      {
        carrier,
        region,
        cd,
        cubage,
        file,
        tipo,
        applyIcms,
        readjustment
      }
    ).then(() => {
      setSubmiting(false);
      setSuccess(true);
      setError(null);
    }).catch((res) => {
      setSubmiting(false);
      setSuccess(false);
      setError(res.message);
    })
  }

  const openModalCarrier = () => {
    setOpen(true);
  }

  return (
    <Page
      title='Tabela de Frete'
      className={classes.root}
    >
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{"Adicionar Anexo"}</DialogTitle>
        <DialogContent>
          <NewCarrier />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth={false}>
        <Toolbar title="Nova tabela de frete" hideButtons />
        <Box mt={3}>
          <StepOne
            params={{
              carrier,
              region,
              cd,
              cubage,
              file,
              tipo,
              applyIcms,
              readjustment
            }}
            setFile={setFile}
            setCarrier={setCarrier}
            setRegion={setRegion}
            setCd={setCd}
            setReadjustment={setReadjustment}
            setCubage={setCubage}
            setTipoCorredor={setTipoCorredor}
            openModalCarrier={openModalCarrier} />
        </Box>

        <Card>
          <CardHeader
            title="Etapa 3 - Validar e Salvar"
            subheader="Clique no botão abaixo para enviar a tabela"
          />
          <Divider />
          <CardContent>
            <Grid container>

              {success ? <Alert severity="success">
                <AlertTitle>Tabela cadastrada com sucesso</AlertTitle>

                A tabela foi cadastrada com sucesso, vá até a listagem de tabelas para ativar!</Alert> : null}

              {error ? <Alert severity="error" style={{ color: 'red' }}>
                <AlertTitle>Oops! Ocorreu um erro.</AlertTitle>

                Encontramos o seguinte erro: {error}</Alert> : null}

                <br />

              <Button color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: 30, backgroundColor: '#2c3357', color: '#fff' }}
                disabled={submiting}
                onClick={submitTable}>{submiting ? 'AGUARDE ...': 'ENVIAR TABELA'}</Button>

            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}


export default withSnackbar(FreightTablesView);
