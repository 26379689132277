import React, { useState, useEffect, useCallback } from 'react';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import {
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
  Box
} from '@material-ui/core';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';

import ApiService from 'src/common/apiService';
import DataTable from '../../../../layouts/DashboardLayout/DataTables';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { ExternalLink, Flag, MapPin } from 'react-feather';
import { LoadingButton } from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import AvatarGroup from '@atlaskit/avatar-group';
import { FlagRounded, WarningRounded } from '@material-ui/icons';
import ProgressBar from '@atlaskit/progress-bar';
import InlineMessage from '@atlaskit/inline-message';
import queryString from 'query-string';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const OrdersList = (props) => {

  const classes = useStyles();
  const apiService = new ApiService();
  const [orders, setOrders] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(props.limit ? props.limit : 20);
  const [page, changePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(props.pickupId ? { pickup_id: props.pickupId, company_id: props.companyId } : props.filters ? props.filters : {
	  created_at_start: moment().subtract(40, 'days').format('YYYY-MM-DD'),
	  created_at_end: moment().format('YYYY-MM-DD')
  }
  );
  const [carriers, setCarriers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  const [selectedRows, setSelectedRowsIds] = useState(props.selectedRows ? props.selectedRows : []);
  const params = queryString.parse(window.location.search);


  const [isOpen, setIsOpen] = useState(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const [batchInProcess, setBatchInProcess] = useState(false);
    const [batchResponse, setBatchResponse] = useState('');
	
	
	
  const handleSelectAll = (event) => {
    let rowIds;
    let entities = [];

    if (event.target.checked) {
      rowIds = orders.map((order) => order.id);
      entities = orders;
    } else {
      rowIds = [];
      entities = [];
    }

    setSelectedRowsIds(rowIds);
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
    if (props.onSelectEntities) {
      props.onSelectEntities(entities);
    }
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);

    if (props.onSelect) {
      props.onSelect(rowIds);
    }

    if (props.onSelectEntities) {
      props.onSelectEntities(orders.filter((order) => rowIds.indexOf(order.id) !== -1));
    }
  };

  const [alerts, setAlerts] = useState([
    { value: 99, label: 'Todos', badge: 'removed' },
    { value: 1, label: 'Avarias', label_description: 'Avaria', badge: 'removed'  },
    { value: 2, label: 'Extravios',  label_description: 'Extravio', badge: 'removed' },
    { value: 3, label: 'Mercadoria Retida',  label_description: 'Mercadoria Retida', badge: 'removed'},
    { value: 4, label: 'Outros',  label_description: 'Outros', badge: 'removed'}
  ]);


  const [statuses, setStatuses] = useState([
    { value: 1, label: 'Aguardando Nota', badge: 'new' },
    { value: 91, label: 'Aguardando Solicitação de Coleta', badge: 'new' },
    { value: 2, label: 'Aguardando aceite', badge: 'inprogress' },
    { value: 6, label: 'Coleta aceita', badge: 'success' },
    { value: 3, label: 'Em trânsito', badge: 'success' },
    { value: 4, label: 'Saiu para entrega', badge: 'success' },
    { value: 5, label: 'Entregue', badge: 'success' },

    { value: 12, label: 'Aguardando Cotação', badge: 'removed' },
    { value: 11, label: 'Aguardando Aprovação', badge: 'moved' },
    { value: 7, label: 'Em Devolução', badge: 'moved' },
    { value: 14, label: 'Devolvido', badge: undefined },
    { value: 8, label: 'Falha', badge: 'removed' },
    { value: 9, label: 'Cancelado', badge: undefined },
    { value: 10, label: 'Erro', badge: 'removed' },
  ]);

  const deadlineStatus = [
    { value: 'delayed', label: 'Entregas Atrasadas' },
    { value: 'today', label: 'Entregas para hoje' },
    { value: 'delivered_early', label: 'Entregues antes do prazo' },
    { value: 'delivered_late', label: 'Entregues com atraso' }
  ];

  const channelIconsUrl = (channel) => {
    var urls = {
      madeiramadeira: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/madeiramadeira.png',
      tiny: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/tiny-logo.png',
      bling: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/bling-logo.png',
      tray: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/logo-tray.png',
      b2w: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/skyhub.png',
      magalu: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/magalu-logo.jpg',
      viavarejo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/viavarejo.png',
      woo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/woo-logo.jpeg',
      'tray-corp': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      'fbitsws': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      omie: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/omie-logo.png',
      simplo7: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/simplo7-logo.png',
      leroy: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/leroy-logo.png',
      nuvemshop: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/nuvemshop-logo2.png',
      mercadolivre: 'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png',
    }
    return urls[channel.toLocaleLowerCase()] || null;
  }

  const handleFilters = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  }

  const handleFiltersStart = (filter, value) => {
	filter = `${filter}_start`;	
    setFilters({ ...filters, [filter]: value });
  }
  
  const handleFiltersEnd = (filter, value) => {
	filter = `${filter}_end`;	
    setFilters({ ...filters, [filter]: value });
  }
  
  const getCarriers = () => {
    apiService
      .getCarriers(true)
      .then((res) => setCarriers(res.data));
  };

  const getCompanies = () => {
    apiService
      .getCompanies()
      .then((res) => setCompanies(res.data));
  };

  const reloadOrders = (filters, sort, n_page) => {
    setIsLoading(true);
	const _page = n_page ? n_page : page;
	
    if (params && params.pickup) {
		filters['pickup_id'] =  params.pickup;
    }
	
    apiService
      .getOrders(filters, limit, _page, props.isPickupPage)
      .then((res) => {
        setOrders(res.data);
        setTotalRows(res.total);
        setIsLoading(false);
      });
  };

  const downloadFile = (data, fileName, type = "text/plain") => {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
  
  const deliver = (order) => {
	if (!window.confirm('Esse dado será atualizado assim que a transportadora der baixa na Entrega. Tem certeza que deseja alterar essa informação manualmente?')) {
		return;
	}
    apiService
      .deliver(order.reference, true)
      .then((res) => {
        props.enqueueSnackbar(order.reference + ' - Pedido marcada como entregue com sucesso!', {
          variant: 'success',
        });
		reloadOrders(filters);
      }).catch((error) => {
        props.enqueueSnackbar(order.reference + ' - ' + error.message, {
          variant: 'error',
        });
      });
  };
  
  const markInReturn = (order) => {
  	if (!window.confirm('Tem certeza que deseja alterar essa informação manualmente? Essa ação não poderá ser desfeita.')) {
  		return;
  	}
      apiService
        .inReturn(order.reference, true)
        .then((res) => {
          props.enqueueSnackbar(order.reference + ' - Pedido marcada como EM DEVOLUÇÂO!', {
            variant: 'success',
          });
  		reloadOrders(filters);
        }).catch((error) => {
          props.enqueueSnackbar(order.reference + ' - ' + error.message, {
            variant: 'error',
          });
        });
  }
  
  const inTransit = (order) => {
  	if (!window.confirm('Esse dado será atualizado assim que a transportadora emitir o CTE. Tem certeza que deseja alterar essa informação manualmente?')) {
  		return;
  	}
    apiService
      .goInTransit(order.reference, true)
      .then((res) => {
        props.enqueueSnackbar(order.reference + ' - Pedido marcada como em trânsito com sucesso!', {
          variant: 'success',
        });
		reloadOrders(filters);
      }).catch((error) => {
        props.enqueueSnackbar(order.reference + ' - ' + error.message, {
          variant: 'error',
        });
      });
  };
  
  const cancelOrder = (order) => {
  	if (!window.confirm('Tem certeza que deseja cancelar esse pedido? Essa ação não poderá ser desfeita.')) {
  		return;
  	}
    apiService
      .cancelOrderFromId(order.id)
      .then((res) => {
        props.enqueueSnackbar(order.reference + ' - Pedido marcada como em trânsito com sucesso!', {
          variant: 'success',
        });
		reloadOrders(filters);
      }).catch((error) => {
        props.enqueueSnackbar(order.reference + ' - ' + error.message, {
          variant: 'error',
        });
      });
  };
  
  const batch = (action, rows) => {
	  var messageMap = {
		  cancel: 'Tem certeza que deseja cancelar os pedidos selecionados? Essa ação não poderá ser desfeita.',
		  inTransit: 'Tem certeza que deseja marcar como em trânsito os pedidos selecionados?',
		  inReturn: 'Tem certeza que deseja marcar como em devolução os pedidos selecionados? Essa ação não poderá ser desfeita.',
		  deliver: 'Esse dado será atualizado assim que a transportadora der baixa na Entrega. Tem certeza que deseja alterar essa informação manualmente em todos os pedidos selecionados?'
	  }
    	if (!window.confirm(messageMap[action])) {
    		return;
    	}
		
	  const apiService = new ApiService;
	  setBatchInProcess(true);
	  openModal();
	  apiService
	  	.batchOrders(action, rows)
	  	.then((res) => alert(res))
        .catch((error) => {
			setBatchResponse(error.response.data.message.split('\n').map((mm) => mm == '________________________' ? <><small><br/><hr/></small><br/></> : <><small>{mm}</small><br/></>));
			setBatchInProcess(false);
			applyFilters();
			setSelectedRowsIds([]);
        });
  }
  
  const exportReport = () => {
    setIsLoading(true);
    const apiService = new ApiService();
	const date = new Date().getTime();
    apiService
      .generateReport('orders', filters)
      .then((res) => {
        props.enqueueSnackbar('Relatório solicitado com sucesso, você receberá ele por e-mail em instantes.', {
          variant: 'success',
        });
	    setIsLoading(false);
      }).catch(() => {
  	    setIsLoading(false);
      });
  };
  
  const applyFilters = () => {
      reloadOrders(filters, 1);
  }
  
  useEffect(() => {
    reloadOrders(filters);
  }, [limit, page, props.reloadOrders]);

  useEffect(() => {
    getCarriers(true);
    getCompanies();
  }, []);

  return (
    <>

<ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal} width={'large'}>
            <ModalHeader>
			<ModalTitle>{batchInProcess ? 'Ação em massa em processamento' : 'Resultado da ação em massa'}</ModalTitle>
             </ModalHeader>
            <ModalBody>
			
<InlineMessage appearance="warning" secondaryText="Nenhuma notificações será enviada">
      <p>
        Ações em massa não irão gerar e-mails de notificação para os destinatários, se desejar que a alteração envie a notificação, execute a ação na própria linha do pedido um a um.
      </p>
    </InlineMessage>
			<br />
			<br />
 			{batchInProcess ? <ProgressBar ariaLabel="Loading issues" isIndeterminate /> : batchResponse}
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={closeModal}>
                Fechar
              </Button>
         
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
		
      <DataTable
        rows={orders}
        caption={props.caption}
        filters={
          props.miniVersion ? [] :
          [
            {
              placeholder: 'Buscar por pedido, nota ou ID',
              type: 'search',
              filter: 'reference',
              onChange: handleFilters,
              onEnter: (filter, value) => {
				  handleFilters(filter, value);
				  applyFilters();
              },
            },
            {
              placeholder: 'Status',
              type: 'select',
              filter: 'status',
              options: statuses,
              onChange: handleFilters
            },
            {
              placeholder: 'Transportadora',
              type: 'select',
              filter: 'carrier',
              options: carriers.map((carrier) => ({ value: carrier.id, label: `${carrier.id} - ${carrier.canonical_name.toUpperCase()}` })),
              onChange: handleFilters
            },
            {
              placeholder: 'Prazo de Entrega',
              type: 'select',
              filter: 'deadline',
              width: 200,
              options: deadlineStatus,
              onChange: handleFilters
            },
            {
              placeholder: 'Período',
              type: 'date',
              filter: 'created_at',
				value_start: filters.created_at_start,
				value_end: filters.created_at_end,
              width: 300,
			  onChangeStart: handleFiltersStart,
              onChangeEnd: handleFiltersEnd
            },
            {
              placeholder: 'Alertas',
              type: 'select',
              filter: 'alerts',
              options: alerts,
              onChange: handleFilters
            },
            {
              text: isLoading ? 'Carregando dados...' : 'Aplicar Filtros',
              type: 'button',
				isDisabled: isLoading,
			  onClick: applyFilters
            }
          ].filter(res => res)
        }
        head={{
          key: `table-order`,
          isHighlighted: false,
          cells: [
            {
              key: 'selector',
              width: 10,
              content: <Checkbox
                onClick={handleSelectAll}
                name="select-all-basic"
                size="large"
              />
            },
            {
              key: 'id',
              content: 'ID',
              isSortable: false,
            },
            {
              key: 'reference',
              content: 'Número Pedido / NF',
              isSortable: false,
            },
            {
              key: 'carrier',
              content: 'Transportadora',
              isSortable: false
            },
            {
              key: 'addressee',
              content: 'Destinatário',
              isSortable: false,
              width: 20
            },
            {
              key: 'status',
              width: 20,
              content: 'Status',
              isSortable: false
            },
            {
              key: 'property',
              content: 'Integrações',
              isSortable: false
            },
            props.miniVersion ? null : {
              key: 'delivery_date',
              content: 'Previsão de Entrega',
              isSortable: false
            },
            {
              key: 'created_at',
              content: 'Criado em',
              isSortable: false
            },
            {
              key: 'actions',
              content: props.miniVersion ? null : <>
                { <DropdownMenu trigger="Ações em massa"  spacing="compact">
			      <DropdownItemGroup hasSeparator>
			        <DropdownItem onClick={() => exportReport()}>Exportar em CSV</DropdownItem>			
			      </DropdownItemGroup>
				{selectedRows.length > 1 ? <><DropdownItemGroup hasSeparator>
			        <DropdownItem onClick={() => batch('inTransit', selectedRows)}>Marcar como Em Trânsito</DropdownItem>
			        <DropdownItem onClick={() => batch('inReturn', selectedRows)}>Marcar como Em Devolução</DropdownItem>
			        <DropdownItem onClick={() => batch('deliver', selectedRows)}>Marcar como Entregues</DropdownItem>				
			      </DropdownItemGroup>
			      <DropdownItemGroup hasSeparator>
			        <DropdownItem onClick={() => batch('cancel', selectedRows)}>Cancelar Pedidos</DropdownItem>
					</DropdownItemGroup></> : null}
				</DropdownMenu>}
              </>,
              isSortable: false
            }
          ].filter(res => res)
        }}
        rows={orders.map((order, index) => ({
          key: `row-${index}-${order.id}`,
          isHighlighted: selectedRows.indexOf(order.id) !== -1,
          cells: [
            {
              key:  `${order.id}-selector`,
              content: <Checkbox
                onClick={() => handleSelectOne(order.id)}
                key={`selected-${order.id}`}
                name="select-all-one"
                isChecked={selectedRows.indexOf(order.id) !== -1}
                size="large"
              />
            },
            {
              key: order.id,
              content: <>
				<a href={`/orders/${order.id}`}  style={{ color: '#2196F3', fontWeight: 'bold' }}>{order.id}</a>
				</>
            },
            {
              key: order.reference,
              content: <>
              <Tooltip title={"Número do pedido"}><strong><a href={`/orders/${order.id}`} style={{ color: '#2196F3', fontWeight: 'bold' }}>{order.reference}</a></strong></Tooltip> 
              {order.invoice_number ? <><br/><Tooltip title={"Número da nota fiscal"}><strong>{order.invoice_number}</strong></Tooltip></> : null}
              </>
            },
            {
              key: order.carrier_name,
              content: <strong>{order.carrier_name?.toUpperCase()}</strong>
            },
            {
              key: order.to_address?.id,
              content: <>
                <Tooltip title={order.to_address?.name}><strong style={{display: 'block', width: '140px', overflow:'hidden', textOverflow: 'ellipsis'}}>{order.to_address?.name}</strong></Tooltip> 
                <small style={{ display: 'flex', alignItems: 'center' }}><MapPin style={{ width: 10, marginRight: 5 }} /> {order.to_address.city} / {order.to_address.state}</small>
              </>
            },
            {
              key: 'status',
              content: <>
              <StatusIcon
                type={statuses.filter((st) => st.value == order.shipments[0]?.status?.id)[0]?.badge}
                title={order.shipments[0]?.status?.description}
                description={order.shipments[0]?.status?.description} />
                {order.order_alert_type ? <StatusIcon
                type={alerts.filter((st) => st.value == order.order_alert_type)[0]?.badge}
                title={alerts.filter((st) => st.value == order.order_alert_type)[0]?.label_description}
                description={`Possivelmente:  ${alerts.filter((st) => st.value == order.order_alert_type)[0]?.label_description}`} /> : null}
                </>
            }, 
             {
              key: 'integration',
               content: <div style={{display: 'flex', alignItems: 'center'}}>
                <AvatarGroup 
                appearance="stack"
                size="small"

                data={[
                  order.property?.channel ? {channel: order.property.channel, channel_id: order.property.channel_id, channel_entity_id: order.property.channel_entity_id} : null,
                  order.property?.marketplace ? {channel: order.property.marketplace, channel_id: null, channel_entity_id: order.property.marketplace_order_number} : null,
                ].filter(res => res).map(res => ({
                  email: res.channel,
                  key: res.channel,
                  name: `Canal: ${res.channel}. Pedido: ${res.channel_entity_id}`,
                  href: '#',
                  src: channelIconsUrl(res.channel),
                  borderColor: "#242424",
				  objectFit: "contain"
                }))} />
              </div>
            },
            props.miniVersion ? null : {
              key: 'delivery_date',
              content:  order.delivered_at && order?.delivery?.delivery_date
              ? <div style={{textAlign: 'right'}}>
                  <div style={{ textDecoration: 'line-through', color: '#aaa' }}>{order?.delivery?.delivery_date && moment(order?.delivery?.delivery_date).format('DD/MM/YYYY HH:mm')}</div>
                  
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  {order.deadline === 'delivered_late' ? <Tooltip title="Entregue depois do prazo"><FlagRounded style={{ color: 'red', width: '18px' }} /></Tooltip> : null}
                  {order.deadline === 'delivered_early' ? <Tooltip title="Entregue antes do prazo"><FlagRounded style={{ color: '#8bc34a', width: '18px' }} /></Tooltip> : null}

                  <Tooltip title={`Entrega realizada pela transportadora em ${moment(order.delivered_at).format('DD/MM/YYYY HH:mm')}`}><div style={{ color: '#145e3f' }}>{moment(order.delivered_at).format('DD/MM/YYYY HH:mm')}</div></Tooltip>
                  </div>
                
                </div> : order?.delivery?.delivery_date ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{order.deadline === 'delayed' ? <Tooltip title="Pedido atrasado"><WarningRounded style={{ color: 'red', width: '18px' }} /></Tooltip> : null} {moment(order?.delivery?.delivery_date).format('DD/MM/YYYY HH:mm')}</div> 
              : ''
            },
            {
              key: 'created_at',
              content: <Tooltip title={moment(order.created_at).format()}><span>{moment(order.created_at).format('DD/MM HH:mm')}</span></Tooltip>
            },
            {
              key: 'actions',
              content: <>
                {!props.noButtons && <DropdownMenu trigger="Ações" spacing="compact">
				      <DropdownItemGroup>
			        <DropdownItem onClick={() => navigate(`/orders/${order.id}`)}>Abrir</DropdownItem>
			        <DropdownItem onClick={() => navigate(`/orders/${order.id}/edit`)}>Editar</DropdownItem>
			      </DropdownItemGroup>
			      <DropdownItemGroup hasSeparator>
			        <DropdownItem onClick={() => inTransit(order)}>Marcar como Em Trânsito</DropdownItem>
			        <DropdownItem onClick={() => markInReturn(order)}>Marcar como Em Devolução</DropdownItem>
			        <DropdownItem onClick={() => deliver(order)}>Marcar como Entregue</DropdownItem>				
			      </DropdownItemGroup>
			      <DropdownItemGroup hasSeparator>
			        <DropdownItem onClick={() => cancelOrder(order)}>Cancelar Pedido</DropdownItem>
			      </DropdownItemGroup>
				</DropdownMenu>}
              </>
            }
          ].filter(res => res)
        }))}
        limit={limit}
        page={page}
        height={props.height ? props.height : 500}
        changePage={changePage}
        totalRows={totalRows}
        isLoading={isLoading}
      />
    </>
  );
};


export default withSnackbar(OrdersList);
