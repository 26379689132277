import React, { useState } from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApiService from 'src/common/apiService';
import { Refresh } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));


const Toolbar = ({ className, hideButton, ...rest }) => {
  const classes = useStyles();
  const [reloading, setReloading] = useState(false);

  const refreshCache = () => {
    const apiService = new ApiService;
    setReloading(true);
    apiService
      .reloadQuotationCache('freight-rules')
      .then(() => {
        setReloading(false);
        alert('Atualizado com sucesso!');
      });

  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          Regras de Frete
        </Typography>

        <Tooltip enterDelay={300} title="Para que as alterações reflitam na cotação, você deve clicar aqui para sincronizar.">
          <Button
            color="default"
            disabled={reloading}
            style={{ marginRight: 30 }}
            onClick={refreshCache}
            variant="contained"
          >
            <Refresh /> Sincronizar
          </Button>
        </Tooltip>

        {!hideButton ? <Button
          color="primary"
          component={Link}
          to="/settings/freight-rules/new"
          variant="contained"
        >
          ADICIONAR NOVA REGRA
        </Button> : null}
      </Box>
    </div>
  );
};

export default Toolbar;
