import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Box,
  CardContent,
  CardHeader,
  Container,
  makeStyles,
  Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const QuotationView = ({enqueueSnackbar}) => {
  const classes = useStyles();
  const [template, setTemplate] = useState(null);
  const [submiting, setSubmiting] = useState(false);
  const apiService = new ApiService;

  const templates = [
    {
      id: 1,
      title: 'Mostrar somente o menor preço',
      subheader: 'Com essa opção selecionada, será retornado apenas o menor preço nas cotações em seu site'
    },
    {
      id: 2,
      title: 'Mostrar todas',
      subheader: 'Com essa opção selecionada, será exibida todas as transportadoras ativas em seu site.'
    },
    {
      id: 3,
      title: 'Mais rápida e a mais barata',
      subheader: 'Mostraremos somente duas opções uma com o menor valor de frete e outra com o menor prazo final.'
    }
  ];

  useEffect(() => {
    apiService.me().then((res) => {
      setTemplate(res.company.quotation_view)
    });
  }, []);

  const changeQuotationView = (quotationView) => {
    setSubmiting(true);
    apiService
    .changeQuotationView(quotationView)
    .then((res) => {
      setSubmiting(false);
      enqueueSnackbar('Mode de exibição atualizado com sucesso. Consulte seu site para testar', {variant: 'success'})
    })
    .catch((res) => enqueueSnackbar('Ocorreu um erro. Contato o suporte', {variant: 'error'}));
    setTemplate(quotationView);
  }

  return (
    <Page
      className={classes.root}
      title="Modo de exibição das cotações de frete"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Grid container spacing={3}>
            {templates.map((temp) => <Grid item
              key={temp.id}
              lg={4}
              md={6}
              xs={12}>
              <Card>
                <CardHeader
                  title={temp.title}
                  subheader={temp.subheader}></CardHeader>
                <CardContent>
                  <Switch
                    disabled={submiting}
                    checked={template === temp.id}
                    onChange={() => changeQuotationView(temp.id)}
                  />
                </CardContent>
              </Card>
            </Grid>)}

          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(QuotationView);
