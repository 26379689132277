import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UsersListView = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const apiService = new ApiService();

  const refresh = () => {
    apiService
      .getUsers()
      .then((res) => {
        setUsers(res.data);
      });
  };

  useEffect(() => {
    refresh();
  }, []);


  return (
    <Page
      className={classes.root}
      title="Usuários"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results users={users} />
        </Box>
      </Container>
    </Page>
  );
};

export default UsersListView;
