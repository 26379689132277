import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputMask from "react-input-mask";
import axios from 'axios';
import * as _ from 'underscore';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FlashOffRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FormRegister = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const tms = [
  'ssw', 'fedex', 'tnt', 'ssw', 'braspress', 'rodonaves', 'solistica', 'asaplog',
  'vipex', 'paju', 'jadlog', 'senior', 'bento', 'brudam', 'esl', 'panex', 'minuano',
  'isentrega', 'exatacargo', 'translovato', 'engloba', 'alfa', 'wartha', '2001', 'cristofoli',
  'tsv', 'centrosul', 'i9', 'jew'];

  const [loading, setLoading] = useState(false);
  const [error_message, setError] = useState(null);
  const [initValues, setInitValues] = useState({
    carrier_cubage: 300,
    address_zip: '',
    address_street1: '',
    address_number: '',
    address_neighborhood: '',
    address_city: '',
    address_state: ''
  });

  const [valuex, setValuex] = React.useState();
  const [success, setSuccess] = React.useState(false);

  const [carrier, setCarrier] = useState(null);

  const [step, setStep] = useState(1);

  const cardStyle = (active) => {
    var style = {
      width: '100px',
      float: 'left',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 10,
      background: '#fff',
      borderRadius: 10,
      marginRight: 10,
      boxShadow: '0 0 7px #ddd',
      cursor: 'pointer',
      marginBottom: 25
    };

    if (active) {
      style.outline = '5px solid #ffcc00';
    }

    return style;
  }

  const handleCnpj = (cnpj, values) => {
    const fti = cnpj.replace(/[^\d]/g, "");
    const apiService = new ApiService;

    if (fti.length === 14) {
      setLoading(true);
      apiService
        .cnpjCard(fti)
        .then((res) => {
          setLoading(false);

          setCarrier(res);

          var cp = res;


          values['carrier_name'] = cp.fantasia;
          values['address_zip'] = cp.cep.replace(/[^\d]/g, "");
          values['address_number'] = cp.numero;
          values['address_street1'] = cp.logradouro;
          values['address_city'] = cp.municipio;
          values['address_neighborhood'] = cp.bairro;
          values['address_state'] = cp.uf;
          values['carrier_federal_tax_id'] = fti;


          console.log(values);

          setInitValues({ ...initValues, values });

          setLoading(false);

        });
    }
  }
  
  const handleCep = (cep, values) => {
    const zip = cep.replace('.', '').replace('-', '').replace('_', '');

    if (zip.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${zip}/json/`)
        .then((res) => {
          const { logradouro, bairro, localidade, uf } = res.data;

          values['address_street1'] = logradouro;
          values['address_street2'] = '';
          values['address_city'] = localidade;
          values['address_neighborhood'] = bairro;
          values['address_state'] = uf;
          values['address_number'] = '';

          setInitValues({ ...initValues, values });

        });
    }
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(values) => {
        const apiService = new ApiService();

        apiService
          .registerCarrier({
            name: values.carrier_name,
            canonical_name: values.carrier_canonical_name,
            state_tax_id: values.carrier_state_tax_id,
            federal_tax_id: values.carrier_federal_tax_id,
            phone: values.carrier_phone,
            cubage: values.carrier_cubage,
            property: {
              tms: values.carrier_tms,
              address: {
                zip: values.address_zip,
                city: values.address_city,
                neighborhood: values.address_neighborhood,
                number: values.address_number,
                state: values.address_state,
                street1: values.address_street1,
              }
            }
          }).then(() => {
            setSuccess(true);
            setError(null);
          })
          .cath((ev) => {
            setSuccess(FlashOffRounded);
            setError(ev.data.message);
          });

      }}
      validationSchema={Yup.object().shape({
        carrier_state_tax_id: Yup.string().required('Digite a inscrição estadual.'),
        carrier_name: Yup.string().required('Digite o nome da empresa'),
        carrier_federal_tax_id: Yup.string().required('Digite o CNPJ'),
        address_zip: Yup.string('Apenas números').required('Digite um CEP válido.'),
        address_street1: Yup.string().required('Digite o endereço.'),
        address_number: Yup.string().required('Digite o número.'),
        address_neighborhood: Yup.string().required('Digite o bairro.'),
        address_city: Yup.string().required('Digite a cidade.'),
        address_state: Yup.string().required('Selecione o estado.')
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Cadastrar uma nova transportadora
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Digite abaixo os dados da filial da transportadora que deseja cadastrar.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold' }}>
              DADOS DA TRANSPORTADORA

            </Typography>

          </Box>

          <Grid container spacing={2}>
            <Grid item lg={6}>
              <InputMask
                mask={'99.999.999/9999-99'}
                onBlur={handleBlur}
                onChange={handleCnpj}
                onChange={(ev) => {
                  // handleChange(ev);
                  handleCnpj(ev.target.value, values);
                }}
                error={Boolean(touched.carrier_federal_tax_id && errors.carrier_federal_tax_id)}
                helperText={touched.carrier_federal_tax_id && errors.carrier_federal_tax_id}
                value={values.carrier_federal_tax_id}
                name="carrier_federal_tax_id"
                required
              >
                {() => (
                  <TextField
                    label="CNPJ"
                    margin="normal"
                    fullWidth
                    required
                    name="carrier_federal_tax_id"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.carrier_name && errors.carrier_name)}
                fullWidth
                helperText={touched.carrier_name && errors.carrier_name}
                label="Razão Social"
                margin="normal"
                name="carrier_name"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.carrier_name}
                variant="outlined"
              />

            </Grid>
            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.carrier_canonical_name && errors.carrier_canonical_name)}
                fullWidth
                helperText={"Nome sem espaço em letra minuscula, exemplo: vipex"}
                label="Slug"
                margin="normal"
                name="carrier_canonical_name"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.carrier_canonical_name}
                variant="outlined"
              />

            </Grid>

            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.carrier_state_tax_id && errors.carrier_state_tax_id)}
                fullWidth
                helperText={touched.carrier_state_tax_id && errors.carrier_state_tax_id}
                label="Inscrição Estadual"
                margin="normal"
                name="carrier_state_tax_id"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.carrier_state_tax_id}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.carrier_phone && errors.carrier_phone)}
                fullWidth
                helperText={touched.carrier_phone && errors.carrier_phone}
                label="Telefone"
                margin="normal"
                name="carrier_phone"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.carrier_phone}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.carrier_cubage && errors.carrier_cubage)}
                fullWidth
                helperText={touched.carrier_cubage && errors.carrier_cubage}
                label="Cubagem"
                margin="normal"
                type="number"
                name="carrier_cubage"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.carrier_cubage}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.carrier_tms && errors.carrier_tms)}
                fullWidth
                helperText={"TMS disponíveis:" + tms.join(', ')}
                label="TMS"
                margin="normal"
                name="carrier_tms"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.carrier_tms}
                variant="outlined"
              />
            </Grid>
          </Grid>

 <Divider style={{ marginTop: 30, marginBottom: 30 }} /> 

 <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold', marginBottom: 30 }}>
               ENDEREÇO
              <br /><br />
              <small style={{ fontWeight: 'normal', color: '#000', letterSpacing: 'normal', fontSize: 13 }}>Endereço da filial da transportadora.</small>
            </Typography>
          </Box>
          
          <Grid container>
            <Grid item xs={12} lg={5}>
			  
            <InputMask
              mask="99.999-999"
              error={Boolean(touched.address_zip && errors.address_zip)}
              helperText={touched.address_zip && errors.address_zip}
              name="address_zip"
              value={values.address_zip}
              onBlur={(ev) => {
                handleBlur(ev);
                handleCep(ev.target.value, values);
              }}
              onChange={(ev) => {
                handleChange(ev);
                handleCep(ev.target.value, values);
              }}
            >
              {() => (
                <TextField
                  label="CEP"
                  margin="normal"
                  name="address_zip"
                  variant="outlined"
                />
              )}
            </InputMask>
			  
			  
              
            </Grid>
          </Grid> 
           <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <TextField
                error={Boolean(touched.address_street1 && errors.address_street1)}
                helperText={touched.address_street1 && errors.address_street1}
                label="Endereço"
                margin="normal"
                fullWidth
                name="address_street1"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.address_street1}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={5}>
              <TextField
                error={Boolean(touched.address_number && errors.address_number)}
                helperText={touched.address_number && errors.address_number}
                label="Número"
                margin="normal"
                name="address_number"
                onBlur={handleBlur}
                fullWidth
                required
                onChange={handleChange}
                value={values.address_number}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={7}>
            <TextField
                error={Boolean(touched.address_neighborhood && errors.address_neighborhood)}
                helperText={touched.address_neighborhood && errors.address_neighborhood}
                label="Bairro"
                margin="normal"
                name="address_neighborhood"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                fullWidth
                value={values.address_neighborhood}
                variant="outlined"
              />
            </Grid>
 

            <Grid item lg={4}>
              <TextField
                error={Boolean(touched.address_city && errors.address_city)}
                helperText={touched.address_city && errors.address_city}
                label="Cidade"
                margin="normal"
                name="address_city"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_city}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4}>
              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Estado</InputLabel>
                <Select
                  error={Boolean(touched.address_state && errors.address_state)}
                  helperText={touched.address_state && errors.address_state}
                  label="Estado"
                  margin="normal"
                  name="address_state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  required
                  value={values.address_state}
                  variant="outlined"
                >
                  <MenuItem value="AC">AC</MenuItem>
                  <MenuItem value="AL">AL</MenuItem>
                  <MenuItem value="AP">AP</MenuItem>
                  <MenuItem value="AM">AM</MenuItem>
                  <MenuItem value="BA">BA</MenuItem>
                  <MenuItem value="CE">CE</MenuItem>
                  <MenuItem value="DF">DF</MenuItem>
                  <MenuItem value="ES">ES</MenuItem>
                  <MenuItem value="GO">GO</MenuItem>
                  <MenuItem value="MA">MA</MenuItem>
                  <MenuItem value="MT">MT</MenuItem>
                  <MenuItem value="MS">MS</MenuItem>
                  <MenuItem value="MG">MG</MenuItem>
                  <MenuItem value="PA">PA</MenuItem>
                  <MenuItem value="PB">PB</MenuItem>
                  <MenuItem value="PR">PR</MenuItem>
                  <MenuItem value="PE">PE</MenuItem>
                  <MenuItem value="PI">PI</MenuItem>
                  <MenuItem value="RJ">RJ</MenuItem>
                  <MenuItem value="RN">RN</MenuItem>
                  <MenuItem value="RS">RS</MenuItem>
                  <MenuItem value="RO">RO</MenuItem>
                  <MenuItem value="RR">RR</MenuItem>
                  <MenuItem value="SC">SC</MenuItem>
                  <MenuItem value="SP">SP</MenuItem>
                  <MenuItem value="SE">SE</MenuItem>
                  <MenuItem value="TO">TO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
           
          </Grid>
          

          <Grid item xs={12} lg={12}>
           
           <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={success}
              style={{ marginTop: 50 }}
            >
              CONCLUIR CADASTRO
            </Button>  
          </Grid>

          {success ? <Grid item xs={12} lg={12}>
            <Alert severity="success">
              <AlertTitle>Parabéns!</AlertTitle>
              Cadastro realizado com sucesso! 
            </Alert>
          </Grid> : null}

          {error_message ? <Grid item xs={12} lg={12}>
            <Alert severity="error">
              <AlertTitle>Erro!</AlertTitle>
              {error_message}
            </Alert>
          </Grid> : null}
        </form >
      )
      }
    </Formik >
  );
};

export default FormRegister;
