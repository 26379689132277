import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Grid,
  CardHeader,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu
} from '@material-ui/core';

import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Search as SearchIcon,
  Truck as TruckIcon,
  Repeat,
  User,
  Home,
  Users,
  Truck,
  Square,
  Gift,
  Package,
  Code,
  FileText,
  Layers,
  ArrowDown,
  LogOut,
  Star,
  HelpCircle,
	MessageCircle
} from 'react-feather';

import NavItem from './NavItem';
import { ArrowDropDown, HomeWork, ReportOutlined, TableChart, TableChartTwoTone, TransferWithinAStation } from '@material-ui/icons';
import ApiService from 'src/common/apiService';
import { getActualCompany, hasPermission, inPresentation, isAdmin, isSuperUser, login, setActualCompany, hasAuditEnabled } from 'src/common/auth';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { stubFalse } from 'lodash';
import Logo from 'src/components/Logo';


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 215
  },
  desktopDrawer: {
    width: 215,
    top: 0,
    height: '100%',
    backgroundColor: '#000'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  list: {

  },
  listLing: {

  },
  linkLogo: {
    padding: 15,
    paddingTop: 31
  },
  logo: {
    height: 16
  },
  name: {
    color: '#fff',
    fontSize: 16,
    textAlign: 'left'
  }
}));

const apiService = new ApiService();

const NavBar = ({ onMobileClose, openMobile, onboardingCompleted }) => {
  const classes = useStyles();
  const location = useLocation();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [isSetting, setIsSetting] = React.useState(false);
  const [inSuport, setInSuport] = React.useState(false);


  const items = [
    {
      href: '/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard',
      render: onboardingCompleted && hasPermission('dashboard')
    },
    {
      href: '/orders',
      icon: Package,
      title: 'Pedidos',
      render: onboardingCompleted && hasPermission('orders')
    },
    {
      href: '/pickups',
      icon: Layers,
      title: 'Coletas',
      render: onboardingCompleted && hasPermission('pickups')

    },
    {
      href: '/quote',
      icon: SearchIcon,
      title: 'Cotações',
      render: onboardingCompleted && hasPermission('quotation')
    },
    {
      href: '/audit',
      icon: TableChartTwoTone,
      title: 'Auditoria de Frete',
      render: onboardingCompleted && hasAuditEnabled()
    },
  ];

  const itemsSettings = [
    {
      href: '/settings/freight-rules',
      icon: SettingsIcon,
      title: 'Regras de Frete',
      render: onboardingCompleted && isAdmin() && hasPermission('settings')
    },
    {
      href: '/settings/quotation-views',
      icon: SearchIcon,
      title: 'Cotações',
      render: onboardingCompleted && isAdmin() && hasPermission('settings')
    },
    {
      href: '/settings/distribution-centers',
      icon: Home,
      title: 'Centros de Distribuição',
      render: isAdmin() && hasPermission('settings')
    },
    {
      href: '/settings/users',
      icon: Users,
      title: 'Usuários',
      render: isAdmin() && hasPermission('settings')
    },
    {
      href: '/settings/products',
      icon: Gift,
      title: 'Produtos',
      render: hasPermission('settings')
    },
    {
      href: '/settings/contingency-tables',
      icon: FileText,
      title: 'Tabelas de Contingência',
      render: onboardingCompleted && isAdmin() && hasPermission('settings')
    },
    {
      href: '/settings/freight-tables',
      icon: Square,
      title: 'Tabelas de Frete',
      render: onboardingCompleted && isAdmin() && hasPermission('settings')
    },
    {
      href: '/settings/carriers',
      icon: Truck,
      title: 'Transportadoras',
      render: onboardingCompleted && hasPermission('settings')
    },
    {
      href: '/settings/integrations',
      icon: Code,
      title: 'Integrações',
      render: isAdmin() && hasPermission('settings')
    },
  ];
   

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    if (location.pathname.indexOf("settings") !== -1) {
      setIsSetting(true);
    } else {
      setIsSetting(false);
    }

    if (!companies.length) {
      apiService
        .getCompanies()
        .then((res) => {
          setCompanies(res.data);
        });
    }

    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const changeCompany = (cmp) => {
    apiService
      .generateCompanyToken(cmp.id)
      .then((res) => {
        login(res.token);
        setActualCompany(res.company);
        window.location.href = '/dashboard';
      });
  };

  const getMe = () => {
    apiService
      .me()
      .then((res) => window.localStorage.setItem('@flixlog/me', JSON.stringify({ name: res.name, email: res.email, has_audit_enabled: res?.company?.has_audit_enabled })));
  }

  const openSuport = () => {
    setInSuport(true);
    window.Beacon('init', 'dde7264a-9b47-4027-a81e-20ae53211e6f') 

    window.Beacon('on', 'ready', () => {

      var me = JSON.parse(window.localStorage.getItem('@flixlog/me'))
        window.Beacon('identify', {
        name: me.name,
        email:  me.email,
        company: getActualCompany().name
      }) 
      window.Beacon('open');

    })

     window.Beacon('on', 'open', () => {
          setInSuport(false); 

    })

  	

  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <RouterLink to="/dashboard" className={classes.linkLogo}>
        <Logo className={classes.logo} />
      </RouterLink>

      <Box
        alignItems="left"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h2"
          aria-haspopup="true"
          onClick={handleClick}
          style={companies.length ? { display: 'flex', alignItems: 'center', cursor: 'pointer' } : { display: 'flex', alignItems: 'center' }}
        >
          {getActualCompany().name} {companies.length > 1 ? <ArrowDropDown /> : null}

        </Typography>
        {companies.length > 1 ? (
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {companies.map((company) => <MenuItem onClick={() => {
              setCompany(company);
              changeCompany(company);
            }} value={company.id}>{company.name} - {company.federal_tax_id}</MenuItem>)}
          </Menu>
        ) : null}
      </Box>
      <Divider />

      {onboardingCompleted ? null : <List>
        <Button
          style={{ background: "#ffcc00", color: "#242424", borderRadius: 0, width: '100%', fontWeight: 'bold', textTransform: 'inherit' }}
          component={RouterLink}
          active
          to={"/onboarding"}
        >
          <Star
            className={classes.icon}
            size="20"
            style={{ color: "#242424", paddingRight: 5 }}
          />
          <span>
            Comece por aqui
          </span>

        </Button>
      </List>}

      <Divider />
      <Box p={2}>
        <List>
          {items
            .filter((item) => {
              if (typeof item.render === undefined) {
                return true;
              }

              if (item.render === false) {
                return false;
              }

              return true;
            })
            .map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}

          {isAdmin() || isSuperUser() || hasPermission('settings') ? <NavItem
            href="/settings/freight-rules"
            key="config"
            title="Configurações"
            icon={SettingsIcon}
            isActive={isSetting}

          /> : null}
          <List style={{ marginLeft: 10 }}>
            {open && window.location.href.includes('settings') && itemsSettings
              .filter((item) => {
                if (typeof item.render === undefined) {
                  return true;
                }

                if (item.render === false) {
                  return false;
                }

                return true;
              })
              .map((item) => (
                <NavItem
                  isLink
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
          </List>

 
          {isSuperUser() ? <> <Divider style={{background: "#4b4b4b"}}  /> 
          <a href={`/v2/pickups`}   style={{color: "#fff", fontWeight: "500", fontSize: 14, padding: 10, display: "flex"}}
			><Layers style={{marginRight: '10px', width: 20}} /> Gestão de Coletas</a>
          </>: null}


		 <Divider style={{background: "#4b4b4b"}}  />
		

			 
		
         {false ? <a
            onClick={() => openSuport()}
			style={{color: "#fff", fontWeight: "500", fontSize: 14, padding: 10, display: "flex"}}
			href="#" 
			disabled={inSuport}
			><MessageCircle style={{marginRight: '10px', width: 20}} /> {inSuport ? "Abrindo chat..." : "Suporte"}</a> : null}
				
          
		 {false ? <Divider style={{background: "#4b4b4b"}}  /> : null}
			

          <a
            href="/logout"
            key="logout"
			
			style={{color: "#fff", fontWeight: "500", fontSize: 14, padding: 10, display: "flex"}}
			disabled={inSuport}
			><LogOut style={{marginRight: '10px', width: 20}} /> Sair </a>
				
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
