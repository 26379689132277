import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import InputMask from "react-input-mask";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';
import { DollarSign, MapPin, Percent, Plus, Search, Trash, TrendingDown, Truck } from 'react-feather';
import { SelectAllSharp, TrendingUp } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Form = ({ enqueueSnackbar, initialValues, onSubmit, submitText, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const states = [
    {
      name: 'Acre',
      state: 'AC',
      zip_from: 69900000,
      zip_to: 69999999,
    },
    {
      name: 'Alagoas',
      state: 'AL',
      zip_from: 57000000,
      zip_to: 57999999,
    },
    {
      name: 'Amazonas 1',
      state: 'AM',
      zip_from: 69000000,
      zip_to: 69299999,
    },
    {
      name: 'Amazonas 2',
      state: 'AM',
      zip_from: 69400000,
      zip_to: 69899999,
    },
    {
      name: 'Amapá',
      state: 'AP',
      zip_from: 68900000,
      zip_to: 68999999,
    },
    {
      name: 'Bahia',
      state: 'BA',
      zip_from: 40000000,
      zip_to: 48999999,
    },
    {
      name: 'Ceará',
      state: 'CE',
      zip_from: 60000000,
      zip_to: 63999999,
    },
    {
      name: 'Distrito Federal 1',
      state: 'DF',
      zip_from: 70000000,
      zip_to: 72799999,
    },
    {
      name: 'Distrito Federal 2',
      state: 'DF',
      zip_from: 73000000,
      zip_to: 73699999,
    },
    {
      name: 'Espírito Santo',
      state: 'ES',
      zip_from: 29000000,
      zip_to: 29999999,
    },
    {
      name: 'Goiás 1',
      state: 'GO',
      zip_from: 72800000,
      zip_to: 72999999,
    },
    {
      name: 'Goiás 2',
      state: 'GO',
      zip_from: 73700000,
      zip_to: 76799999,
    },
    {
      name: 'Maranhão',
      state: 'MA',
      zip_from: 65000000,
      zip_to: 65999999,
    },
    {
      name: 'Minas Gerais',
      state: 'MG',
      zip_from: 30000000,
      zip_to: 39999999,
    },
    {
      name: 'Mato Grosso do Sul',
      state: 'MS',
      zip_from: 79000000,
      zip_to: 79999999,
    },
    {
      name: 'Mato Grosso',
      state: 'MT',
      zip_from: 78000000,
      zip_to: 78899999,
    },
    {
      name: 'Pará',
      state: 'PA',
      zip_from: 66000000,
      zip_to: 68899999,
    },
    {
      name: 'Paraíba',
      state: 'PB',
      zip_from: 58000000,
      zip_to: 58999999,
    },
    {
      name: 'Pernambuco',
      state: 'PE',
      zip_from: 50000000,
      zip_to: 56999999,
    },
    {
      name: 'Piauí',
      state: 'PI',
      zip_from: 64000000,
      zip_to: 64999999,
    },
    {
      name: 'Paraná',
      state: 'PR',
      zip_from: 80000000,
      zip_to: 87999999,
    },
    {
      name: 'Rio de Janeiro',
      state: 'RJ',
      zip_from: 20000000,
      zip_to: 28999999,
    },
    {
      name: 'Rio Grande do Norte',
      state: 'RN',
      zip_from: 59000000,
      zip_to: 59999999,
    },
    {
      name: 'Rondônia',
      state: 'RO',
      zip_from: 76800000,
      zip_to: 76999999,
    },
    {
      name: 'Roraima',
      state: 'RR',
      zip_from: 69300000,
      zip_to: 69399999,
    },
    {
      name: 'Rio Grande do Sul',
      state: 'RS',
      zip_from: 90000000,
      zip_to: 99999999,
    },
    {
      name: 'Santa Catarina',
      state: 'SC',
      zip_from: 88000000,
      zip_to: 89999999,
    },
    {
      name: 'Sergipe',
      state: 'SE',
      zip_from: 49000000,
      zip_to: 49999999,
    },
    {
      name: 'São Paulo',
      state: 'SP',
      zip_from: '01000000',
      zip_to: 19999999,
    },
    {
      name: 'Tocantins',
      state: 'TO',
      zip_from: 77000000,
      zip_to: 77999999,
    }
  ]
  const [cds, setCds] = useState([]);
  const [open, setOpen] = useState(false);
  const [zips, setZips] = useState({});
  const [actualRule, setActualRule] = useState(null);
  const [rules, setRules] = useState(initialValues && initialValues.routes ? initialValues.routes : [{ id: 1 }]);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getCities()
      .then((res) => {
        setCities(res.data);
      });

     apiService
      .getDistributionCenters()
      .then((res) => {
        setCds(res.data);
      });

      apiService
      .getCarriers()
      .then((res) => {
        setCarriers(res.data);
      });

  }, []);

  const addRule = () => {
    setRules(rules => [...rules, { id: rules.length + 1 }]);
  };

  const removeRule = (ruleId) => {
    setRules(rules.filter((rule) => rule.id !== ruleId));
  };

  const findZip = (rule) => {
    setOpen(true);
    setActualRule(rule);
  };

  const handleBlurRoute = (ev, ruleId) => {
    setRules(rules.map((rule) => {
      if (rule.id === ruleId) {
        rule[ev.target.name] = ev.target.value;
      }

      return rule;
    }));
  }

  const handleChangeRoute = (ev, ruleId, isCheckebox) => {

    setRules(rules.map((rule) => {
      if (rule.id === ruleId) {
        if (isCheckebox) {
          rule[ev.target.name] = ev.target.checked;
        } else {
          rule[ev.target.name] = ev.target.value;
        }
      }

      return rule;
    }));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string(' ').max(50, 'Deve ter no máximo 50 caracteres').required('Digite um nome para essa regra'),
        start_at: Yup.string(' ').required('Coloque uma data de início de vigência dessa regra'),
      })}
      onSubmit={(vals) => onSubmit(vals, rules)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Municípios"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Pesquise o nome da cidade para encontrar a faixa de CEP e clique em "Selecionar".
              </DialogContentText>

              <br/>
              <Button 
              color="primary"
              variant="contained"
              onClick={() => {
                zips[actualRule.id] = {
                  zip_from: '01000000',
                  zip_to: '99999999',
                  name: 'Brasil Inteiro',
                  state: 'BR'
                };
                setZips(zips);
                setOpen(false);
              }} color="primary">
                BRASIL INTEIRO
              </Button>
              <br/>
              <br/>
              ou por:
              <br/>
              <br/>
              <Autocomplete
                id="combo-box-demo-state"
                options={states}
                onChange={(event, newValue) => {
                  zips[actualRule.id] = newValue;
                  setZips(zips);
                  setOpen(false);
                }}
                getOptionLabel={(option) => `${option.name} / ${option.state}`}
                style={{ width: 300 }}
                renderInput={(city) => <TextField {...city} label="Estado" variant="outlined" />}
              />

<br/>
              <br/>
              ou ainda:
              <br/>
              <br/>

              <Autocomplete
                id="combo-box-demo-cities"
                options={cities}
                onChange={(event, newValue) => {
                  zips[actualRule.id] = newValue;
                  setZips(zips);
                  setOpen(false);
                }}
                getOptionLabel={(option) => `${option.name} / ${option.state}`}
                style={{ width: 300 }}
                renderInput={(city) => <TextField {...city} label="Município" variant="outlined" />}
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {title ? title : "Nova regra"}
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Utilize o formulário abaixo para cadastrar uma nova regra de frete
                    </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Nome da regra"
                margin="normal"
                name="name"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Canal de Venda</InputLabel>
                <Select
                  label="Canal de Venda"
                  error={Boolean(touched.channel_id && errors.channel_id)}
                  helperText={touched.channel_id && errors.channel_id}
                  margin="normal"
                  name="channel_id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.channel_id}
                  variant="outlined"
                  style={{ maxHeight: 58 }}
                >

                  <MenuItem value={null}>Todos</MenuItem>
                  <MenuItem value={3}>MadeiraMadeira</MenuItem>
                  <MenuItem value={8}>Tray</MenuItem>
                  <MenuItem value={9}>Skyhub (B2W)</MenuItem>
                  <MenuItem value={10}>Magalu</MenuItem>
                  <MenuItem value={11}>Via Varejo</MenuItem>
                  <MenuItem value={12}>WooCommerce</MenuItem>
                  <MenuItem value={13}>TrayCorp</MenuItem>
                  <MenuItem value={15}>Simplo7</MenuItem>
                  <MenuItem value={16}>Leroy Merlin</MenuItem>
                  <MenuItem value={25}>Ricardo Eletro</MenuItem>
                  <MenuItem value={26}>Carrefour</MenuItem>
                  <MenuItem value={29}>NuvemShop</MenuItem>
                  <MenuItem value={31}>AnyMarket</MenuItem>
                  <MenuItem value={32}>Lojas Colombo</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {values.distribution_center_id || cds.length > 1 ?<Grid item xs={12} lg={3}>
               <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">CD de Origem</InputLabel>
                <Select
                  label="CD de Origem"
                  error={Boolean(touched.distribution_center_id && errors.distribution_center_id)}
                  helperText={touched.distribution_center_id && errors.distribution_center_id}
                  margin="normal"
                  name="distribution_center_id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.distribution_center_id}
                  variant="outlined"
                  style={{ maxHeight: 58 }}
                >
                  <MenuItem value={null}>Todos</MenuItem>
                  {cds.map((cd) => <MenuItem value={cd.id}>{cd.reference}</MenuItem>)}
                </Select>
				  </FormControl> 
            </Grid>: null}
            <Grid item xs={12} lg={2}>
              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Transportadora</InputLabel>
                <Select
                  label="Transportadora"
                  error={Boolean(touched.carrier_id && errors.carrier_id)}
                  helperText={touched.carrier_id && errors.carrier_id}
                  margin="normal"
                  name="carrier_id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.carrier_id}
                  variant="outlined"
                  style={{ maxHeight: 58 }}
                >
                  <MenuItem value={null}>Todos</MenuItem>
                  {carriers.map((carrier) => <MenuItem value={carrier.id}>{carrier.id} - {carrier.canonical_name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
				  <Grid item  lg={12}>
				  <Divider /> </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.start_at && errors.start_at)}
                helperText={touched.start_at && errors.start_at}
                label="Início da Vigência"
                margin="normal"
                name="start_at"
                type="datetime-local"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.start_at ? moment(values.start_at).format('YYYY-MM-DDTHH:mm') : null}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <TextField
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.end_at && errors.end_at)}
                helperText={touched.end_at && errors.end_at}
                label="Fim da Vigência"
                margin="normal"
                name="end_at"
                type="datetime-local"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.end_at ? moment(values.end_at).format('YYYY-MM-DDTHH:mm') : null}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {rules.map((rule, index) => {
                rule.zip_up = rule.zip_up ? (new String(rule.zip_up)).padStart(8, '0') : null;
                rule.zip_from = rule.zip_from ? (new String(rule.zip_from)).padStart(8, '0') : null;
                rule.destination = zips[rule.id] ? `${zips[rule.id].name}/${zips[rule.id].state}` : rule.destination;

                if (! rule.zip_from) {
                  rule.zip_from = zips[rule.id] ? zips[rule.id].zip_from : null;
                }

                if (! rule.zip_up) {
                  rule.zip_up = zips[rule.id] ? zips[rule.id].zip_to : null;
                }

                if (!rule.destination && rule.zip_from  && rule.zip_up) {
                  var stat = states.filter((state) => state.zip_from <= rule.zip_from && state.zip_to >= rule.zip_up);
          
                  rule.destination = stat.length ? `${stat[0].name}/${stat[0].state}` : null;
                }

                return <Card key={index}>
                  <CardContent>
                    <h3>Regra por CEP #{index + 1} {rule.destination ? ' → ' + rule.destination : ''}</h3>
                    <Grid container spacing={3}>
                      <Grid item xs={6} lg={2}>
                        <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                          <InputLabel margin="normal">Tipo de regra</InputLabel>
                          <Select
                            label="Tipo de regra"
                            margin="normal"
                            name="rule_type"
                            onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                            onChange={(ev) => handleChangeRoute(ev, rule.id)}
                            value={rule.rule_type}
                            variant="outlined"
                            style={{ maxHeight: 58 }}
                          >
                            <MenuItem value={1}><TrendingUp style={{ marginRight: 5, width: 15 }} /> {"  Acrescentar valor sobre o frete"}</MenuItem>
                            <MenuItem value={2}><Percent style={{ marginRight: 5, width: 15 }} /> {"  Acrescentar percentual sobre o frete"}</MenuItem>
                            <MenuItem value={3}><TrendingDown style={{ marginRight: 5, width: 15 }} /> {"  Reduzir valor sobre o frete"}</MenuItem>
                            <MenuItem value={4}><Percent style={{ marginRight: 5, width: 15 }} /> {"  Reduzir percentual sobre o frete"}</MenuItem>
                            <MenuItem value={5}><DollarSign style={{ marginRight: 5, width: 15 }} /> {"  Frete fixo"}</MenuItem>
							<MenuItem value={6}><Truck style={{ marginRight: 5, width: 15 }} /> {"  Remover Transportadora da Cotação"}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} lg={2}>
                        <InputMask
                          mask="99.999-999"
                          name="zip_from"
                          onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                          
                          onChange={(ev) => {
                            handleChangeRoute(ev, rule.id);
                            zips[rule.id] = null;
                          }}
                          value={zips[rule.id] && !rule.zip_from ? zips[rule.id].zip_from : rule.zip_from}
                        >
                          {() => (
                            <TextField
                              label="CEP Inicial"
                              name="zip_from"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                              fullWidth
                              value={zips[rule.id] && !rule.zip_from ? zips[rule.id].zip_from : rule.zip_from}

                            />
                          )}
                        </InputMask>
                        <a onClick={() => {
                          findZip(rule);
                          zips[rule.id] = null;
                          rule.zip_from = null;
                          rule.zip_up = null;
                        }} style={{cursor: 'pointer', display: 'flex', color: '#ffa600', fontWeight: 'bold'}}>
                          <MapPin style={{width: 15, marginRight: 10}} /> Procurar CEP
                        </a>
                      </Grid>

                      <Grid item xs={6} lg={2}>
                        <InputMask
                          mask="99.999-999"
                          name="zip_up"
                          onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                        
                          onChange={(ev) => {
                            handleChangeRoute(ev, rule.id);
                            zips[rule.id] = null;
                          }}
                          value={zips[rule.id] && !rule.zip_up ? zips[rule.id].zip_to : rule.zip_up}
                        >
                          {() => (
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="CEP Final"
                              name="zip_up"
                              margin="normal"
                              variant="outlined"
                              fullWidth
                              value={zips[rule.id] && !rule.zip_up ? zips[rule.id].zip_to : rule.zip_up}

                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item xs={6} lg={2}>
                        <TextField
                          name="value"
                          onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                          onChange={(ev) => handleChangeRoute(ev, rule.id)}
                          value={rule.value}
                          type="number"
                          label={"Valor"}
                          margin="normal"
                          variant="outlined"
						  disabled={rule.rule_type == 6}
                          fullWidth
                        />
                        {rule.rule_type == 5 && rule.value == 0 ? <small style={{ color: "red" }}>Frete grátis</small> : null}
                      </Grid>

                      <Grid item xs={6} lg={2}>
                        <TextField
                          name="additional_deadline"
                          onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                          onChange={(ev) => handleChangeRoute(ev, rule.id)}
                          value={rule.additional_deadline}
                          type="number"
                          label={"Prazo adicional"}
                          margin="normal"
                          variant="outlined"
						  disabled={rule.rule_type == 6}
                          fullWidth
                        />
                        <small>Prazo adicionado ao tempo de expedição</small> 
                      </Grid>

                      <Grid item xs={1} lg={2}>
                        <IconButton onClick={() => removeRule(rule.id)} style={{ marginTop: 18 }}><Trash /></IconButton>
                      </Grid>

                      {rule.cart_min || rule.min_value ? (
                        <>
                          <Grid item xs={6} lg={3}>
                            <TextField
                              name="min_value"
                              onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                              onChange={(ev) => handleChangeRoute(ev, rule.id)}
                              value={rule.min_value}
                              type="number"
                              label={"Valor mínimo do carrinho"}
                              margin="normal"
                              variant="outlined"
                              required
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={6} lg={3}>
                            <TextField
                              name="max_value"
                              onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                              onChange={(ev) => handleChangeRoute(ev, rule.id)}
                              value={rule.max_value}
                              type="number"
                              label={"Valor máximo do carrinho"}
                              margin="normal"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                        </>
                      ) : null}

                      {rule.total_volumes || rule.apply_only_volumes ? <Grid item xs={6} lg={3}>
                        <TextField
                          name="total_volumes"
                          onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                          onChange={(ev) => handleChangeRoute(ev, rule.id)}
                          value={rule.total_volumes}
                          type="number"
                          label={"Qtd. Volumes"}
                          margin="normal"
                          variant="outlined"
                          fullWidth
                        />
                        <small>Regra válida enquanto a quantidade de volumes for menor ou igual à informada acima</small>
                      </Grid> : null}

                      {rule.apply_only_skus || rule.sku_list ? <Grid item xs={6} lg={6}>
                        <TextareaAutosize
                          placeholder="SKU123,SKU456..."
                          label="Digite a lista de SKUs ao qual deseja aplicar essa regra"
                          variant="outlined"
                          name="sku_list"
                          value={rule.sku_list}
                          onBlur={(ev) => handleBlurRoute(ev, rule.id)}
                          onChange={(ev) => handleChangeRoute(ev, rule.id)}
                        />
                      </Grid> : null}

                      <Grid item xs={12} lg={12} flexDirection="column">

                        <FormControlLabel
                          control={
                            <Checkbox
                              name="cart_min"
                              checked={rule.min_value || rule.cart_min}
                              onChange={(ev) => {
                                handleChangeRoute(ev, rule.id, true);
                                handleChangeRoute({
                                  target: {
                                    name: 'min_value',
                                    value: null
                                  }
                                }, rule.id, true);
                                handleChangeRoute({
                                  target: {
                                    name: 'max_value',
                                    value: null
                                  }
                                }, rule.id, true);
                              }}
                              color="primary"
                            />
                          }
                          label="Aplicar regra por valor de carrinho"
                        />

                        <FormControlLabel
                          disabled={rule.apply_only_volumes}
                          control={
                            <Checkbox
                              name="apply_only_skus"
                              checked={rule.sku_list || rule.apply_only_skus}
                              disabled={rule.apply_only_volumes}
                              onChange={(ev) => {
                                handleChangeRoute(ev, rule.id, true);
                                handleChangeRoute({
                                  target: {
                                    name: 'total_volumes',
                                    value: null
                                  }
                                }, rule.id, true);
                                if (! ev.target.checked) {
                                  handleChangeRoute({
                                    target: {
                                      name: 'sku_list',
                                      value: null
                                    }
                                  }, rule.id, true);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label="Aplicar regra para uma lista de SKUs"
                        />

                        <FormControlLabel
                          disabled={rule.apply_only_skus}
                          control={
                            <Checkbox
                              name="apply_only_volumes"
                              checked={rule.total_volumes || rule.apply_only_volumes}
                              disabled={rule.apply_only_skus}
                              onChange={(ev) => {
                                handleChangeRoute(ev, rule.id, true);
                                handleChangeRoute({
                                  target: {
                                    name: 'sku_list',
                                    value: null
                                  }
                                }, rule.id, true);
                              }}
                              color="primary"
                            />
                          }
                          label="Aplicar regra pela quantidade de itens"
                        />


                      </Grid>

                    </Grid>
                  </CardContent>
                </Card>
              }
              )}
            </Grid>
            <Grid xs={12}>
              <Box my={2}>

                <Button onClick={addRule}>
                  <Plus /> Adicionar um destino.
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Box my={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitText ? submitText : "CADASTRAR"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withSnackbar(Form);
