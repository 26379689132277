import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import { withStyles } from '@material-ui/styles';
import { useSnackbar, withSnackbar } from 'notistack';
import Page from 'src/components/Page';
import StepOne from './StepOne';
import Sheet from './Sheet';
import Toolbar from './Toolbar';
import Form from './Form';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const useStyles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
});

const NewCarrier = () => {
  const enqueueSnackbar = useSnackbar();
  const navigate = useNavigate();

  return (
    <Box mt={3}>
      <Card>
        <CardContent>
          <Form
            initialValues={{
              name: '',
              start_at: moment().format('YYYY-MM-DDTHH:mm'),
              ended_at: ''
            }}
            submitText="CADASTRAR"
            onSubmit={(values, rules) => {
              const apiService = new ApiService();
              return apiService
                .newFreightRule({ ...values, routes: rules })
                .then((res) => {
                  enqueueSnackbar('Regra cadastrada com sucesso', {
                    variant: 'success',
                  });
                  navigate('/settings/freight-rules');
                })
                .catch((res) => {
                  enqueueSnackbar('Ocorreu um erro:' + res.message, {
                    variant: 'error',
                  });
                });

              return true;
            }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};


export default NewCarrier;
