import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';

import Highcharts, { getOptions } from 'highcharts';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Sales = ({ className, totalOrders, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const options = {

    title: {
      text: ''
    },

    subtitle: {
      text: ''
    },

    yAxis: {
      title: {
        text: 'Total de Pedidos'
      }
    },

    xAxis: {
      categories: totalOrders.map((res) => res.period)
    },


    plotOptions: {
      line: {
          dataLabels: {
              enabled: true
          },
          enableMouseTracking: true
      }
  },

    series: [
      {
        backgroundColor: '#ffcc00',
        data: totalOrders.map((res) => res.total),
        name: 'Pedidos Confirmados'
      },
      {
        backgroundColor: colors.grey[200],
        data: totalOrders.map((res) => res.total_unconfirmed),
        name: 'Pedidos Aguardando NF'
      }
    ],
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }]
    }

  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Pedidos por dias"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <HighchartsReact
            options={options}
            highcharts={Highcharts}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
            containerProps={{ className: 'chartContainer' }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
