import React, { useEffect, useState } from 'react';
import Form from "@rjsf/material-ui";
import { JsonToTable } from "react-json-to-table";
import { useNavigate, useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Drawer,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Check, Eye, Trash } from 'react-feather';
import { Link } from 'react-router-dom';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';
import ProductCard from './ProductCard';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const IntegrationsListSystemView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [channels, setChannels] = useState([]);
  const [companyChannels, setCompanyChannels] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [form, setForm] = useState(null);
  const apiService = new ApiService();
  const navigate = useNavigate();

  useEffect(() => {
    apiService
      .getChannels(1)
      .then((res) => {
        setChannels(res.data);
      });

    apiService
      .getCompanyChannels()
      .then((res) => {
        setCompanyChannels(res.data);
      });
  }, []);

  const log = (type) => console.log.bind(console, type);

  const submit = (channel, formData) => {
    setOpenDrawer(false);
    apiService
      .newCompanyChannel({
        channel: {
          id: channel.id
        },
        data: formData
      })
      .then((res) => {
        enqueueSnackbar('Integração adicionada com sucesso.', { variant: 'success' });
        apiService
          .getCompanyChannels()
          .then((res) => {
            var lastItem = res.data.slice(-1)[0] || null;
            navigate(`/settings/integrations/${lastItem.id}/detail`);

          });
      });
  };

  const handleAdd = (channel) => {
    if (typeof channel.layout.oauth !== undefined && channel.layout.oauth) {
      return window.location.href = channel.layout.oauth;
    }

    setOpenDrawer(true);
    setForm(
      <Form
        schema={channel.layout}
        onChange={log('changed')}
        onSubmit={(ev) => submit(channel, ev.formData)}
        onError={log('errors')}
      />
    );
  };

  const previewChannel = (companyChannel) => {
    setOpenDrawer(true);
    setForm(<JsonToTable json={companyChannel.property} />);
  };

  return (
    <Page
      className={classes.root}
      title="Integrações"
    >
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box mt={3} style={{ minWidth: 300, margin: 30 }}>
          {form}
        </Box>
      </Drawer>
      <Container maxWidth={false}>
        <Toolbar title="Integração com ERPs e Marketplaces" />
        <Box mt={3}>
          <Grid
            container
            spacing={3}
          >
            {channels.map((channel) => (
              <Grid
                item
                key={channel.id}
                lg={4}
                md={6}
                xs={12}
              >
                <ProductCard
                  className={classes.productCard}
                  channel={channel}
                  handleAdd={handleAdd}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        
      </Container>
    </Page>
  );
};

export default withSnackbar(IntegrationsListSystemView);
