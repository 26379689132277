import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: '#fff',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    }
  },

  activeButton: {
    color: '#fff',
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    color: '#fff',
    borderLeft: '2px solid #ffcc00',
    backgroundColor: 'rgba(255,255,255,0.1)',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: '#fff',
      display: 'block'
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    display: 'none'
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: '#fff',
    borderLeft: '2px solid #ffcc00',
    backgroundColor: 'rgba(255,255,255,0.1)',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: '#fff',
      display: 'block'
    }
  },
  link: {
    fontWeight: 300,
    color: 'rgba(255, 255, 255, 06)',
    textTransform: 'inherit',
    fontSize: 13,
    borderLeft: '1px solid rgb(255 255 255 / 14%)',
    borderRadius: 0,
    paddingLeft: 10,
    padding: 5,
    '&:hover': {
      fontWeight: 'bold'
    },
  },
  linkActive: {
    fontWeight: 'bold',
    color: '#fff'
  }
}));

const NavItem = ({
  className,
  isLink,
  isActive,
  href,
  icon: Icon,
  title,
  subicon,
  onClick,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      {onClick ? <Button
        activeClassName={isLink ? classes.linkActive : classes.active}
        className={isLink ? classes.link : (isActive ? classes.activeButton : classes.button)}
        style={{backgroundColor: '#373737'}}
        active
        onClick={onClick}
      >
        <Icon
          className={classes.icon}
          style={{display: 'block'}}
          size="20"
        />
        <span className={classes.title}>
          {title}
        </span>

        {subicon}
      </Button> :
        <Button
          activeClassName={isLink ? classes.linkActive : classes.active}
          className={isLink ? classes.link : (isActive ? classes.activeButton : classes.button)}
          component={RouterLink}
          active
          to={href}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>

          {subicon}
        </Button>}

    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
