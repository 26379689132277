import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { getOptions } from 'highcharts';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const StatusGeralBlock = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
 
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 300
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: data.map((item) => item.status_description),
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total de pedidos'
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    legend: {
      enabled: false,
    },

    credits: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr> ' +
        '<td style="padding:0"><b>{point.y}</b> pedidos</td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [{
      name: "Pedidos por status",
      colorByPoint: true,
      data: data.map((item) => ({
        name: item.status_description,
        y: item.total,
        color: item.color
      }))
    }]
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Pedidos - Por status"
      />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <HighchartsReact
            options={options}
            highcharts={Highcharts}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
            containerProps={{ className: 'chartContainer' }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

StatusGeralBlock.propTypes = {
  className: PropTypes.string
};

export default StatusGeralBlock;
