import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Avatar
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}


const OAuthMercadoLivre = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  const apiService = new ApiService();
  const flistLogUrl = `https://app.flixlog.com/settings/integrations/mercadolivre`;
  const clientId = '1978539127333779';
  const channelId = 30;

  const isAuth = localStorage.getItem('@flixlog-token');


if (getQueryVariable('code')) {
  window.location.href =  '/settings/integrations/mercadolivre?code=' + getQueryVariable('code');
}

  const redirectToMercadolivre = () => {
    setDisableButton(true);
    var url = 'https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=' + clientId + '&redirect_uri=' + flistLogUrl;
    var code = getQueryVariable('code');

    if (isAuth) {
      window.location.href = code ? '/settings/integrations/mercadolivre?code=' + code : url;
    } else {
      window.location.href = `/login?redirect=${btoa(url)}`;
    }
  };

  useEffect(() => {
    apiService
      .getCompanyChannels()
      .then((res) => {
        res.data.forEach((cc) => {
          if (cc.channel_id === channelId) {
            return window.location.href = '/settings/integrations/' + cc.id + '/detail';
          }
        });
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title="Mercadolivre + Flixlog"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            onSubmit={(values) => {
              return redirectToMercadolivre();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  

                <center>
                                   <img src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png" style={{maxWidth: 200}} />
                                     </center>                  <br />
                  <br />
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: 'center' }}
                  >
                  </Typography>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{ textAlign: 'center' }}

                  >
                    Clique no botão abaixo para iniciar o processo de <br /> integração da Mercadolivre com a Flixlog
                  </Typography>
                </Box>

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isSubmitting ? 'AGUARDE ...' : 'INTEGRAR MERCADOLIVRE + FLIXLOG'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(OAuthMercadoLivre);
