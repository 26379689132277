import React, { useState } from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import { styled } from '@mui/material/styles';

import Textfield from '@atlaskit/textfield';
import Select from '@atlaskit/select';
import DynamicTable from '@atlaskit/dynamic-table';
import { ClassNames } from '@emotion/react';
import { Card, CardContent, CardHeader, Grid, IconButton, Slide, Typography, Divider } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Pagination from '@atlaskit/pagination';
import ShipmentsConfirmed from 'src/views/reports/DashboardView/ShipmentsConfirmed';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from '@atlaskit/button/standard-button';



const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: '#fff',  
    '& table th': {
      color: '#242424',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '10px 15px !important'
    },
    '& table tbody td': {
      color: '#242424',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      padding: '10px 15px !important',
      cursor: 'pointer',
      borderBottom: '1px solid #f4f5f7'
    }
  },
  slide: {
    position: 'absolute',
    zIndex: 1,
    width: '96%',
    minHeight: '700px',
    top: '120px',
    right: '-24px',
    boxShadow: '0 0 10px #c3c3c3',
    padding: '0',
    background: '#fff',
    borderRadius: '5px'
  },
  buttonClose: {
    position: 'absolute',
    left: '-35px',
    background: '#fff !important',
    borderRadius: 0,
    boxShadow: '0 0 10px #ddd',
    zIndex: 0,
    border: '1px solid #d3d3d3',
    top: '23px',
    borderRight: 0,
    padding: '5px',
  }
}));

export default function DataTable({ rows, head, isLoading, filters, limit, page, totalRows, changePage, caption, window, onCloseWindow, height, dashboard }) {
  const classes = useStyles();

  const getNumPages = (totalRows, limit) => {
    const totalPages = Math.ceil(totalRows / limit);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return pages;
  }

  return (
    <>
      {dashboard && <><Grid
        container
        spacing={3}
      >
        {dashboard.map((dash) => <Grid
          item
          lg={2}
          sm={3}
          xl={2}
          xs={12}
          style={{ marginBottom: '-2px' }}
        >
          <div style={{
            padding: 5,
            background: '#f4f5f7',
            color: '#5e6c84',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            justifyContent: 'center'
          }} className={`dash-card ${dash.type}`}>
            <strong>{dash.label.toUpperCase()} <span>{dash.value}</span></strong>
          </div>
        </Grid>)}
      </Grid>
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
      </>}

      {caption && <Typography variant='h1'>{caption}</Typography>}
      <div style={{ width: '100%', minHeight: 300, position: 'relative', background: '#fff',     border: '1px solid #ddd' }}>
        {filters?.length ? <div style={{
          marginBottom: 0,
          display: 'flex',
          background: 'rgb(244 245 247)',
          padding: '10px',
          border: '1px solid rgb(221 221 221)',
          borderTop: 'none',
          borderLeft: 0,
          borderRight: 0
        }}><div style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
            <strong>Filtrar por</strong>
          </div>
          {filters.map((filter, index) => {
            switch (filter.type) {
            case 'button':
              return <div style={{ marginRight: 20 }}>
				<Button isDisabled={filter.isDisabled} onClick={filter.onClick} appearance={filter.appearance ? filter.appearance : 'primary'}>{filter.text}</Button>
				</div>
              break;
              case 'search':
                return <div style={{ marginRight: 20 }}>
                  <Textfield
                    name={filter.filter}
                    placeholder={filter.placeholder}
                    onChange={filter.onChange ? (e) => filter.onChange(filter.filter, e.target.value) : undefined}
                    onBlur={filter.onBlur ? (item) => filter.onBlur(filter.filter, item?.value) : undefined}
                    onKeyPress={(e) => e.key === 'Enter' && filter.onEnter(filter.filter, e.target.value)}
                    style={{ border: '1px solid rgb(221 221 221) !important' }}
                  /></div>
                break;
              case 'select':
                return <div style={{ marginRight: 20, width: filter.width || 150 }}>
                  <Select
                    isOptionSelected
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    options={filter.options}
                    style={filter.width ? { width: filter.width, border: '1px solid rgb(221 221 221) !important' } : { border: '1px solid rgb(221 221 221) !important' }}
                    isClearable
                    placeholder={filter.placeholder}
                    onChange={filter.onChange ? (item) => filter.onChange(filter.filter, item?.value) : undefined}

                  /></div>
                break;
            case 'date':
              return <div style={{ marginRight: 20,  display: 'flex',  alignItems: 'center' }}>
				<span style={{paddingRight: 20}}>{filter.placeholder}</span> 
				
				<div style={{display: 'flex'}}>
              <Textfield
                name={filter.filter}
				type="date"
				value={filter.value_start}
                placeholder={filter.placeholder}
                onChange={filter.onChangeStart ? (item) => filter.onChangeStart(filter.filter, item?.target.value) : undefined}
                 style={{ border: '1px solid rgb(221 221 221) !important' }}
              />
              <Textfield
                name={filter.filter}
				type="date"
                placeholder={filter.placeholder}
				value={filter.value_end}
                onChange={filter.onChangeEnd ? (item) => filter.onChangeEnd(filter.filter, item?.target.value) : undefined}
                 style={{ border: '1px solid rgb(221 221 221) !important' }}
              /></div>
				</div>
              break;
            }
          })}</div> : null}

        <div className={classes.table} style={height ? { height: height, overflow: 'auto' } : {}}>
          <PerfectScrollbar>
            <DynamicTable
              head={head}
              rows={rows}
              loadingSpinnerSize="large"
              isLoading={isLoading}
              onChange={(e, a, b) => console.table(e, a, b)}
              emptyView={<h2>Nenhum registro retornado</h2>}
            />
          </PerfectScrollbar>
          <Grid container>
              <Grid item lg={12} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Pagination
                  isDisabled={true}
                  pages={getNumPages(totalRows, limit)}
                  onChange={(ev, page) => changePage(page)}
                />
              </Grid>
            </Grid>
          {window ? 
            <Drawer anchor="right" open={window} onClose={onCloseWindow}>
              <div style={{maxWidth: 1166}}>{window}</div>
            </Drawer>
			  : null}

        </div>
      </div>
    </>
  );
}
