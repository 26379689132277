import {
  ORDER_SEARCH,
  ORDER_SEARCH_STATUS,
  ORDER_SEARCH_CARRIER,
  SET_AUTHENTICATION_REQUESTED,
  SET_AUTHENTICATION_SUCCEED,
  SET_AUTHENTICATION_FAILED,
  PRODUCT_FILTER
} from './actionTypes';

export const setAuthenticationRequested = () => ({
  type: SET_AUTHENTICATION_REQUESTED,
});

export const setAuthenticationSucceed = ({ token, user }) => ({
  payload: { token, user },
  type: SET_AUTHENTICATION_SUCCEED,
});

export const setAuthenticationFailed = () => ({
  type: SET_AUTHENTICATION_FAILED,
});

export const changeOrderSearch = (value, many) => ({
  type: ORDER_SEARCH,
  search: value,
  many: many
});

export const changeOrderSearchStatus = (value) => ({
  type: ORDER_SEARCH_STATUS,
  status: value
});

export const changeOrderSearchCarrier = (value) => ({
  type: ORDER_SEARCH_CARRIER,
  carrier: value
});

export const filterProduct = (filter) => ({
  type: PRODUCT_FILTER,
  filter: filter
});
