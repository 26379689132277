
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, hasPermission, isSuperUser } from 'src/common/auth';
import NewDistributionCenterView from 'src/views/settings/DistributionCenter/New';
import EditDistributionCenterView from 'src/views/settings/DistributionCenter/Edit';
import DeleteDistributionCenterView from 'src/views/settings/DistributionCenter/Delete';
import DistributionCenterView from 'src/views/settings/DistributionCenter';
import DeleteIntegrationView from 'src/views/settings/IntegrationsListView/Delete';

import NewFreightTableView from 'src/views/settings/FreightTablesView/New';
import ReuseView from 'src/views/settings/FreightTablesView/Reuse';

import QuotationView from 'src/views/settings/QuotationView';


import NewOrderView from 'src/views/orders/OrdersView/New';
import EditOrderView from 'src/views/orders/OrdersView/Edit';
import NewImportView from 'src/views/orders/OrdersView/Import';
import NewUserView from 'src/views/settings/UsersListView/New';
import EditUserView from 'src/views/settings/UsersListView/Edit';
import DeleteUserView from 'src/views/settings/UsersListView/Delete';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import UsersListView from './views/settings/UsersListView';
import CarriersListView from './views/settings/CarriersListView';
import IntegrationsListSystemView from './views/settings/IntegrationsListView';
import IntegrationsListViewActive from './views/settings/IntegrationsListView/active';
import IntegrationsListCarrierView from './views/settings/IntegrationsListView/carrier';
import ProductsListView from './views/settings/ProductsListView';
import NewProductView from './views/settings/ProductsListView/New';
import EditProductView from './views/settings/ProductsListView/Edit';
import DeleteProductView from './views/settings/ProductsListView/Delete';

import PickupsListView from './views/pickups/PickupsView';
import PickupsListViewV2 from './views/pickups/v2/pickups/PickupsView';

import NewPickupView from './views/pickups/PickupsView/New';
import OrderPreview from './views/orders/OrderPreview';
import FreightTablesView from './views/settings/FreightTablesView';
import QuoteView from './views/quote';
import LogoutView from './views/auth/LogoutView';
import ResetPasswordView from './views/auth/ResetPassword';
import NewPasswordView from './views/auth/NewPasswordView';
import ConfirmEmailView from './views/auth/ConfirmEmailView';
import TrackingView from './views/tracking/TrackingView';
import TrackingCompanyView from './views/tracking/TrackingCompanyView';
import RateDeliveryView from './views/auth/RateDeliveryView';
import OAuth from './views/auth/OAuth';
import OAuthNuvemShop from './views/auth/OAuthNuvemShop';
import Complete from './views/auth/tray/Complete';
import CompleteNuvemShop from './views/auth/nuvemshop/Complete';
import CompleteMercadoLivre from './views/auth/mercadolivre/Complete';
import FreightRuleView from './views/settings/FreightRules';
import NewFreightRuleView from './views/settings/FreightRules/New';
import EditFreightRuleView from './views/settings/FreightRules/Edit';
import DeleteFreightRuleView from './views/settings/FreightRules/Delete';

import ContingencyTableView from './views/settings/ContingencyTable';
import NewContingencyTableView from './views/settings/ContingencyTable/New';
import IntegrationDetail from './views/settings/IntegrationsListView/Detail';
import OAuthMercadoLivre from './views/auth/OAuthMercadoLivre';
import TrackingLinkView from './views/tracking/TrackingLinkView';
import PublicLayout from 'src/layouts/MainLayout';
import TrackingLinkCustomView from './views/tracking/TrackingLinkCustomView';
import TrackingCustomView from './views/tracking/TrackingCustomView';
import NotfisView from './views/carrier/NotfisView';
import QuotationLog from './views/quote/QuotationLog';
import RegisterView from './views/auth/RegisterView';
import CarrierRegisterView from './views/carrier/RegisterView';

import AuditView from './views/orders/v2/AuditView';
import OrdersView from './views/orders/v2/OrdersView';
import OrdersViewV1 from './views/orders/OrdersView';
import Import from './views/orders/v2/AuditView/Import';
import WithoutOrders from './views/orders/v2/AuditView/WithoutOrders';
import Onboarding from './views/account/Onboarding';

const PrivateComponent = ({ component: Component, ...rest }) => (
  isAuthenticated() ? <Component {...rest} /> : window.location.href = '/login'
);

const child = [
  { path: '', element: hasPermission('dashboard') ? <PrivateComponent component={DashboardView} /> : null },
  { path: 'onboarding', element: hasPermission('onboarding') ? <PrivateComponent component={Onboarding} /> : null },
  { path: 'dashboard', element: hasPermission('dashboard') ? <PrivateComponent component={DashboardView} /> : null },
  { path: 'logout', element: <PrivateComponent component={LogoutView} /> },
  { path: 'orders', element: hasPermission('orders') ? <PrivateComponent component={OrdersView} /> : null },
  { path: 'v1/orders', element: hasPermission('orders') ? <PrivateComponent component={OrdersViewV1} /> : null },
  { path: 'v2/pickups', element: hasPermission('pickups') ? isSuperUser() && <PrivateComponent component={PickupsListViewV2} /> : null },
  { path: 'pickups', element: hasPermission('pickups') ? <PrivateComponent component={PickupsListView} /> : null },
  { path: 'pickups/new', element: hasPermission('pickups') ? <PrivateComponent component={NewPickupView} /> : null },
  { path: 'orders/new', element: hasPermission('orders') ? <PrivateComponent component={NewOrderView} /> : null },
  { path: 'orders/import', element: hasPermission('orders') ? <PrivateComponent component={NewImportView} /> : null },
  { path: 'orders/:id', element: hasPermission('orders') ? <PrivateComponent component={OrderPreview} /> : null },
  { path: 'orders/:id/edit', element: hasPermission('orders') ? <PrivateComponent component={EditOrderView} /> : null },
  { path: 'quote', element: hasPermission('quotation') ? <PrivateComponent component={QuoteView} /> : null },
  { path: 'quote/logs', element: hasPermission('quotation') ? <PrivateComponent component={QuotationLog} /> : null },
  { path: 'audit', element: hasPermission('audit') ? <PrivateComponent component={AuditView} /> : null },
  { path: 'audit/import', element: hasPermission('audit') ? <PrivateComponent component={Import} /> : null },
  { path: 'audit/without-order', element: hasPermission('audit') ? <PrivateComponent component={WithoutOrders} /> : null },
  { path: 'account', element: <PrivateComponent component={AccountView} /> },
  { path: 'settings', element: hasPermission('settings') ? <PrivateComponent component={DistributionCenterView} /> : null },

  { path: '*', element: <Navigate to="/404" /> }
];

if (hasPermission('settings')) {
  [
    { path: 'settings/distribution-centers', element: <PrivateComponent component={DistributionCenterView} /> },
    { path: 'settings/distribution-centers/new', element: <PrivateComponent component={NewDistributionCenterView} /> },
    { path: 'settings/distribution-centers/edit/:id', element: <PrivateComponent component={EditDistributionCenterView} /> },
    { path: 'settings/distribution-centers/remove/:id', element: <PrivateComponent component={DeleteDistributionCenterView} /> },
    { path: 'settings', element: <PrivateComponent component={DistributionCenterView} /> },

    { path: 'settings/freight-rules', element: <PrivateComponent component={FreightRuleView} /> },
    { path: 'settings/freight-rules/new', element: <PrivateComponent component={NewFreightRuleView} /> },
    { path: 'settings/freight-rules/edit/:id', element: <PrivateComponent component={EditFreightRuleView} /> },
    { path: 'settings/freight-rules/remove/:id', element: <PrivateComponent component={DeleteFreightRuleView} /> },

    { path: 'settings/contingency-tables', element: <PrivateComponent component={ContingencyTableView} /> },
    { path: 'settings/contingency-tables/new', element: <PrivateComponent component={NewContingencyTableView} /> },

    { path: 'settings', element: <PrivateComponent component={DistributionCenterView} /> },

    { path: 'settings/products', element: <PrivateComponent component={ProductsListView} /> },
    { path: 'settings/products/new', element: <PrivateComponent component={NewProductView} /> },
    { path: 'settings/products/edit/:id', element: <PrivateComponent component={EditProductView} /> },
    { path: 'settings/products/remove/:id', element: <PrivateComponent component={DeleteProductView} /> },

    { path: 'settings/integrations', element: <PrivateComponent component={IntegrationsListViewActive} /> },
    { path: 'settings/integrations/:id/detail', element: <PrivateComponent component={IntegrationDetail} /> },
    { path: 'settings/integrations/active', element: <PrivateComponent component={IntegrationsListViewActive} /> },
    { path: 'settings/integrations/systems', element: <PrivateComponent component={IntegrationsListSystemView} /> },
    { path: 'settings/integrations/carriers', element: <PrivateComponent component={IntegrationsListCarrierView} /> },

    { path: 'settings/integrations/tray', element: <PrivateComponent component={Complete} /> },
    { path: 'settings/integrations/nuvemshop', element: <PrivateComponent component={CompleteNuvemShop} /> },
    { path: 'settings/integrations/mercadolivre', element: <PrivateComponent component={CompleteMercadoLivre} /> },
    { path: 'settings/integrations/remove/:id', element: <PrivateComponent component={DeleteIntegrationView} /> },

    { path: 'settings/carriers', element: <PrivateComponent component={CarriersListView} /> },
    { path: 'settings/carriers/new', element: <CarrierRegisterView /> },


    { path: 'settings/users', element: <PrivateComponent component={UsersListView} /> },
    { path: 'settings/users/new', element: <PrivateComponent component={NewUserView} /> },
    { path: 'settings/users/edit/:id', element: <PrivateComponent component={EditUserView} /> },
    { path: 'settings/users/remove/:id', element: <PrivateComponent component={DeleteUserView} /> },

    { path: 'settings/freight-tables', element: <PrivateComponent component={FreightTablesView} /> },
    { path: 'settings/freight-tables/new', element: <PrivateComponent component={NewFreightTableView} /> },
    { path: 'settings/freight-tables/reuse', element: <PrivateComponent component={ReuseView} /> },

    { path: 'settings/quotation-views', element: <PrivateComponent component={QuotationView} /> },
  ].map((item) => child.push(item));
}

const routes = [
  {
    path: '/',
    element: <PrivateComponent component={DashboardLayout} />,
    children: child
  },
  {
    path: '/tracking',
    element: <PublicLayout />,
    children: [
      { path: '', element: <TrackingView /> },
      { path: 'c/:company', element: <TrackingLinkCustomView /> },
      { path: 'c/:company/:trackingCode', element: <TrackingLinkCustomView /> },
      { path: ':trackingCode', element: <TrackingLinkView /> },
    ]
  },
  {
    path: '/carrier',
    element: <PublicLayout />,
    children: [
      { path: 'notfis/:token', element: <NotfisView /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'connect/tray', element: <OAuth /> },
      { path: 'connect/nuvemshop', element: <OAuthNuvemShop /> },
      { path: 'connect/mercadolivre', element: <OAuthMercadoLivre /> },
      { path: 'company-tracking/:company', element: <TrackingCompanyView /> },
      { path: 'rate/:trackingCode/:token', element: <RateDeliveryView /> },
      { path: 'confirm/:token', element: <ConfirmEmailView /> },
      { path: 'reset-password', element: <ResetPasswordView /> },
      { path: 'new-password/:token', element: <NewPasswordView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
