import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import ApiService from 'src/common/apiService';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ProductsListView = ({ sku, filters }) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const apiService = new ApiService();
  const [limit, changeLimit] = useState(10);
  const [page, changePage] = useState(1);

  const refresh = (filters) => {
    apiService
      .getProducts(filters, limit, page)
      .then((res) => {
        setProducts(res.data);
        setTotal(res.total);
      });
  };

  useEffect(() => {
    if (sku) {
      refresh({reference: sku, filters: filters});
    } else {
      refresh({filters: filters});
    }
  }, [sku, filters, limit, page]);

  return (
    <Page
      className={classes.root}
      title="Produtos"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results
            products={products}
            total={total}
            changePage={changePage}
            changeLimit={changeLimit}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (store) => ({
  sku: store.orderSearch.value,
  filters: store.filterProduct.filter
});

export default connect(mapStateToProps)(ProductsListView);
