import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputMask from "react-input-mask";
import axios from 'axios';
import * as _ from 'underscore';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select
} from '@material-ui/core';
import Page from 'src/components/Page';
import FormRegister from './FormRegister';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 0, marginBottom: 60, background: '#f5f6f8' }}>
        <img src="https://flixlog.com/images/flixlogo.png" style={{ width: 150, margin: '0 auto' }} />
      </div>

      <Box
        display="flex"
        justifyContent="center"
      >
        <Container maxWidth="sm" style={{padding: 50, backgroundColor: '#fff', borderRadius: 50}}>
          <FormRegister />
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
