import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Form from './Form';

import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
} from '@material-ui/core';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditProductView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const match = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getProducts({ id: match.id }, 1, 1)
      .then((res) => setProduct(res.data[0]));
  }, [match.id]);

  return (
    <Page
      className={classes.root}
      title={`Editar - Produto`}
    >
      <Container maxWidth={false}>
        <Toolbar hideButton />
        <Box mt={3}>
          <Card>
            <CardContent>
              {product ? (
                <Form
                  initialValues={{
                    reference: product.reference,
                    description: product.description,
                    price: product.price,
                    distribution_center_id: product.distribution_center_id,
                    width: product.width * 100,
                    height: product.height * 100,
                    length: product.length * 100,
                    weight: product.weight,
                    quantity: product.quantity,
                    expedition_time: product.expedition_time,
                    property: product.property && product.property.length > 0 ? product.property.map((res) => {
                      res.width = res.width * 100;
                      res.height = res.height * 100;
                      res.length = res.length * 100;
                      return res;
                    }) : [],
                  }}
                  title={`Editar - ${product ? product.reference : null}`}
                  submitText="SALVAR"
                  onSubmit={(values, parcels) => {
                    const apiService = new ApiService();

                    apiService
                      .updateProduct({
                        reference: values.reference,
                        description: values.description,
                        price: values.price,
                        distribution_center_id: values.distribution_center_id,
                        width: values.width / 100,
                        height: values.height / 100,
                        length: values.length / 100,
                        weight: values.weight,
                        quantity: values.quantity,
                        expedition_time: values.expedition_time,
                        property: parcels.map((parcel) => {
                          return {
                            reference: values.reference,
                            width: parcel.width / 100,
                            height: parcel.height / 100,
                            length: parcel.length / 100,
                            weight: parcel.weight,
                            quantity: parcel.quantity,
                          };
                        })
                      })
                      .then(() => {
                        enqueueSnackbar('Produto atualizado com sucesso', {
                          variant: 'success',
                        });
                      })
                      .catch((res) => {
                        enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                          variant: 'error',
                        });
                      });

                    return true;
                  }}
                />
              ) : 'Carregando...'}
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(EditProductView);
