import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Form from './Form';

import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
} from '@material-ui/core';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditUserView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const match = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getUser(match.id)
      .then((res) => setUser(res));
  }, [match.id]);

  return (
    <Page
      className={classes.root}
      title="Editar - Usuário"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton />
        <Box mt={3}>
          <Card>
            <CardContent>
              {user ? (
                <Form
                  initialValues={{
                    name: user.name,
                    email: user.email,
                    password: false,
                    is_admin: user.is_admin,
                    permissions: user.permissions === null ? [
                      'dashboard',
                      'orders',
                      'pickups',
                      'quotation',
                      'settings'
                    ] : user.permissions
                  }}
                  title={`Editar - ${user.name}`}
                  submitText="SALVAR"
                  onSubmit={(values, permissions) => {
                    const apiService = new ApiService();
                    apiService
                      .updateUser({
                        id: user.id,
                        name: values.name,
                        email: values.email,
                        is_admin: values.is_admin,
                        permissions: permissions || {}
                      })
                      .then(() => {
                        enqueueSnackbar('Usuário atualizado com sucesso', {
                          variant: 'success',
                        });
                      })
                      .catch((res) => {
                        enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                          variant: 'error',
                        });
                      });

                    return true;
                  }}
                />
              ) : 'Carregando...'}
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(EditUserView);
