import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
  Box
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import DataTable from '../../../../layouts/DashboardLayout/DataTables';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { ArrowDown, ArrowUp, ExternalLink, Flag, MapPin } from 'react-feather';
import { LoadingButton } from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import AvatarGroup from '@atlaskit/avatar-group';
import { FlagRounded, WarningRounded } from '@material-ui/icons';
import EditView from './Edit.js';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  variationBad: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'red'
  },
  variationGood: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'green'
  }
}));

const AuditList = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [ctes, setCtes] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(props.limit ? props.limit : 20);
  const [page, changePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(props.pickupId ? { pickup_id: props.pickupId } : props.filters ? props.filters : null);
  const [carriers, setCarriers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  const [highlightedPickup, setHighlightedPickup] = useState(null);
  const [window, setWindow] = useState(null);

  const [selectedRows, setSelectedRowsIds] = useState(props.selectedRows ? props.selectedRows : []);

  const handleSelectAll = (event) => {
    let rowIds;
    let entities = [];

    if (event.target.checked) {
      rowIds = ctes.map((order) => order.id);
      entities = ctes;
    } else {
      rowIds = [];
      entities = [];
    }

    setSelectedRowsIds(rowIds);
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
    if (props.onSelectEntities) {
      props.onSelectEntities(entities);
    }
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);

    if (props.onSelect) {
      props.onSelect(rowIds);
    }

    if (props.onSelectEntities) {
      props.onSelectEntities(ctes.filter((order) => rowIds.indexOf(order.id) !== -1));
    }
  };


  const [statuses, setStatuses] = useState([
    { value: 12, label: 'Aguardando Cotação', badge: 'removed' },
    { value: 11, label: 'Aguardando Aprovação', badge: 'moved' },
    { value: 1, label: 'Aguardando Nota', badge: 'new' },
    { value: 2, label: 'Aguardando aceite', badge: 'inprogress' },
    { value: 3, label: 'Em trânsito', badge: 'success' },
    { value: 4, label: 'Saiu para entrega', badge: 'success' },
    { value: 5, label: 'Entregue', badge: 'success' },
    { value: 6, label: 'Coleta aceita', badge: 'success' },
    { value: 7, label: 'Retornando', badge: 'moved' },
    { value: 8, label: 'Falha', badge: 'removed' },
    { value: 9, label: 'Cancelado', badge: undefined },
    { value: 10, label: 'Erro', badge: 'removed' },
  ]);

  const diffStatus = [
    { value: 'up', label: 'Diferença de frete para cima' },
    { value: 'down', label: 'Diferença de frete para baixo' },
    { value: 'no_diff', label: 'Frete exato' },
  ];

  const channelIconsUrl = (channel) => {
    var urls = {
      madeiramadeira: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/madeiramadeira.png',
      tiny: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/tiny-logo.png',
      bling: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/bling-logo.png',
      tray: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/logo-tray.png',
      b2w: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/skyhub.png',
      magalu: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/magalu-logo.jpg',
      viavarejo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/viavarejo.png',
      woo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/woo-logo.jpeg',
      'tray-corp': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      'fbitsws': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      omie: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/omie-logo.png',
      simplo7: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/simplo7-logo.png',
      leroy: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/leroy-logo.png',
      nuvemshop: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/nuvemshop-logo2.png',
      mercadolivre: 'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png',
    }
    return urls[channel.toLocaleLowerCase()] || null;
  }

  const handleFilters = (filter, value) => {
	props.handleFilters({ ...filters, [filter]: value });
    setFilters({ ...filters, [filter]: value });
  }

  const handleFiltersStart = (filter, value) => {
	filter = `${filter}_start`;
	props.handleFilters({ ...filters, [filter]: value });
	
    setFilters({ ...filters, [filter]: value });
  }
  
  const handleFiltersEnd = (filter, value) => {
	filter = `${filter}_end`;
	props.handleFilters({ ...filters, [filter]: value });
	
    setFilters({ ...filters, [filter]: value });
  }
  
  const getCarriers = () => {
    apiService
      .getCarriers(true, true)
      .then((res) => setCarriers(res.data));
  };

  const getCompanies = () => {
    apiService
      .getCompanies()
      .then((res) => setCompanies(res.data));
  };

  const reloadCtes = (filters, sort) => {
    setIsLoading(true);
    apiService
      .getCtes(filters, limit, page, props.isPickupPage)
      .then((res) => {
        setCtes(res.data);
        setTotalRows(res.total);
        setIsLoading(false);
      });
  };

  const openCte = (cteId) => {
    setWindow(null);
    setIsLoading(true);
    setHighlightedPickup(cteId);
    apiService
      .getCte(cteId)
      .then((res) => {
        setWindow(
          <EditView
            cte={res}
            callReload={() => reloadCtes()}
            onCloseWindow={() => setWindow(null)} />);
        setIsLoading(false);
      });

    ;
  }

  useEffect(() => {
    reloadCtes(filters);
  }, [filters, limit, page, props.reloadCtes]);

  useEffect(() => {
    getCarriers(true);
    getCompanies();
  }, []);

  return (
    <>

      <DataTable
        rows={ctes}
        caption={props.caption}
        filters={
          [
            {
              placeholder: 'Buscar por CTE, Pedido ou Nota',
              type: 'search',
              filter: 'reference',
              // onBlur: handleFilters,
              onEnter: handleFilters,
            },
            {
              placeholder: 'Transportadora',
              type: 'select',
              filter: 'carrier',
              options: carriers.map((carrier) => ({ value: carrier.id, label: `${carrier.id} - ${carrier.canonical_name.toUpperCase()}` })),
              onChange: handleFilters
            },
            {
              placeholder: 'Diferença',
              type: 'select',
              filter: 'difference',
              options: diffStatus,
              onChange: handleFilters
            },
              {
                placeholder: 'Data de importação',
                type: 'date',
                filter: 'created_at',
                width: 300,
				onChangeStart: handleFiltersStart,
                onChangeEnd: handleFiltersEnd
              },
          ].filter(res => res)
        }
        head={{
          key: `table-order`,
          isHighlighted: false,
          cells: [
            {
              key: 'cte',
              content: 'CTE',
              isSortable: false,
            },
            {
              key: 'reference',
              content: 'Pedido / NF',
              isSortable: false,
            },
            {
              key: 'carrier',
              content: 'Transportadora',
              isSortable: false
            },
            {
              key: 'addressee',
              content: 'Destinatário',
              isSortable: false,
              width: 20
            },
            {
              key: 'estimated_cost',
              width: 20,
              content: 'FRT. Cotado / FRT. Cobrado',
              isSortable: false
            }, {
              key: 'cargo_value',
              width: 20,
              content: 'NF. Total / NF. Declarado',
              isSortable: false
            },
            {
              key: 'weight',
              width: 20,
              content: 'P. Cotado / P. Cobrado',
              isSortable: false
            },
            {
              key: 'variation',
              content: 'Diferença (R$)',
              isSortable: false
            },
            {
              key: 'created_at',
              content: 'Importado em',
              isSortable: false
            },
            {
              key: 'actions',
              content: '',
              isSortable: false
            }
          ].filter(res => res)
        }}
        rows={ctes.map((cte, index) => ({
          key: `row-${index}-${cte.id}`,
          isHighlighted: highlightedPickup == cte.id,
          onClick: () => openCte(cte.id),
          cells: [
            {
              key: cte.id,
              content: <Tooltip title={"Número do CTE"}><strong>{cte.number}</strong></Tooltip>
            },
            {
              key: cte.shipment.reference,
              content: <>
                <Tooltip title={"Número do pedido"}><Link to={`/orders/${cte.shipment.order_id}`} style={{ color: '#2196f3' }}><strong>{cte.shipment.reference}</strong></Link></Tooltip>
                {cte.invoice.number ? <><br /><Tooltip title={"Número da nota fiscal"}><strong>{cte.invoice.number}</strong></Tooltip></> : null}
              </>
            },
            {
              key: cte.carrier.canonical_name,
              content: cte.carrier?.canonical_name == cte.shipment?.carrier?.canonical_name ? <strong>{cte.carrier?.canonical_name?.toUpperCase()}</strong> : <><Tooltip title={"Transportadora do Pedido"}><strong style={{ display: 'block', width: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cte.shipment?.carrier?.canonical_name?.toUpperCase()} / </strong></Tooltip>  <Tooltip title={"Transportadora do CTE"}><strong style={{ display: 'block', width: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cte.carrier?.canonical_name?.toUpperCase()}</strong></Tooltip></>
            },
            {
              key: cte.shipment.to_address.id,
              content: <>
                <Tooltip title={cte.shipment.to_address.name}><strong style={{ display: 'block', width: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cte.shipment.to_address.name}</strong></Tooltip>
                <small style={{ display: 'flex', alignItems: 'center' }}><MapPin style={{ width: 10, marginRight: 5 }} /> {cte.shipment.to_address.city} / {cte.shipment.to_address.state}</small>
              </>
            },
            {
              key: 'estimated_cost',
              content: <>
                {cte.shipment.delivery.estimated_cost ? <><Tooltip title={"Valor da cotação do frete com base na tabela"}><span>F: {cte.shipment.delivery.estimated_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></Tooltip><br /></> : null}
                {cte.freight_value ? <Tooltip title={"Valor do frete cobrado pela transportadora."}><span>T: {cte.freight_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></Tooltip> : null}
              </>
            },

            {
              key: 'cargo_value',
              content: <>
                {cte.shipment.cargo_value ? <><Tooltip title={"Valor de nota utilizado para cotação"}><span>F: {cte.shipment.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></Tooltip><br /></> : null}
                {cte.amount ? <Tooltip title={"Valor de nota declarado pela transportadora."}><span>T: {cte.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></Tooltip> : ' - '}
              </>
            },
            {
              key: 'weight',
              content: <>
                {cte.shipment.delivery.cubed_weight ? <><Tooltip title={"Peso utilizado para cotação"}><span>F: {cte.shipment.delivery.cubed_weight.toLocaleString('pt-BR')}KG</span></Tooltip><br /></> : null}
                {cte.total_weight ? <Tooltip title={"Peso declarado pela transportadora."}><span>T: {cte.total_weight.toLocaleString('pt-BR') + 'KG'}</span></Tooltip> : ' - '}
              </>
            },
            {
              key: 'variation',
              content: <>
                <Tooltip title={cte.variation.message}><span className={cte.variation.total > 0 ? classes.variationBad : classes.variationGood}>
                  {cte.variation.total > 0 ? <ArrowUp /> : <ArrowDown />} {cte.variation.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></Tooltip>
              </>
            },
            {
              key: 'created_at',
              content: <Tooltip title={moment(cte.created_at).format()}><span>{moment(cte.created_at).format('DD/MM HH:mm')}</span></Tooltip>
            },
            {
              key: 'actions',
              content: <> </>
            }
          ].filter(res => res)
        }))}
        limit={limit}
        page={page}
        height={props.height ? props.height : 500}
        changePage={changePage}
        totalRows={totalRows}
        isLoading={isLoading}
        window={window}
        onCloseWindow={() => setWindow(null)}
      />
    </>
  );
};


export default AuditList;
