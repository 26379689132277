import React, { useState, useEffect, useCallback } from 'react';

import {
  makeStyles,
  Container,
  Grid,
  Drawer,
  Tooltip,
  Divider,
  TextField
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import Header from 'src/layouts/DashboardLayout/Header';
import { Check, ChevronRight, ExternalLink, Gift, Home, Package, Phone, Play, User, Users, Zap } from 'react-feather';
import { Close, TableChart } from '@material-ui/icons';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Link } from 'react-router-dom';
import SectionMessage from '@atlaskit/section-message';
import { Alert, AlertTitle } from '@material-ui/lab';
import moment from 'moment';
import Form, { CheckboxField, ErrorMessage, Field, Fieldset, FormFooter, FormHeader, FormSection, HelperMessage } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import SimpleReactFileUpload from '../orders/OrderPreview/FileUpload';
import { getToken } from 'src/common/auth';
import Dropzone, { defaultClassNames, Layout } from 'react-dropzone-uploader';
import Checkbox from '@atlaskit/checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    background: '#2196f3',
    width: '157px',
    padding: '5px',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#fff',
    borderRadius: '5px'
  },
  buttonClicked: {
    display: 'flex',
    alignItems: 'center',
    background: '#9e9e9e',
    width: '157px',
    padding: '5px',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#fff',
    borderRadius: '5px'
  },
  buttonIcon: {
    width: 12,
    marginLeft: 5
  }
}));


const CustomLayout = ({ setFiles, disableSubmit }) => {
  const getUploadParams = ({ file }) => {
    const apiService = new ApiService();

    const body = new FormData();
    body.append('file', file);

    return {
      url: `${apiService.getBaseURL()}upload`,
      body,
      headers: {
        'X-Token': getToken()
      }
    };
  };

  const handleSubmit = (files, allFiles) => {
    setFiles(allFiles.map((res) => {
      const response = JSON.parse(res.xhr.responseText);
      return { path: response.path };
    }));
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      LayoutComponent={Layout}
      multiple
      onSubmit={handleSubmit}

      submitButtonDisabled={disableSubmit}
      submitButtonContent={disableSubmit ? "MARQUE A CAIXINHA DE CONFIRMAÇÃO" : "ENVIAR ARQUIVOS"}
      classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
      inputContent="Clique ou arraste os arquivos da tabela de frete."
    />
  );
};


const Onboarding = (props) => {
  const [status, setStatus] = useState('process');
  const [videoUrl, setUrlVideo] = useState(null);
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();
  const apiService = new ApiService();
  const [isOpen, setIsOpen] = useState(false);
  const [freightTable, setFreightTable] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);
  const reload = () => {
    apiService
      .onboarding()
      .then((res) => {
        setStatus(res.is_complete === true ? 'finish' : 'process');
        setStep(res.actual_step - 1);
        setSteps(res.steps);
      });
  };

  const downloadFile = (path) => {
    const apiService = new ApiService();
    apiService.download(path);
  }

  const updateStep = (step, complete, data) => {
    apiService
      .updateStep(step, complete, data)
      .then((res) => {
        reload();
      });
  }

  const showButtonStepFive = (video) => {
    const st = steps?.filter((step) => step.step == 5) || [];
    if (st.length > 0) {
      const stepFive = st[0];
      for (let i = 0; i <= stepFive?.data?.length; i++) {
        if (stepFive?.data[i]?.file == video) {
          return true;
        }
      }
    }

    return false;
  }

  useEffect(() => {
    reload();
  }, []);

  const templates = {
    1: "Preencha o CNPJ e Razão social do embarcador e pagador dos fretes",
    2: <>Cadastre novos utilizadores à sua conta. Caso seja somente você, pode ignorar essa etapa! <br /><br /> <Button iconBefore={<User style={{ width: 11 }} />}
      appearance="primary" spacing="compact" component={Link} to={"/settings/users/new"}>
      ADD NOVO USUÁRIO
    </Button>
      <Button style={{ marginLeft: 20 }} iconBefore={<Close style={{ width: 11 }} />}
        appearance="subtle" spacing="compact" onClick={() => updateStep(2, true, null)}>
        SOMENTE EU IREI UTILIZAR
      </Button>
    </>,
    3: <>Cadastre o endereço de coleta dos produtos. Você também poderá definir uma janela de horários. <br /><br /> <Button iconBefore={<Home style={{ width: 11 }} />}
      appearance="primary" spacing="compact" component={Link} to={"/settings/distribution-centers/new"}>
      CADASTRAR NOVO CD
    </Button>
    </>,

    4: <>Envie as tabelas de frete das transportadoras que você já utiliza hoje. Você também pode adicionar a transportadora via Integração, clique abaixo e veja a lista de transportadoras integradas.
      <br /><br />
      <Button iconBefore={<TableChart style={{ width: 11 }} />}
        appearance="primary" spacing="compact" onClick={() => setIsOpen(true)}>
        ADD TABELA DE FRETE
      </Button>

      <Button style={{ marginLeft: 20 }} iconBefore={<Zap style={{ width: 11 }} />}
        appearance="primary" spacing="compact" component={Link} to={"/settings/integrations/carriers"}>
        ADD VIA INTEGRAÇÃO
      </Button>

      <Tooltip title="Ao clicar aqui, nossa equipe de prospecção de transportadoras será notificada e irá te enviar novas opções de transportadoras. Essa etapa pode demorar até 15 dias dependendo da origem.">
        <Button style={{ marginLeft: 20 }} iconBefore={<ChevronRight style={{ width: 11 }} />}
          appearance="warning" spacing="compact" onClick={() => updateStep(4, true, { solicitar: true })}>
          SOLICITAR NOVAS TRANSPORTADORAS
        </Button>
      </Tooltip>
    </>,

    5: <>
      <SectionMessage appearance="success" title="Parabéns! Você concluiu as primeiras etapas com sucesso.">
        <p>Agora, para que você saiba utilizar por completo a Flixlog, selecionamos alguns videos para te ajudar nessa jornada. Com eles você aprenderá a utilizar os principais recursos, além de ter uma visão geral sobre nossa plataforma.</p>

        <br /><br />
        <Steps direction="vertical" current={6} status={'finish'} icons={{ finish: <Play style={{ width: 17 }} />, error: <Close style={{ width: 17 }} /> }}>
          <Step
            title={'Dashboard'}
            description={<>Aqui você aprenderá os principais recursos da área de Dashboard. <br /><br />
              {showButtonStepFive('dashboard')
                ? <a className={classes.buttonClicked} href={'https://drive.google.com/file/d/1rD-lcBrm2GCCVr64pMKPp-tLvYVz59Sd/preview'} target="_blank" >
                  REVER AULA <ExternalLink className={classes.buttonIcon} />
                </a>
                : <a className={classes.button} onClick={() => updateStep(5, false, { video: true, file: 'dashboard' })} href={'https://drive.google.com/file/d/1rD-lcBrm2GCCVr64pMKPp-tLvYVz59Sd/preview'} target="_blank" >
                  ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
                </a>}</>}
          />

          <Step
            title={'Pedidos'}
            description={<>Aqui você irá tirar todas as dúvidas sobre como trazer seus pedidos para a Flixlog. <br /><br />
              {showButtonStepFive('pedidos')
                ? <a className={classes.buttonClicked} href={'https://drive.google.com/file/d/1I_W7dNaiUmpt97pdEdLuqmj8Ueg9awJu/preview'} target="_blank" >
                  REVER AULA <ExternalLink className={classes.buttonIcon} />
                </a> : <a className={classes.button} onClick={() => updateStep(5, false, { video: true, file: 'pedidos' })} href={'https://drive.google.com/file/d/1I_W7dNaiUmpt97pdEdLuqmj8Ueg9awJu/preview'} target="_blank" >
                  ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
                </a>}
            </>}
          />

          <Step
            title={'Cadastro de Pedidos'}
            description={<>Caso precise cadastrar um pedido manualmente, aqui é a aula ideal. <br /><br />
              {showButtonStepFive('cadastro-pedidos') ? <a className={classes.buttonClicked} href={'https://drive.google.com/file/d/1bUFcu2CL5DYUF9AK3dahYGynhwS-FPlV/preview'} target="_blank" >
                REVER AULA <ExternalLink className={classes.buttonIcon} />
              </a> : <a className={classes.button} onClick={() => updateStep(5, false, { video: true, file: 'cadastro-pedidos' })} href={'https://drive.google.com/file/d/1bUFcu2CL5DYUF9AK3dahYGynhwS-FPlV/preview'} target="_blank" >
                ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
              </a>}
            </>}
          />

          <Step
            title={'Solicitação de coletas'}
            description={<>O mundo perfeito existe. Aprenda aqui a solicitar coletas de forma automatizada e nunca mais tenha que ligar para transportadoras :D. <br /><br />
              {showButtonStepFive('coletas') ? <a className={classes.buttonClicked} href={'https://drive.google.com/file/d/1TbTO8dphIP53T_s_RR7W6gjiz-4pMsWk/preview'} target="_blank" >
                REVER AULA <ExternalLink className={classes.buttonIcon} />
              </a> : <a className={classes.button} onClick={() => updateStep(5, false, { video: true, file: 'coletas' })} href={'https://drive.google.com/file/d/1TbTO8dphIP53T_s_RR7W6gjiz-4pMsWk/preview'} target="_blank" >
                ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
              </a>}
            </>}
          />

          <Step
            title={'Cotações de Frete'}
            description={<>Se solicitar coletas para transportadoras é um trampo, imagina ter que cotar manualmente certo? Aqui automatizamos tudo, aprenda a realizar cotações aqui. <br /><br />
              {showButtonStepFive('cotacao') ? <a className={classes.buttonClicked} href={'https://drive.google.com/file/d/1jH1x4suff4w7_7azUXM-a2ybdcrltIES/preview'} target="_blank" >
                REVER AULA <ExternalLink className={classes.buttonIcon} />
              </a> : <a className={classes.button} onClick={() => updateStep(5, false, { video: true, file: 'cotacao' })} href={'https://drive.google.com/file/d/1jH1x4suff4w7_7azUXM-a2ybdcrltIES/preview'} target="_blank" >
                ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
              </a>}
            </>}
          />

          <Step
            title={'Configurações: Regra de Frete'}
            description={<>Quer dar um frete grátis ou aumentar um valor de frete? Aqui é a aula ideal! <br /><br />
              {showButtonStepFive('regra-frete') ? <a className={classes.buttonClicked} href={'https://drive.google.com/file/d/1xXo70MWaedt99PFC7Jh4Zp_DCQAx65pF/preview'} target="_blank" >
                ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
              </a> : <a className={classes.button} onClick={() => updateStep(5, false, { video: true, file: 'regra-frete' })} href={'https://drive.google.com/file/d/1xXo70MWaedt99PFC7Jh4Zp_DCQAx65pF/preview'} target="_blank" >
                ASSISTIR AULA <ExternalLink className={classes.buttonIcon} />
              </a>}
            </>}
          />
        </Steps>

      </SectionMessage>
    </>,

    6: <>Se você vende em Marketplaces como MadeiraMadeira ou ViaVarejo é essencial que seus produtos estejam cadastrados aqui na Flixlog, pois esses Marketplaces não enviam as medidas dos prodtuso na hora da cotação. Além disso, na Flixlog você consegue
      cadastrar mais de um volume para o mesmo SKU, tornando assim o cálculo de frete mais exato.
      <br /><br />  <Button iconBefore={<Gift style={{ width: 11 }} />}
        appearance="primary" spacing="compact" component={Link} to={"/settings/products"}>
        IMPORTAR PRODUTOS
      </Button>
      <Button style={{ marginLeft: 20 }} iconBefore={<Close style={{ width: 11 }} />}
        appearance="subtle" spacing="compact" onClick={() => updateStep(6, true, null)}>
        NÃO PRECISO CADASTRAR
      </Button>
    </>,
    7: <>Integre o seu ERP ou E-commerce e automatize seus processos =). Sabemos que essa é uma etapa complicada, você pode enviar um e-mail para nossa equipe técnica te auxiliar: <strong>andre@flixlog.com</strong>
      <br /><br />  <Button iconBefore={<Zap style={{ width: 11 }} />}
        appearance="primary" spacing="compact" component={Link} to={"/settings/integrations/systems"}>
        ADD INTEGRAÇÃO
      </Button>
      <Button style={{ marginLeft: 20 }} iconBefore={<Close style={{ width: 11 }} />}
        appearance="subtle" spacing="compact" onClick={() => updateStep(7, true, null)}>
        NÃO PRECISO DE INTEGRAÇÕES
      </Button>
    </>,
    8: <>Cadastre ou importe o seu primeiro pedido
      <br /><br />  <Button iconBefore={<Package style={{ width: 11 }} />}
        appearance="primary" spacing="compact" component={Link} to={"/settings/integrations/systems"}>
        CADASTRAR PEDIDO
      </Button>
      <Button style={{ marginLeft: 20 }} iconBefore={<Close style={{ width: 11 }} />}
        appearance="subtle" spacing="compact" onClick={() => updateStep(8, true, null)}>
        PULAR ESSA ETAPA
      </Button>
    </>
  };

  return (
    <Page
      className={classes.root}
      title="Onboarding - Flixlog"
    >
      <Header
        title="Bem vindo(a) à Bordo!"
        description="Aqui você encontra as principais etapas a serem concluídas para tirar o maior proveito da Flixlog!"
      />

      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal} width={'x-large'}>

            <ModalBody>
              <br />
              <br />
              <Grid cotainer>
                <Grid item lg={12}>

                  <Form>
                    {({ formProps }) => (
                      <form
                        {...formProps}
                        method="POST"
                        target="submitFrame"
                        name="create-repo"
                      >
                        <FormHeader
                          title="Enviar tabela de frete"
                          description="Preencha com os dados da transportadora que deseja adicionar"
                        />

                        <FormSection>

                          <Field isRequired name="carrier" label="Nome da Transportadora" defaultValue={freightTable.carrier}  >
                            {({ fieldProps }) => <Textfield {...fieldProps} onBlur={(ev) => setFreightTable(st => ({ ...st, carrier: ev?.target?.value }))} />}
                          </Field>

                        </FormSection>

                        <FormSection>

                          <Field isRequired name="federal_tax_id" label="CNPJ da Filial da transportadora" defaultValue={freightTable.federal_tax_id}>
                            {({ fieldProps }) => <Textfield {...fieldProps} onBlur={(ev) => setFreightTable(st => ({ ...st, federal_tax_id: ev?.target?.value }))} />}
                          </Field>

                        </FormSection>


                        <FormSection>

                          <Field name="emails" label="E-mails de solicitação de coleta (Separado por vírgula)" defaultValue={freightTable.emails}>
                            {({ fieldProps }) => <Textfield {...fieldProps} onBlur={(ev) => setFreightTable(st => ({ ...st, emails: ev?.target?.value }))} />}
                          </Field>

                        </FormSection>

                        {freightTable.carrier && freightTable.federal_tax_id ? <FormSection>
                          <strong>Anexos</strong>
                          <br />
                          <Alert severity="warning">
                            <AlertTitle>Observações para enviar os arquivos</AlertTitle>
                            <p>Você deve enviar sempre que possível arquivos em formato excel. <strong>Envie todos os arquivos descritos abaixo!</strong></p>
                            <p><strong>1</strong> - Envie o arquivo de tabela de frete;</p>
                            <p><strong>2</strong> - Envie o arquivo que contém as cidades atendendidas e seus respectivos prazos;</p>
                            <p><strong>3</strong> - Envie o arquivo que contém taxas como TDA, TRT e EMEX. A transportadora poderá te informar se existe;</p>
                            <br />
                            <p>Após selecionar todos os arquivos, clique na caixinha de confirmação e em sequida em <strong>ENVIAR ARQUIVOS</strong>.</p>
                          </Alert>
                          <br />


                          <CustomLayout
                            disableSubmit={disableSubmit}
                            setFiles={(files) => {
                              setFreightTable(st => ({ ...st, files: files }));
                              updateStep(4, true, {
                                tabela_frete: 1,
                                carrier: freightTable.carrier,
                                federal_tax_id: freightTable.federal_tax_id,
                                emails: freightTable.emails,
                                files
                              });
                              setFreightTable({});
                              closeModal();
                            }
                            } />

                          <FormSection>
                            <CheckboxField name="disableSubmit" >
                              {({ fieldProps }) => (
                                <Checkbox {...fieldProps} isChecked={!disableSubmit} onChange={() => setDisableSubmit((current) => !current)} autoFocus label="Confirmo que adicionei todos os arquivos! Estou ciente de que a falta de algum dos arquivos poderá ocasionar divergências de frete." />
                              )}
                            </CheckboxField>
                          </FormSection>
                        </FormSection> : null}
                      </form>
                    )}
                  </Form>

                </Grid>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={(() => closeModal())}>CANCELAR</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>


      <Container maxWidth={false}>
        <Grid container>
          <Grid item lg={8}>
            <Steps direction="vertical" current={step} status={status} icons={{ finish: <Check style={{ width: 17 }} />, error: <Close style={{ width: 17 }} /> }}>
              {steps.map((stepx) =>
                ((stepx.step == 4 && step > 3) || (stepx.step == 5 && step > 4)) ?
                  <Step
                    title={stepx.title}
                    description={<>
                      {templates[stepx.step]}
                      {stepx.step == 4 && stepx.data && stepx.data.map((item) =>
                        <Alert severity="info" style={{ marginTop: 10 }}>
                          <strong>{moment(item.date).format('DD/MM HH:mm')} - {item.title}: </strong> {item.description} {item.files ? <> - {item.files.map((file, index) => <a style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: 14, textDecoration: 'underline', marginRight: 5 }} onClick={() => downloadFile(file.path)}>Arquivo {index + 1}</a>)}</> : null}</Alert>
                      )}
                    </>}
                  />
                  : <Step
                    title={stepx.title}
                    description={(stepx.complete || step + 1 !== stepx.step) ? null : templates[stepx.step]}
                  />)}
            </Steps>

            {status == 'finish' ? <SectionMessage appearance="success" title="Parabéns! Você concluiu as principais etapas com sucesso.">
              <p>Agora, você já pode utilizar a Flixlog, esperamos que você tenha sucesso em sua jornada com a gente. Se precisar de qualquer auxílio, pode nos contactar pelo chat ao lado.</p>


            </SectionMessage> : null}

          </Grid>
        </Grid>

      </Container>
    </Page>
  );
};


export default Onboarding;
