import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { getOptions } from 'highcharts';
import highchartsMap from "highcharts/modules/map";
import ApiService from 'src/common/apiService';
highchartsMap(Highcharts); // Initialize the map module


const BlockMapVectorPrazo = ({ values, since }) => {
  const [options, setOptions] = useState(null);

  const loadingTopo = async (since) => {
    const topo = await fetch(
      'https://code.highcharts.com/mapdata/countries/br/br-all.topo.json'
    ).then(response => response.json());
    const apiService = new ApiService;

    apiService
      .getSlaState({
        since
      })
      .then((res) => {
        console.log(res.states)
        getOptions(topo, res.states ? res.states : []);
      });

  };
  const getOptions = async (map, tables) => {

    let data = [
      ['br-sp'], ['br-ma'], ['br-pa'], ['br-sc'],
      ['br-ba'], ['br-ap'], ['br-ms'], ['br-mg'],
      ['br-go'], ['br-rs'], ['br-to'], ['br-pi'],
      ['br-al'], ['br-pb'], ['br-ce'], ['br-se'],
      ['br-rr'], ['br-pe'], ['br-pr'], ['br-es'],
      ['br-rj'], ['br-rn'], ['br-am'], ['br-mt'],
      ['br-df'], ['br-ac'], ['br-ro']
    ];

    for (let j = 0; j < tables.length; j++) {
      for (let h = 0; h < data.length; h++) {
        if (data[h][0] === `br-${tables[j].state.toLowerCase()}`) {
          data[h] = tables[j];
        } else {
          if (typeof data[h].total == undefined) {
            data[h] = {deadline_avg: 0, total:0, state: tables[j].state.toLowerCase(), percentage: 0}
          }
        }
      }
    }

    console.log(data);
return;
   

    setOptions({

      chart: {
        map: map,
        borderWidth: 1
      },

      title: {
        text: 'Prazos - Média por Estado'
      },

      exporting: {
        sourceWidth: 600,
        sourceHeight: 500
      },

      legend: {
        layout: 'horizontal',
        borderWidth: 0,
        backgroundColor: 'rgba(255,255,255,0.85)',
        floating: true,
        verticalAlign: 'top',
        y: 25
      },

      mapNavigation: {
        enabled: true
      },

      colorAxis: {
        min: 1,
        type: 'logarithmic',
        minColor: '#ffcc00',
        maxColor: 'red',
        stops: []
      },

      series: [{
        animation: {
          duration: 1000
        },
        data,
        joinBy: null,
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          format: '{point.code}'
        },
        name: 'Prazo Médio',
        tooltip: {
          pointFormat: '{point.code}: {point.deadline_avg}'
        }
      }]
    });
  }

  useEffect(() => {
    loadingTopo(since);

  }, [since]);

  return (
    options ? <HighchartsReact
      options={options}
      highcharts={Highcharts}
      constructorType={'mapChart'}
      allowChartUpdate={true}
      updateArgs={[true, true, true]}
      containerProps={{ className: 'chartContainer' }}
    /> : null
  )
}

export default BlockMapVectorPrazo;
