import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';


import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const OrdersListView = (props) => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(props.limit ? props.limit : 10);
  const [page, changePage] = useState(1);
  

  const [selectedRows, setSelectedRowsIds] = useState([]);

  const handleSelectAll = (event) => {
    let rowIds;

    if (event.target.checked) {
      rowIds = orders.map((order) => order.reference);
    } else {
      rowIds = [];
    }

    setSelectedRowsIds(rowIds);
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);
  
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
  };

  const reloadOrders = (filters) => {
    const apiService = new ApiService();
  
    apiService
      .getOrders(filters, limit, page, props.isPickupPage)
      .then((res) => {
        setOrders(res.data);
        setTotalRows(res.total);

        if (res.filters_not_match && res.filters_not_match.search.length) {
          alert('Linhas não encontradas. ' + res.filters_not_match.search.join(', '));
        }
      });
  };


  useEffect(() => {
    let filters = {};

    if (props.orderSearch.value && !props.orderSearch.many) {
      filters['reference'] = props.orderSearch.value;
    } else {
      filters['search'] = props.orderSearch.value;
    }
    
    if (props.orderSearch.carrier) {
      filters['carrier'] = props.orderSearch.carrier;
    }

    if (props.orderSearchStatus) {
      filters['status'] = props.orderSearchStatus;
    } else if (props.defaultStatus) {
      filters['status'] = props.defaultStatus;
    }
    const params = queryString.parse(window.location.search);
    
    if (params.pickup) {
      filters['pickup_id'] = params.pickup;
    }

    if (props.miniVersion) {
      filters['no_pickup'] = true;
    }

    reloadOrders(filters);

  }, [props.orderSearch, props.orderSearchStatus, props.orderSearchCarrier, limit, page]);

  return (
    <Page
      className={classes.root}
      title="Pedidos"
    >
      <Container maxWidth={false}>
        <Toolbar miniVersion={props.miniVersion} selectedRows={selectedRows} reloadOrders={(filters) => reloadOrders(filters)} />
        <Box mt={3}>
          <Results
            isPickupPage={props.isPickupPage}
            miniVersion={props.miniVersion} 
            totalRows={totalRows}
            changePage={changePage}
            changeLimit={changeLimit}
            orders={orders}
            selectedRows={selectedRows}
            handleSelectAll={handleSelectAll}
            handleSelectOne={handleSelectOne}
            setSelectedRowsIds={setSelectedRowsIds}
            limits={props.limits}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (store) => ({
  orderSearch: store.orderSearch,
  orderSearchStatus: store.orderSearch.status,
  orderSearchCarrier: store.orderSearch.carrier,
});

export default connect(mapStateToProps)(OrdersListView);
