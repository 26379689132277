import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewFreightRuleView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Novo - Regra de Frete"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton={true} />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Form
                initialValues={{
                  name: '',
                  start_at: moment().format('YYYY-MM-DDTHH:mm'),
                  ended_at: ''
                }}
                submitText="CADASTRAR"
                onSubmit={(values, rules) => {
                  const apiService = new ApiService();
                  return apiService
                    .newFreightRule({ ...values, routes: rules })
                    .then((res) => {
                      enqueueSnackbar('Regra cadastrada com sucesso', {
                        variant: 'success',
                      });
                      navigate('/settings/freight-rules');
                    })
                    .catch((res) => {
                      enqueueSnackbar('Ocorreu um erro:' + res.message, {
                        variant: 'error',
                      });
                    });

                  return true;
                }}
              />
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewFreightRuleView);
