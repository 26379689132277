import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OrderRow from './OrderRow';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Checkbox,
  Drawer,
  Grid,
  CardContent,
  TextField,
  CardHeader,
  Button,
  Divider
} from '@material-ui/core';
import { isSuperUser } from 'src/common/auth';
import ApiService from 'src/common/apiService';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Results = ({ className, pickups, noPagination, totalRows, changeLimit, enqueueSnackbar, changePage, selectedRows, handleSelectAll, handleSelectOne, setSelectedRowsIds, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [actualPickup, setActualPickup] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState('15:00');
  const [driver, setDriver] = useState(null);
  const [estimatedDate, setDate] = useState(null);
  const [estimatedTimeStart, setTimeStat] = useState('10:00');
  const [estimatedTimeEnd, setTimeEnd] = useState('15:00');
  const [integrationCode, setIntegrationCode] = useState(null);

  const confirmPickup = () => {
    const apiService = new ApiService();

    apiService
      .confirmPickup(actualPickup.id)
      .then((res) => {
        enqueueSnackbar(actualPickup.id + ' - Coleta confirmada com sucesso!', {
          variant: 'success',
        });
      }).catch((error) => {
        enqueueSnackbar(actualPickup.id + ' - ' + error.message, {
          variant: 'error',
        });
      });
  }

  const updateIntegrationCode = () => {
    const apiService = new ApiService();

    if (integrationCode) {
      apiService
        .updatePickup(actualPickup.id, { integration_code: integrationCode })
        .then((res) => {
          window.location.reload();
          enqueueSnackbar(actualPickup.id + ' - Código de coleta alterado com sucesso', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar(actualPickup.id + ' - Erro ao alterar código de coleta', {
            variant: 'error',
          });
        });
    }
  }
  const updatePickupInfo = () => {
    const apiService = new ApiService();

    if (driver) {
      apiService
        .changeDriver(actualPickup.id, driver, null)
        .then((res) => {
          enqueueSnackbar(actualPickup.id + ' - Motorista alterado com sucesso', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar(actualPickup.id + ' - Erro ao alterar motorista', {
            variant: 'error',
          });
        });
    }



    if (estimatedDate) {
      apiService
        .changePickupDate(actualPickup.id, {
          estimated_date: estimatedDate,
          estimated_time_start: estimatedTimeStart,
          estimated_time_end: estimatedTimeEnd,
        })
        .then((res) => {
          enqueueSnackbar(actualPickup.id + ' - Data de coleta alterada com sucesso', {
            variant: 'success',
          });
        }).catch(() => {
          enqueueSnackbar(actualPickup.id + ' - Erro ao alterar data de coleta', {
            variant: 'error',
          });
        });
    }
  };

  const performPickup = () => {
    const apiService = new ApiService();

    apiService
      .performPickup(actualPickup.id)
      .then((res) => {
        window.location.reload();
        enqueueSnackbar(actualPickup.id + ' - Coleta realizada com sucesso!', {
          variant: 'success',
        });
      }).catch((error) => {
        enqueueSnackbar(actualPickup.id + ' - ' + error.message, {
          variant: 'error',
        });
      });
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  const openEditPickup = (pickup) => {
    setActualPickup(pickup);

    if (pickup.driver) {
      setDriver(pickup.driver);
    }

    if (pickup.pickup_date) {
      setDate(pickup.pickup_date.substring(0, 10));
    }

    setOpenDrawer(true);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <>
        {isSuperUser()
          ? (<Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <PerfectScrollbar>
              <Grid container>
                {isSuperUser()
                  ? (
                    <Grid xs={6} item>
                      <Card style={{ minWidth: 300 }}>
                        <CardHeader title="Dados da Coleta" />
                        <CardContent>
                          <Grid container>
                            <Grid xs={12} item>
                              <TextField
                                onChange={(e) => setDriver(e.target.value)}
                                label="Nome do motorista"
                                variant="outlined"
                                style={{ marginBottom: '30px' }}
                                value={driver}
                                InputLabelProps={{
                                  shrink: true,
                                }} />
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid xs={12} item>
                              <TextField
                                type="date"
                                onChange={(e) => setDate(e.target.value)}
                                label="Data da coleta"
                                variant="outlined"
                                value={estimatedDate}
                                style={{ marginBottom: '30px' }}
                                InputLabelProps={{
                                  shrink: true,
                                }} />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid xs={6} item>
                              <TextField
                                type="time"
                                value={estimatedTimeStart}
                                onChange={(e) => setTimeStat(e.target.value)}
                                label="Hora inicial"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }} />
                            </Grid>
                            <Grid xs={6} item>
                              <TextField
                                type="time"
                                value={estimatedTimeEnd}
                                onChange={(e) => setTimeEnd(e.target.value)}
                                label="Hora final"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }} />
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginTop: 30 }}>
                            <Grid xs={12} item>
                              <Button color="success" variant="contained" onClick={updatePickupInfo}>Salvar</Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : null}
              </Grid>

              <Card style={{ minWidth: 300 }}>
                <CardHeader title="Alterar Status" />
                <CardContent>
                  <Grid container>
                    <Grid xs={12} item>

                      <>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginTop: 20 }}
                          onClick={() => confirmPickup()}
                        >
                          CONFIRMAR COLETA
                          </Button>
                        <br />
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ marginTop: 20 }}
                          onClick={() => performPickup()}
                        >
                          COLETA REALIZADA
                          </Button>
                      </>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card style={{ minWidth: 300 }}>
                <CardHeader title="Alterar Coleta" />
                <CardContent>
                  <Grid container>
                    <Grid xs={12} item>
                      <TextField
                        value={integrationCode}
                        onChange={(e) => setIntegrationCode(e.target.value)}
                        label="Código da coleta"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }} />
                    </Grid>
                    <Grid xs={12} item>


                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginTop: 20 }}
                        onClick={() => updateIntegrationCode()}
                      >
                        SALVAR
                          </Button>

                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </PerfectScrollbar>
          </Drawer>) : null}
      </>

      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Transportadora
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                  Informações
                </TableCell>
                <TableCell>
                  Milestones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pickups.slice(0, limit).map((pickup) => <OrderRow openEditPickup={openEditPickup} pickup={pickup} handleSelectOne={handleSelectOne} selectedRows={selectedRows} />)}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {noPagination ? null : <TablePagination
        component="div"
        count={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 20, 30]}
      />}
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

export default (withSnackbar(Results));
