import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewUserView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cds, setCds] = useState([]);

  return (
    <Page
      className={classes.root}
      title="Novo Usuário"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Form
                initialValues={{
                  name: '',
                  email: '',
                  password: '',
                  permissions: [
                    'dashboard',
                    'orders',
                    'pickups',
                    'quotation',
                    'settings'
                  ],
                  is_admin: false
                }}
                title="Novo"
                submitText="CADASTRAR"
                onSubmit={(values, permissions) => {
                  const apiService = new ApiService();
                  return apiService
                    .createNewUser({
                      name: values.name,
                      email: values.email,
                      password: values.password,
                      is_admin: values.is_admin,
                      permissions: permissions || {}
                    })
                    .then(() => {
                      enqueueSnackbar('Usuário criado com sucesso', {
                        variant: 'success',
                      });
                      navigate('/settings/users');
                    })
                    .catch((res) => {
                      enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                        variant: 'error',
                      });
                    });
                }}
              />
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewUserView);
