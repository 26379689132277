import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewOrderView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Page
      className={classes.root}
      title="Solicitar nova coleta"
    >
      <Container maxWidth={false}>
        <Box>
          <Form
            initialValues={{
              note: '',
              orders: [],
            }}
            submitText="SOLICITAR COLETAS"
            onSubmit={(values, orders) => {
              const apiService = new ApiService();

              return apiService
                .newPickup({
                  note: values.note,
                  orders
                })
                .then((res) => {
                  enqueueSnackbar('Coleta solicitada com sucesso, aguarde nosso retorno.', {
                    variant: 'success',
                  });
                  navigate('/pickups');
                })
                .catch((res) => {
                  enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                    variant: 'error',
                  });
                });
            }}
          />

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewOrderView);
