import jwt_decode from "jwt-decode";

export const TOKEN_KEY = '@flixlog-token';
export const COMPANY_KEY = '@flixlog-company';
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getActualCompany = () => JSON.parse(localStorage.getItem(COMPANY_KEY));
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
export const setActualCompany = (company) => {
  localStorage.setItem(COMPANY_KEY, JSON.stringify(company));
};
export const unsetCompany = () => {
  localStorage.removeItem(TOKEN_KEY);
};
export const isSuperUser = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  const decoded = jwt_decode(token);

  return decoded.is_super_admin === true;
};

export const isAdmin = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  const decoded = jwt_decode(token);

  return decoded.is_admin === true || decoded.is_super_admin === true;
};

export const hasAuditEnabled = () => {
	if (!window.localStorage.getItem('@flixlog/me')) {
		return false;
	}
  	var me = JSON.parse(window.localStorage.getItem('@flixlog/me'))
	return me.has_audit_enabled || false;
};

export const inPresentation = () => {
  const token = getToken();
  if (!token) {
    return false;
  }
  const decoded = jwt_decode(token);

  return decoded.name === 'André Kiffer';
};

export const hasPermission = (permission) => {
  const token = getToken();
  if (!token) {
    return false;
  }
  const decoded = jwt_decode(token);

  if (decoded.is_admin === true || decoded.is_super_admin === true) {
    return true;
  }

  return decoded.permissions === null || (decoded.permissions && decoded.permissions.indexOf(permission) !== -1);
};
