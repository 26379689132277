import React from 'react';
import ReactDOM from 'react-dom';
import { HotTable } from '@handsontable/react';

import 'handsontable/dist/handsontable.full.css';

class Sheet extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<HotTable 
                data={this.props.grid} 
                colHeaders={true} 
                rowHeaders={true} 
                width="100%" 
                height="768" 
              />);
  }
}

export default Sheet;
