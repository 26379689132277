import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { Edit, Plus } from 'react-feather';
import ApiService from 'src/common/apiService';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  successButton: {
    backgroundColor: '#8BC34A'
  }
}));



const AddOccurrence = props => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [description, setDescription] = useState(props.description);
    const [description2, setDescription2] = useState(props.description);

  const [code, setCode] = useState(props.type);
  const [occurred_at, setOccurredAt] = useState(moment().format('YYYY-MM-DD'));
  const apiService = new ApiService();

  const send = () => { 
    const payload = {
      order_id: props.order_id,
      message: description2 + (description ? ' - ' + description : ''),
      source: 'manual',
      code: code,
      occurred_at: occurred_at
    };

    apiService
      .registerTracking(payload)
      .then((res) => {
        props.props.enqueueSnackbar('Ocorrência registrada com sucesso', { 
          variant: 'success',
        });
        props.refreshOrder();
      });
  };

  return (
    <div> 
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"Registrar ocorrência de falha"}</DialogTitle>
        <DialogContent>
          <DialogContentText>

            <FormControl component="fieldset" margin="normal">
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel margin="normal" value={178} checked={code === 178} onChange={() => {
                      setCode(178);
                      setDescription2('Avaria - Total');
                    }} control={<Radio color="primary" />} label="Avaria - Total" />
                    <FormControlLabel margin="normal" value={179} checked={code === 179} onChange={() => {
                      setCode(179);
                      setDescription2('Avaria - Parcial');
                    }} control={<Radio color="primary" />} label="Avaria - Parcial" />
                    <FormControlLabel margin="normal" value={123} checked={code === 123} onChange={() => {
                      setCode(123);
                      setDescription2('Extravio');
                    }} control={<Radio color="primary" />} label="Extravio" />
                    <FormControlLabel margin="normal" value={126} checked={code === 126} onChange={() => {
                      setCode(126);
                      setDescription2('Mercadoria Retida')
                    }} control={<Radio color="primary" />} label="Mercadoria Retida" />
                  </RadioGroup>
                </FormControl>

      

             <TextField id="driver-name" required value={description} InputLabelProps={{
                  shrink: true,
                }} onChange={(e) => setDescription(e.target.value)} label="Descrição" variant="outlined" />
             <TextField id="occurred-at" InputLabelProps={{
                  shrink: true,
                }}type="date" required value={occurred_at} onChange={(e) => setOccurredAt(e.target.value)} label="Data Ocorrência" variant="outlined" />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fechar
          </Button>
          <Button onClick={() => send()} color="primary" autoFocus  variant="contained">
            Registrar
          </Button>
        </DialogActions>
      </Dialog>
      <Button color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
          className={classes.successButton}><Plus /> {props.label ? props.label : "Editar"}</Button>
    </div>
  );
};

export default AddOccurrence;
