import React, { useState, useEffect, useCallback } from 'react';

import {
  makeStyles,
  Container,
  Grid
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Header from '../../../../layouts/DashboardLayout/Header';
import OrdersList from './List';
import { Trash } from 'react-feather';
import ShipmentsConfirmed from 'src/views/reports/DashboardView/ShipmentsConfirmed';
import BlockMoney from 'src/views/reports/DashboardView/BlockMoney';
import ConciliationNumbers from 'src/views/reports/DashboardView/ConciliationNumbers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const AuditView = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [flixlogAmoundFreight, setFlixlogAmountFreight] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [carriersAmoundFreight, setCarriersAmountFreight] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [diff, setDiff] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [conciliation, setConciliation] = useState({ total: 0, percentage: 0, last_total: 0 });

  const [since, setSince] = useState(30);
  const [filters, setFilters] = useState(null);

  const [downloading, setDownloading] = useState(false);

  const downloadFile = (data, fileName, type = "text/plain") => {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
  
  const newOrder = () => {
    setDownloading(true);
    const apiService = new ApiService();
	
	let query = '';
	
    if (filters && filters.carrier) {
      query += `filters[carrier]=${filters.carrier}&`;
    }
	
    if (filters && filters.created_at_start) {
      query += `filters[created_at_from]=${filters.created_at_start}&`;
    }
	
    if (filters && filters.created_at_end) {
      query += `filters[created_at_to]=${filters.created_at_end}&`;
    }
	
    if (filters && filters.difference) {
      query += `filters[difference]=${filters.difference}&`;
    }
	
    if (filters && filters.reference) {
      query += `filters[reference]=${filters.reference}&`;
    }
	
    if (filters && filters.created_at) {
      query += `filters[created_at]=${filters.created_at}&`;
    }
	
	
    apiService
      .generateReport('audit?' + query)
      .then((res) => {
        downloadFile(res, `audit-report-${(new Date()).getTime()}.csv`, "application/csv");
        setDownloading(false);
      }).catch(() => {
        setDownloading(false);
      });
  };
  
  useEffect(() => {
    apiService
      .getAuditDashboard({
        since
      })
      .then((res) => {
        setFlixlogAmountFreight(res.flixlog_amount_freight);
        setCarriersAmountFreight(res.carriers_amount_freight);
        setDiff(res.diff);
        setConciliation(res.conciliation);
      });
  }, [since]);

  return (
    <Page
      className={classes.root}
      title="Auditoria de Frete - Flixlog"
    >
      <Header
        title="Auditoria de Frete"
        description="Aqui você encontra a listagem de CTEs das transportadoras relacionado com a respectiva NF. Você pode também fazer o upload dos CTEs faltantes para fazer a conciliação do frete."
        // help="https://flixlog.readme.io/"
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          },
          {
            text: 'Auditoria',
            link: ''
          }
        ]}
        tabs={{
          tabSelected: 0,
          data: [
            {
              text: 'Conciliados',
              link: '/audit'
            },
            {
              text: 'Sem Conciliação',
              link: '/audit/without-order'
            }
          ]
        }}
        buttons={[
          {
            text: 'Importar CTEs',
            link: '/audit/import'
          },
          {
            text: 'Exportar para csv',
            onClick: () => newOrder(),
            isDisabled: downloading
          }
        ]}
      />

      <Container maxWidth={false}>

        <Grid
          container
          spacing={3}
          style={{marginBottom: 20}}
        >
    
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockMoney
              title="TOTAL FRETE FLIXLOG"
              values={flixlogAmoundFreight}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <BlockMoney
              title="TOTAL FRETE TRANSPORTADORA"
              values={carriersAmoundFreight}
              since={since}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <BlockMoney
              title="DIFERENÇA"
              values={diff}
              since={since}
              color={diff.total < 0 ? 'red' : 'green'}
              description={diff.total <= 0 ? 'Os fretes na Flixlog deram abaixo, ou exatamente o cobrado pela transportadora nos CTEs conciliados' : 'Os fretes na Flixlog deram mais alto do que o cobrado pela transportadora nos CTEs conciliados'}
            />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
             <ConciliationNumbers
              title="CTEs sem conciliação. 90 dias."
              values={conciliation}
              since={since}
            
            />
          </Grid>
        </Grid>
        <OrdersList
          height={'100%'}
		  handleFilters={setFilters}
        />
      </Container>
    </Page>
  );
};


export default AuditView;
