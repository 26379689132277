import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItem,
  ListItemIcon,
  Divider,
  List,
  ListItemText,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Input,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  TextareaAutosize
} from '@material-ui/core';
import { Filter, Search as SearchIcon } from 'react-feather';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeOrderSearch, changeOrderSearchStatus } from 'src/actions';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ApiService from 'src/common/apiService';
import { isSuperUser } from 'src/common/auth';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Sync } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));

const Toolbar = ({ changeOrderSearch, changeOrderSearchStatus, className, reloadOrders, selectedRows, enqueueSnackbar, ...rest }) => {
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [estimatedDate, setDate] = useState(null);
  const [estimatedTimeStart, setTimeStat] = useState('10:00');
  const [estimatedTimeEnd, setTimeEnd] = useState('15:00');
  const [deliveryDate, setDeliveryDate] = useState('15:00');
  const [driver, setDriver] = useState(null);
  const apiService = new ApiService();

  const changeStatus = (ev) => {
    setStatus(ev.target.value);
    changeOrderSearchStatus(ev.target.value);
  };

  const updatePickupInfo = () => {
    selectedRows.forEach((reference) => {
      if (driver) {
        apiService
          .changeDriver(reference, driver, null)
          .then((res) => {
            enqueueSnackbar(reference + ' - Motorista alterado com sucesso', {
              variant: 'success',
            });
          })
          .catch(() => {
            enqueueSnackbar(reference + ' - Erro ao alterar motorista', {
              variant: 'error',
            });
          });
      }

      if (estimatedDate) {
        apiService
          .changePickupDate(reference, {
            estimated_date: estimatedDate,
            estimated_time_start: estimatedTimeStart,
            estimated_time_end: estimatedTimeEnd,
          })
          .then((res) => {
            enqueueSnackbar(reference + ' - Data de coleta alterada com sucesso', {
              variant: 'success',
            });
          }).catch(() => {
            enqueueSnackbar(reference + ' - Erro ao alterar data de coleta', {
              variant: 'error',
            });
          });
      }

    });
    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const updateDeliveryInfo = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .changeDeliveryDate(reference, deliveryDate)
          .then((res) => {
            enqueueSnackbar(reference + ' - Data de entrega alterada com sucesso', {
              variant: 'success',
            });
          }).catch(() => {
            enqueueSnackbar(reference + ' - Erro ao alterar data de entrega', {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const confirmPickup = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .confirmPickup(reference)
          .then((res) => {
            enqueueSnackbar(reference + ' - Coleta confirmada com sucesso!', {
              variant: 'success',
            });
          }).catch((error) => {
            enqueueSnackbar(reference + ' - ' + error.message, {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const confirmShipment = () => {
    selectedRows.forEach((reference) => {
      apiService
        .confirmShipment(reference)
        .then((res) => {
          enqueueSnackbar(reference + ' - Remessa confirmada com sucesso!', {
            variant: 'success',
          });
        }).catch((error) => {
          enqueueSnackbar(reference + ' - ' + error.message, {
            variant: 'error',
          });
        });
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const cancelShipment = () => {
    selectedRows.forEach((reference) => {
      apiService
        .cancelOrder(reference)
        .then((res) => {
          enqueueSnackbar(reference + ' - Remessa cancelada com sucesso!', {
            variant: 'success',
          });
        }).catch((error) => {
          enqueueSnackbar(reference + ' - ' + error.message, {
            variant: 'error',
          });
        });
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const performPickup = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .performPickup(reference)
          .then((res) => {
            enqueueSnackbar(reference + ' - Coleta realizada com sucesso!', {
              variant: 'success',
            });
          }).catch((error) => {
            enqueueSnackbar(reference + ' - ' + error.message, {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const goOutDelivery = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .goOutDelivery(reference)
          .then((res) => {
            enqueueSnackbar(reference + ' - Pedido saiu para entrega!', {
              variant: 'success',
            });
          }).catch((error) => {
            enqueueSnackbar(reference + ' - ' + error.message, {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const deliver = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .deliver(reference)
          .then((res) => {
            enqueueSnackbar(reference + ' - Pedido entregue com sucesso!', {
              variant: 'success',
            });
          }).catch((error) => {
            enqueueSnackbar(reference + ' - ' + error.message, {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle>{"Cole abaixo as informações para busca, uma por linha"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextareaAutosize onChange={(e) => setSearchData(e.target.value)}></TextareaAutosize>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(e) => changeOrderSearch(searchData.split("\n").join('|'), true)} >
              Filtrar
            </Button>
            <Button onClick={() => setOpenModal(false)} color="primary" autoFocus>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >Coletas
        </Typography>
       
       

        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/pickups/new"
        >
          GERAR COLETAS
        </Button>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeOrderSearch, changeOrderSearchStatus }, dispatch);
};

export default connect(null, mapDispatchToProps)(withSnackbar(Toolbar));
