import React, { useState, useEffect, useCallback } from 'react';

import {
  makeStyles,
  Container
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Header from '../../../../layouts/DashboardLayout/Header';
import OrdersList from './List';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const OrdersView = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();

  return (
    <Page
      className={classes.root}
      title="Gestão de Pedidos - Flixlog"
    >
      <Header
        title="Gestão de Pedidos"
        description="Aqui você pode acompanhar o status de todos os pedidos."
        // help="https://flixlog.readme.io/"
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          },
          {
            text: 'Pedidos',
            link: ''
          }
        ]}
        buttons={[
          {
            text: 'Importar Notas',
            link: '/orders/import'
          },
          {
            text: 'Cadastrar Pedido',
            link: '/orders/new'
          }
        ]}
      />

      <Container maxWidth={false}>
        <OrdersList
          height={'100%'} 
        />
      </Container>
    </Page>
  );
};


export default OrdersView;
