import React, { Component, useEffect, useState } from 'react';
import { render } from 'react-dom';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { getOptions } from 'highcharts';
import highchartsMap from "highcharts/modules/map";
import ApiService from 'src/common/apiService';
highchartsMap(Highcharts); // Initialize the map module


const BlockMapVector = ({carrier}) => {
  const [options, setOptions] = useState(null);

  const loadingTopo = async () => {
    const topo = await fetch(
      'https://code.highcharts.com/mapdata/countries/br/br-all.topo.json'
    ).then(response => response.json());
    const apiService = new ApiService;
    apiService
      .getFreightTables({carrier: carrier}, 100, 1)
      .then((res) => {
        getOptions(topo, res.data.filter((table) => table.active && table?.main_destinations?.length > 0).map((table) => ({ name: table.carrier.canonical_name, destinations: table.main_destinations ? table.main_destinations : [] })));
      })
  };
  const getOptions = async (map, tables) => {

    let data = [
      ['br-sp'], ['br-ma'], ['br-pa'], ['br-sc'],
      ['br-ba'], ['br-ap'], ['br-ms'], ['br-mg'],
      ['br-go'], ['br-rs'], ['br-to'], ['br-pi'],
      ['br-al'], ['br-pb'], ['br-ce'], ['br-se'],
      ['br-rr'], ['br-pe'], ['br-pr'], ['br-es'],
      ['br-rj'], ['br-rn'], ['br-am'], ['br-mt'],
      ['br-df'], ['br-ac'], ['br-ro']
    ];



    let carrierNames = [...new Set(tables.map(({ name }) => name.toUpperCase()))];

    for (let g = 0; g < data.length; g++) {
      for (let i = 0; i < carrierNames.length; i++) {
        data[g].push(0);
      }
    }


    for (let i = 0; i < carrierNames.length; i++) {
      for (let j = 0; j < tables.length; j++) {
        if (tables[j].name.toUpperCase() == carrierNames[i]) {
          for (let h = 0; h < data.length; h++) {
            tables[j].destinations.forEach((destination) => {
              if (data[h].length && data[h][0] === `br-${destination.state}`) {
                console.log(`br-${destination.state}`, i);
                data[h][i + 1] = destination.total;
              }
            });
          }
        }
      }
    }



    const newSeries = {
      data: data,
      name: 'Estados',
      keys: ['id', ...carrierNames],
      joinBy: null,
      color: '#ffcc00',

      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return '<strong>' + this.name + '</strong> <br/>' +
            Highcharts.map(carrierNames
              .filter((carrier) => this[carrier])
              .map((carrier) => [carrier, this[carrier]]), function (line) {
                return '<span style="color:' + line[2] +
                  '">\u25CF</span> ' +
                  line[0] + ': <strong>' + Highcharts.numberFormat(line[1], 0) + '</strong> destinos<br/>';
              }).join('')
        }
      }
    };

    setOptions({
      series: [newSeries],

      chart: {
        map: map,
        animation: false,
        events: {
          load: function () {

            var series = this.series[0],
              states = series.data;

            states.forEach(function (el, inx) {
              let values = [];

              carrierNames.forEach((item) => {
                if (typeof el[item] !== undefined) {
                  values.push(el[item]);
                } else {
                  values.push(0)
                }
              })

              if (values.every(e => e == 0)) {
                el.update({
                  color: '#f0f0f0'
                });
              }  

            });

            return this;
          },
        },
      },
      title: {
        text: 'Área de cobertura das transportadoras'
      },
      subtitle: {
        text: 'Apenas tabelas ativas. Não contempla integrações via API.'
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom'
        }
      },
      credits: {
        enabled: false
      }
    });
  }

  useEffect(() => {
    loadingTopo();

  }, [carrier]);

  return (
    options ? <HighchartsReact
      options={options}
      highcharts={Highcharts}
      constructorType={'mapChart'}
      allowChartUpdate={true}
      updateArgs={[true, true, true]}
      containerProps={{ className: 'chartContainer' }}
    /> : null
  )
}

export default BlockMapVector;
