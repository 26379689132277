import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import Form from './Form';
import { getActualCompany } from 'src/common/auth';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeleteFreightRuleView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const match = useParams();
  const [rule, setRule] = useState(null);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getFreightRule(match.id)
      .then((res) => setRule(res));
  }, [match.id]);

  const handleDelete = () => {
    const apiService = new ApiService();
    apiService
      .removeFreightRule(rule.id)
      .then(() => {
        enqueueSnackbar('Regra de frete excluída com sucesso', { variant: 'success' });
        navigate('/settings/freight-rules');
      })
      .catch((res) => enqueueSnackbar("Ocorreu um erro: " + res.message, { variant: 'error' }));
  };

  return (
    <Page
      className={classes.root}
      title="Excluir - Regra de Frete"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton={true} />
        <Box mt={3}>
          <Card>
            <CardContent>
              {rule ? <>
                <Typography variant="h2">Tem certeza que deseja remover a regra de frete: {rule.name}?</Typography>
                <Button style={{ backgroundColor: '#dc1d1d', color:  '#fff', marginTop: 30}} onClick={handleDelete}>EXCLUIR DEFINITIVAMENTE</Button>
              </> : 'Aguarde...'}
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(DeleteFreightRuleView);
