import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApiService from 'src/common/apiService';
import SimpleReactFileUpload from 'src/views/orders/OrderPreview/FileUpload';
import { withSnackbar } from 'notistack';
import { changeOrderSearch, filterProduct } from 'src/actions';
import { Search } from 'react-feather';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Download
} from 'react-feather';
import { Refresh } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));

const Toolbar = ({ className, hideButton, changeOrderSearch, filterProduct, enqueueSnackbar, ...rest }) => {
  const classes = useStyles();
  const [sendingCsv, setSendCsv] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [disableDownload, setDisableDownload] = useState(false);
  const [reloading, setReloading] = useState(false);

  const refreshCache = () => {
    const apiService = new ApiService;
    setReloading(true);
    apiService
      .reloadQuotationCache('products')
      .then(() => {
        setReloading(false);
        alert('Atualizado com sucesso!');
      });

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadCsv = (response) => {
    setDisableDownload(true);
    (new ApiService())
      .downloadCsv()
      .then(() => setDisableDownload(false));
  }

  const sendInvoice = (response) => {
    setSendCsv(true);
    (new ApiService())
      .createProductsFromCSV({
        csv: {
          path: response.data.path
        }
      })
      .then(() => {
        enqueueSnackbar('Produtos importados com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        enqueueSnackbar('Ocorreu um erro ao tentar processar o CSV, tente novamente. ' + error.message, {
          variant: 'error',
        });
      });
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          Produtos
          {!hideButton ? <><TextField
            className={classes.search}
            onChange={(ev) => changeOrderSearch(ev.target.value)}
            style={{ paddingRight: 30 }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <Search />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Buscar por SKU"
            variant="outlined"
          />

            <FormControlLabel
              control={
                <Checkbox
                  onChange={(ev) => filterProduct(ev.target.checked ? { filter: 'distribution_center', value: 'IS NULL' } : null)}
                  name="dimensions"
                  color="primary"
                />
              }
              label="Produtos sem Origem"
            /></> : null}
        </Typography>

        {!hideButton ? <>

          <a
            onClick={() => downloadCsv()}
            disabled={disableDownload}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              color: '#2196f3',
              cursor: 'pointer',
              marginRight: 20
            }}
          ><Download style={{ marginRight: 5 }} /> Baixar csv</a>

<Tooltip enterDelay={300} title="Para que as alterações reflitam na cotação, você deve clicar aqui para sincronizar.">
            <Button
              color="default"
              disabled={reloading}
              style={{ marginRight: 30 }}
              onClick={refreshCache}
              variant="contained"
            >
              <Refresh /> Sincronizar
            </Button>
          </Tooltip>

          <Button
            variant="contained"
            onClick={handleClickOpen}
            style={{ marginRight: 20 }}
          >
            IMPORTAR CSV
          </Button>

          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={`/settings/products/new`}
          >
            ADICIONAR PRODUTO
          </Button>

          <Dialog
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Enviar CSV de produtos</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Envie o arquivo utilizando o botão abaixo e aguarde até aparecer a mensagem de sucesso. <br />
                Clique em "Escolher arquivo", procure o arquivo CSV e em seguida clique em Enviar.
                <br />
                <br />
                <SimpleReactFileUpload
                  submiting={sendingCsv}
                  title=""
                  onSuccess={(response) => sendInvoice(response)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </> : null}
      </Box>
    </div >
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeOrderSearch, filterProduct }, dispatch);
};

export default connect(null, mapDispatchToProps)(withSnackbar(Toolbar));
