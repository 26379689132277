import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import { Plus, Trash } from 'react-feather';
import ApiService from 'src/common/apiService';

const Form = ({ enqueueSnackbar, initialValues, onSubmit, submitText, title }) => {
  const [products, setProducts] = useState({});
  const [cds, setCds] = useState([]);
  const reloadCdList = (filters) => {
    const apiService = new ApiService();
    apiService.getDistributionCenters(filters).then((res) => {
      setCds(res.data);
    });
  };

  const [parcels, setParcels] = useState(initialValues.property || []);

  useEffect(() => {
    reloadCdList();
  }, []);

  const removeParcel = (ids) => {
    setParcels(parcels.filter((item, id) => (id !== ids)));
  };

  const handleParcel = (idx, event) => {
    let par = JSON.parse(JSON.stringify(parcels));
    par[idx][event.target.name] = event.target.value;
    setParcels(par);
  };

  const addParcel = () => {
    setParcels(parcels => [...parcels, {
      width: '',
      length: '',
      height: '',
      weight: '',
      quantity: 1
    }]);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(ev) => onSubmit(ev, parcels)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {title ?? 'Novo'}
            </Typography>
          </Box>
          <Grid container spacing={3}>

            <Grid item xs={6} lg={3}>
              <TextField
                error={Boolean(touched.reference && errors.reference)}
                helperText={touched.reference && errors.reference}
                label="SKU"
                margin="normal"
                name="reference"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.reference}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={7}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                label="Título do produto"
                margin="normal"
                name="description"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={2}>
              <TextField
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                label="Preço do produto"
                margin="normal"
                name="price"
                required
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                variant="outlined"
                fullWidth
              />
            </Grid>

            <Divider />

            <Grid item xs={6} lg={3}>
              <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">CD de saída</InputLabel>
                <Select
                  error={Boolean(touched.distribution_center_id && errors.distribution_center_id)}
                  helperText={touched.distribution_center_id && errors.distribution_center_id}
                  label="CD de saída"
                  margin="normal"
                  name="distribution_center_id"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.distribution_center_id}
                  variant="outlined"
                  fullWidth
                >
                  {cds.map((cd) => <MenuItem value={cd.id}>{cd.reference} - {cd.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Tooltip enterDelay={300} title="(Opcional) Esse prazo será adicionado ao prazo de entrega do cliente">
                <TextField
                  error={Boolean(touched.expedition_time && errors.expedition_time)}
                  helperText={touched.expedition_time && errors.expedition_time}
                  label="Prazo de separação"
                  margin="normal"
                  name="expedition_time"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.expedition_time}
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Divider />

          </Grid>

          <Card>
            <CardHeader title="Volumes" subheader={"Em quantidade, você deve preencher a quantidade de volumes com essas medidas que parte desse SKU. Não é a quantidade em estoque."} />
            <CardContent>

              <Grid container spacing={3}>
                <Grid item xs={6} lg={2}>
                  <TextField
                    error={Boolean(touched.height && errors.height)}
                    helperText={touched.height && errors.height}
                    label="Altura (cm)"
                    margin="normal"
                    name="height"
                    required
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.height}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <TextField
                    error={Boolean(touched.width && errors.width)}
                    helperText={touched.width && errors.width}
                    label="Largura (cm)"
                    margin="normal"
                    type="number"
                    name="width"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.width}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <TextField
                    error={Boolean(touched.length && errors.length)}
                    helperText={touched.length && errors.length}
                    label="Comprimento (cm)"
                    margin="normal"
                    name="length"
                    type="number"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.length}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <TextField
                    error={Boolean(touched.weight && errors.weight)}
                    helperText={touched.weight && errors.weight}
                    label="Peso (KG)"
                    margin="normal"
                    name="weight"
                    type="number"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.weight}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <TextField
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                    label="Quantidade"
                    margin="normal"
                    name="quantity"
                    type="number"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.quantity}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={1}>

                </Grid>
                <Grid item xs={1} style={{ display: 'flex' }}>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {parcels.map((parcel, idx) => (
                  <>
                    <Grid item xs={6} lg={2}>
                      <TextField
                        label="Altura (cm)"
                        margin="normal"
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        name="height"
                        value={parcel.height}
                        disabled={(products[parcel.reference] && products[parcel.reference].height)}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={2}>
                      <TextField
                        label="Largura (cm)"
                        type="number"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        name="width"
                        value={parcel.width}
                        disabled={(products[parcel.reference] && products[parcel.reference].width)}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={2}>
                      <TextField
                        label="Comprimento (cm)"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        margin="normal"
                        name="length"
                        value={parcel.length}
                        disabled={(products[parcel.reference] && products[parcel.reference].length)}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={2}>
                      <TextField
                        label="Peso (Kg)"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        type="number"
                        name="weight"
                        value={parcel.weight}
                        disabled={(products[parcel.reference] && products[parcel.reference].weight)}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={2}>
                      <TextField
                        label="Quantidade"
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        margin="normal"
                        name="quantity"
                        value={parcel.quantity}
                        onChange={(ev) => handleParcel(idx, ev)}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6} lg={1}>

                    </Grid>
                    <Grid item xs={1} style={{ display: 'flex' }}>
                      <Button onClick={() => removeParcel(idx)}> <Trash /> </Button>
                    </Grid>

                    {products[parcel.reference] ?
                      (
                        <Grid item xs={12} lg={12}>
                          SKU: <strong>{products[parcel.reference].reference}</strong> <br />
                          Vol. 1 - Dimensões: <strong>({products[parcel.reference].height * 100}cm x {products[parcel.reference].width * 100}cm x {products[parcel.reference].length * 100}cm) | Peso: {products[parcel.reference].weight.toLocaleString('pt-BR')}kg | Quantidade: {products[parcel.reference].quantity * parcel.quantity}</strong>

                          {products[parcel.reference] && products[parcel.reference].property ?
                            products[parcel.reference].property.map((res, idx) => {
                              return <span><br />Vol. {idx == 0 ? 2 : idx + 2} - Dimensões: <strong>({res.height * 100}cm x {res.width * 100}cm x {res.length * 100}cm) | Peso:  {res.weight.toLocaleString('pt-BR')}kg | Quantidade: {res.quantity * parcel.quantity}</strong></span>
                            }) : null}
                        </Grid>
                      ) : null}


                  </>
                ))}
              </Grid>

              <br />
              <Button onClick={addParcel}>
                <Plus /> Adicionar mais um volume.
              </Button>

            </CardContent>


          </Card>

          <Box my={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitText ? submitText : "CADASTRAR"}
            </Button>
          </Box>
        </form>
      )
      }
    </Formik >
  );
};

export default withSnackbar(Form);
