import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Edit, Trash } from 'react-feather';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, cds, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Código de referência
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Endereço
                </TableCell>
                <TableCell>
                  Prazo de Expedição
                </TableCell>
                <TableCell>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cds.map((cd) => (
                <TableRow
                  hover
                  key={cd.id}
                >
                  <TableCell>
                    {cd.id}
                  </TableCell>
                  <TableCell>
                    {cd.reference}
                  </TableCell>
                  <TableCell>
                    {cd.name}
                  </TableCell>
                  <TableCell>
                    {cd.address.street1}{', '}
                    {cd.address.number}{', '}
                    {cd.address.street2 ? cd.address.street2 + ', ':''}
                    {cd.address.zip + ' - ' + cd.address.city + '/' + cd.address.state}
                  </TableCell>
                  <TableCell>
                    {cd.expedition_time ? `${cd.expedition_time} dias úteis` : 'Sem prazo'}
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to={"/settings/distribution-centers/edit/" + cd.id}>
                      <Edit />
                    </Button>
                    <Button component={Link} to={"/settings/distribution-centers/remove/" + cd.id}>
                      <Trash />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
