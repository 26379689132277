import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIcon from '@material-ui/icons/Phone';
import TabletIcon from '@material-ui/icons/Tablet';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}));

const SlaCarriers = ({ className, sla, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        label: 'Entregas dentro do prazo',
        data: sla.map(( res ) => res.totals.on_time),
        stack: 1,
        backgroundColor: colors.green[500],
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      },
      {
        label: 'Entregas fora do prazo',
        data: sla.map((res) => res.totals.out_of_time),
        stack: 1,
        backgroundColor: colors.red[600],
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      },
      {
        label: 'Prazo médio de entrega',
        data: sla.map((res) => res.totals.deadline_avg),
        backgroundColor: '#ffcc00',
        borderColor: '#ffcc00',
        hoverBorderColor: '#ffcc00',
        width: '100%'
      }
    ],
    labels: sla.map((res) => res.name)
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: true,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Nível de serviço por transportadora" />
      <Divider />
      <CardContent>
        <Box
          height={250}
          position="relative"
        >
          <HorizontalBar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

SlaCarriers.propTypes = {
  className: PropTypes.string
};

export default SlaCarriers;
