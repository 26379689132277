import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import moment from 'moment';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SwipeableDrawer,
  Grid,
  Table,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  TableHead,
  TableRow,
  TableCell,
  Chip,
  Tooltip
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';


import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import OrderRow from '../orders/OrdersView/OrderRow';
import { Calendar, Check, CheckCircle, File, Package, Truck } from 'react-feather';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  trackerHeaderStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadBar: {
    height: 4,
    width: '100%',
    backgroundColor: '#ddd',
  },
  loadBarCompleted: {
    height: 4,
    width: '100%',
    backgroundColor: '#8BC34A'
  },
  iconCompleted: {
    color: '#8BC34A'
  },
  iconNormal: {
    color: '#727272'
  }
}));

const NotfisView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const [carrier, setCarrier] = useState(null);
  const [pickupsByCD, setPickupsByCD] = useState([]);

  const [status, setStatus] = useState({});

  const [pickupDate, setPickupDate] = useState(null);
  const [pickupCode, setPickupCode] = useState(null);
  const [selectedPickups, setSelectedPickups] = useState([]);
  const { token } = useParams();

  const confirmPickup = (cdId) => {

    setStatus(st => ({...st, [cdId]: {...[cdId], isConfirming: true}}));

    apiService
      .confirmPickupsFromCarrierToken(token, selectedPickups, pickupDate, pickupCode)
      .then((res) => {
        setStatus(st => ({...st, [cdId]: {...[cdId], isConfirming: false}}));
        setStatus(st => ({...st, [cdId]: {...[cdId], confirmed: true}}));

        setSelectedPickups([]);

        reloadPickups();
      });

  }

  const handleSelected = (id) => {
    if (selectedPickups.indexOf(id) !== -1) {
      setSelectedPickups(selectedPickups.filter((pid) => pid !== id));
      return;
    }
    setSelectedPickups(st => [...st, id]);
  }

  const hasPickupsSelected = (cd) => {
    return selectedPickups.some((id) => cd.pickups.some((pickup) => pickup.id == id));
  }

  const reloadPickups = () => {
    apiService
      .getPickupsFromCarrierToken(token)
      .then((res) => {
        setCarrier(res.carrier)
        setPickupsByCD(res.distribution_center);
        let statuses = {};
        res.distribution_center.forEach((cd) => {
          statuses[cd.id] = {
            id: cd.id,
            confirmed: false,
            isConfirming: false
          };
        });

        setStatus(statuses);

        console.log(statuses);
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  useEffect(() => {
    reloadPickups();
  }, [token]);

  return (
    <Page
      className={classes.root}
      title="Arquivos para coleta"
    >
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 0, marginBottom: 60, background: '#f5f6f8' }}>
        <img src="https://flixlog.com/images/flixlogo.png" style={{ width: 150, margin: '0 auto' }} />
      </div>

      <Box style={{ margin: '40px auto', maxWidth: '1000px' }}>
        <Grid container >
          {carrier && <Grid item xs={12}>

            <Typography color="textPrimary" variant="h1" style={{ marginBottom: 30 }}>
              Olá, {carrier.canonical_name.toUpperCase()}.
            </Typography>
          </Grid>}
        </Grid>

        {pickupsByCD.map((distribution_center) => <Card style={{ marginBottom: 30 }}>
          <CardHeader
            title={`${distribution_center.name} - ${distribution_center.address.city}/${distribution_center.address.state}`}
            subheader={<>
              <strong>Endereço:</strong>  {distribution_center.format_address} <br />
              <strong>Razão Social:</strong> {distribution_center.address.name} | <strong>CNPJ:</strong> {distribution_center.address.federal_tax_id} | <strong>Tel:</strong> {distribution_center.address.phone}
            </>}></CardHeader>
          <CardContent>
            <Alert severity="error" style={{ color: 'red' }} >
              <AlertTitle>Horários de coleta do CD:</AlertTitle>
              <p>
                {distribution_center.pickup_window.map((pic) => <>
                  {pic}<br />
                </>)}
              </p>

            </Alert>
            <br />

            {distribution_center?.pickups && distribution_center.pickups.length > 0 ? <Card>
              <CardHeader title="Lista de coletas aguardando aceite"></CardHeader>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableCell>
                      <Checkbox
                        onChange={(ev) => ev.target.checked ? setSelectedPickups(distribution_center.pickups.map(({ id }) => id)) : setSelectedPickups([])}
                      />
                    </TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Qtd. Volumes</TableCell>
                    <TableCell>Peso total</TableCell>
                    <TableCell>Volumetria</TableCell>
                     <TableCell>Valor da carga</TableCell>
                    <TableCell>Data de Aceite</TableCell>
                    <TableCell>Código da coleta</TableCell>
                  </TableHead>
                  <TableBody>
                    {distribution_center.pickups.map((pickup) => <>
                      <TableRow>
                        <TableCell><Checkbox
                          checked={selectedPickups.indexOf(pickup.id) !== -1}
                          onChange={() => handleSelected(pickup.id)}
                        /></TableCell>
                        <TableCell>{pickup.id}</TableCell>
                        <TableCell>{pickup.totals.total_volumes}</TableCell>
                        <TableCell>{pickup.totals.total_weight}kg</TableCell>
						  <TableCell style={{whiteSpace: 'nowrap'}}>Total: {pickup.totals.total_m3}m³ <br/> Maior: {pickup.totals.max_m3}m³ <br/> Menor: {pickup.totals.min_m3}m³</TableCell>
 						  
                        <TableCell> R$ {pickup.totals.cargo_value}</TableCell>
                        <TableCell>{pickup.pickup_date ? <Tooltip title="Coleta já foi aceita pela transportadora"> <Chip
                          variant="outlined"
                          size="small"
                          label={moment(pickup.pickup_date).format('DD/MM/YYYY')}
                          color="secondary"
                          style={{ color: '#fff', background: '#4caf50', borderColor: '#4caf50' }}
                        /></Tooltip> : <Tooltip title="Coleta ainda não foi aceita pela transportadora. Selecione as coletas e clique em confirmar."><Chip
                          variant="outlined"
                          size="small"
                          label="Aguardando Aceite"
                          color="secondary"
                          style={{ color: '#fff', background: 'red', borderColor: 'red' }}
                        /></Tooltip>}</TableCell>
                        <TableCell>{pickup.integration_code ? pickup.integration_code : 'Não informado'}</TableCell>
                      </TableRow>
                      {pickup.note ? <TableRow rowspan={2}>
                        <TableCell></TableCell>
                        <TableCell colSpan={6}><div style={{ color: 'red' }}>{pickup.note}</div></TableCell></TableRow> : null}

                    </>)}
                  </TableBody>
                </Table>
              </CardContent>

            </Card> : null}


            {!hasPickupsSelected(distribution_center) && distribution_center?.pickups && distribution_center.pickups.length > 0 ? <Alert severity="warning" >
              <AlertTitle>Importante!</AlertTitle>
              Selecione uma ou mais coletas para confirmar.
            </Alert> : null}


            {hasPickupsSelected(distribution_center) ? <Card>
              <CardHeader title="Arquivos para download" />
              <CardContent>

                {status[distribution_center.id]?.confirmed ? <><Alert severity="success" >
                  <AlertTitle>Parabéns!</AlertTitle>
                  A coleta foi confirmada para o dia {moment(pickupDate).format('DD/MM/YYYY')} <br />
                  Veja abaixo a lista de arquivos referentes a esta coleta para baixar:
                </Alert><br /><br /></> : null}

                {status[distribution_center.id]?.confirmed ? null : <>


                  <Alert severity="warning" >
                    <AlertTitle>Importante!</AlertTitle>
                    Para visualizar os arquivos das <strong>Notas Fiscais</strong> você precisa informar a <strong>Data de Coleta</strong> e clicar no botão <strong>CONFIRMAR COLETA</strong>.
                  </Alert>



                  <Grid container style={{ marginTop: 50 }}>

                    <Grid xs={6} lg={4} item>
                      <TextField
                        onChange={(e) => setPickupCode(e.target.value)}
                        label="Código da Coleta"
                        variant="outlined"
                        style={{ marginBottom: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }} />
                      <small style={{
                        display: 'block',
                        fontSize: '11px',
                        color: '#242424'
                      }}>Você pode informar aqui o código da coleta <br /> gerada no seu sistema.</small>
                    </Grid>
                    <Grid xs={6} lg={4} item>
                      <TextField
                        type="date"
                        onChange={(e) => setPickupDate(e.target.value)}
                        label="Data da Coleta"
                        variant="outlined"
                        style={{ marginBottom: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }} />
                    </Grid>
                    <Grid xs={12} lg={4} item>
                      <Button
                        color="primary"
                        style={{ backgroundColor: '#8bc34a', height: '55px' }}
                        disabled={status[distribution_center.id]?.isConfirming || selectedPickups.length <= 0}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        autoFocus
                        onClick={() => {
                          if (!pickupDate) {
                            alert('Informe a data estimada de coleta');
                          } else {
                            confirmPickup(distribution_center.id);
                          }

                        }}
                      >
                        {status[distribution_center.id]?.isConfirming ? 'CONFIRMANDO ...' : 'CONFIRMAR COLETA'}
                      </Button>
                    </Grid>
                  </Grid>
                </>}

              </CardContent>
            </Card> : null}

            <br />

            {distribution_center?.pickups_accepted ? <Card>
              <CardHeader title="Lista de coletas aceitas nos últimos dias"></CardHeader>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableCell>ID</TableCell>
                    <TableCell>Qtd. Volumes</TableCell>
                    <TableCell>Peso total</TableCell>
                    <TableCell>Volumetria</TableCell>
                    <TableCell>Valor da carga</TableCell>
                    <TableCell>Previsão de Coleta</TableCell>
                    <TableCell>Arquivos</TableCell>
                  </TableHead>
                  <TableBody>
                    {distribution_center.pickups_accepted.map((pickup) => <>
                      <TableRow>
                        <TableCell>{pickup.id}</TableCell>
                        <TableCell>{pickup.totals.total_volumes}</TableCell>
                        <TableCell>{pickup.totals.total_weight}kg</TableCell>
						  <TableCell  style={{whiteSpace: 'nowrap'}}>Total: {pickup.totals.total_m3}m³ <br/> Maior: {pickup.totals.max_m3}m³ <br/> Menor: {pickup.totals.min_m3}m³</TableCell>
                        <TableCell> R$ {pickup.totals.cargo_value}</TableCell>
                        <TableCell>
                          <strong>{moment(pickup.pickup_date).format('DD/MM/YYYY')}</strong> <br />
                          {pickup.integration_code}
                        </TableCell>
                        <TableCell>
                          <ul style={{ listStyleType: 'none' }}>
                            {pickup.files.map((res) => <li>
                              <a href={res.file} target="_blank" download style={{ color: '#03a9f4', display: 'flex', alignItems: 'center' }} autoFocus>
                                <File style={{ marginRight: 10 }} /> {res.description}</a>
                            </li>)}
                          </ul>
                        </TableCell>
                      </TableRow>

                      {pickup.note ? <TableRow rowspan={2}>
                        <TableCell colSpan={6}><div style={{ color: 'red' }}>{pickup.note}</div></TableCell></TableRow> : null}

                    </>)}
                  </TableBody>
                </Table>
              </CardContent>

            </Card> : null}

          </CardContent>
        </Card >
        )}

      </Box >
    </Page >
  );
};

export default NotfisView;
