import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import Form from './Form';
import { getActualCompany } from 'src/common/auth';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import Header from 'src/layouts/DashboardLayout/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  }
}));

const EditDistributionCenterView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const match = useParams();
  const [cd, setCd] = useState(null);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getDistributionCenter(match.id)
      .then((res) => setCd(res));
  }, [match.id]);

  return (
    <Page
      className={classes.root}
      title={`Editar CD ${cd?.reference} - Centros de Distribuição - Flixlog`}
    >
      <Header
        title={`Editar CD ${cd?.reference}`}
        description="Editar informações do centro de distribuição."
        // help="https://flixlog.readme.io/"
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          },
          {
            text: 'Centros de Distribuição',
            link: '/settings/distribution-centers'
          },
          {
            text: 'Editar CD #' . cd?.reference,
            link: ''
          }
        ]}
        buttons={[
          {
            text: 'Voltar',
            appearance: 'subtle',
            link: '/settings/distribution-centers'
          }
        ]}
      />

      <Container maxWidth={false}>
        <Box mt={3}>
          <Card>
            <CardContent>
              {cd ? <Form
                initialValues={{
                  reference: cd.reference,
                  name: cd.name,
                  expedition_time: cd.expedition_time,
                  address_name: cd.address.name,
                  address_federal_tax_id: cd.address.federal_tax_id,
                  address_phone: cd.address.phone,
                  address_email: cd.address.email,
                  address_zip: cd.address.zip,
                  address_street1: cd.address.street1,
                  address_number: cd.address.number,
                  address_street2: cd.address.street2,
                  address_neighborhood: cd.address.neighborhood,
                  address_city: cd.address.city,
                  address_state: cd.address.state,
                  address_country: cd.address.country,

                  pickup_window_monday_time1: cd.pickup_window ? cd.pickup_window.monday.time1 : '09:00',
                  pickup_window_monday_time2: cd.pickup_window ? cd.pickup_window.monday.time2 : '12:00',
                  pickup_window_monday_time3: cd.pickup_window ? cd.pickup_window.monday.time3 : '14:00',
                  pickup_window_monday_time4: cd.pickup_window ? cd.pickup_window.monday.time4 : '17:00',

                  pickup_window_tuesday_time1: cd.pickup_window ? cd.pickup_window.tuesday.time1 : '09:00',
                  pickup_window_tuesday_time2: cd.pickup_window ? cd.pickup_window.tuesday.time2 : '12:00',
                  pickup_window_tuesday_time3: cd.pickup_window ? cd.pickup_window.tuesday.time3 : '14:00',
                  pickup_window_tuesday_time4: cd.pickup_window ? cd.pickup_window.tuesday.time4 : '17:00',

                  pickup_window_wednesday_time1: cd.pickup_window ? cd.pickup_window.wednesday.time1 : '09:00',
                  pickup_window_wednesday_time2: cd.pickup_window ? cd.pickup_window.wednesday.time2 : '12:00',
                  pickup_window_wednesday_time3: cd.pickup_window ? cd.pickup_window.wednesday.time3 : '14:00',
                  pickup_window_wednesday_time4: cd.pickup_window ? cd.pickup_window.wednesday.time4 : '17:00',

                  pickup_window_thursday_time1: cd.pickup_window ? cd.pickup_window.thursday.time1 : '09:00',
                  pickup_window_thursday_time2: cd.pickup_window ? cd.pickup_window.thursday.time2 : '12:00',
                  pickup_window_thursday_time3: cd.pickup_window ? cd.pickup_window.thursday.time3 : '14:00',
                  pickup_window_thursday_time4: cd.pickup_window ? cd.pickup_window.thursday.time4 : '17:00',

                  pickup_window_friday_time1: cd.pickup_window ? cd.pickup_window.friday.time1 : '09:00',
                  pickup_window_friday_time2: cd.pickup_window ? cd.pickup_window.friday.time2 : '12:00',
                  pickup_window_friday_time3: cd.pickup_window ? cd.pickup_window.friday.time3: '14:00',
                  pickup_window_friday_time4: cd.pickup_window ? cd.pickup_window.friday.time4 : '17:00',

                }}
                title={"Editar #" + cd.reference}
                submitText="SALVAR"
                onSubmit={(values) => {
                  const apiService = new ApiService();
                  apiService
                    .updateDistributionCenter({
                      id: cd.id,
                      reference: values.reference,
                      expedition_time: values.expedition_time,
                      name: values.name,
                      address: {
                        residential: false,
                        name: values.address_name,
                        federal_tax_id: values.address_federal_tax_id,
                        phone: values.address_phone,
                        email: values.address_email,
                        zip: values.address_zip,
                        street1: values.address_street1,
                        number: values.address_number,
                        street2: values.address_street2,
                        neighborhood: values.address_neighborhood,
                        city: values.address_city,
                        state: values.address_state
                      },
                      pickup_window: {
                        monday: {
                          time1: values.pickup_window_monday_time1,
                          time2: values.pickup_window_monday_time2,
                          time3: values.pickup_window_monday_time3,
                          time4: values.pickup_window_monday_time4
                        },
                        tuesday: {
                          time1: values.pickup_window_tuesday_time1,
                          time2: values.pickup_window_tuesday_time2,
                          time3: values.pickup_window_tuesday_time3,
                          time4: values.pickup_window_tuesday_time4
                        }, 
                        wednesday: {
                          time1: values.pickup_window_wednesday_time1,
                          time2: values.pickup_window_wednesday_time2,
                          time3: values.pickup_window_wednesday_time3,
                          time4: values.pickup_window_wednesday_time4
                        }, 
                        thursday: {
                          time1: values.pickup_window_thursday_time1,
                          time2: values.pickup_window_thursday_time2,
                          time3: values.pickup_window_thursday_time3,
                          time4: values.pickup_window_thursday_time4
                        }, 
                        friday: {
                          time1: values.pickup_window_friday_time1,
                          time2: values.pickup_window_friday_time2,
                          time3: values.pickup_window_friday_time3,
                          time4: values.pickup_window_friday_time4
                        }
                      }
                    })
                    .then((res) => {
                      enqueueSnackbar('CD atualizado com sucesso', {
                        variant: 'success',
                      });
                    })
                    .catch((res) => {
                      enqueueSnackbar('Ocorreu um erro:' + res.message, {
                        variant: 'error',
                      });
                    });

                  return true;
                }}
              /> : 'Carregando...'}
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(EditDistributionCenterView);
