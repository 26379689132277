import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import axios from 'axios';
import * as _ from 'underscore';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core';
import OrdersListView from 'src/views/orders/OrdersView';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus, Trash } from 'react-feather';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));


const Form = ({ enqueueSnackbar, initialValues, onSubmit, submitText, title }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const lastQuote = localStorage.getItem('lastQuote') ? JSON.parse(localStorage.getItem('lastQuote')) : {};
  const [orders, setOrders] = useState([]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(vals) => onSubmit(vals, orders)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={(values) => handleSubmit(values, orders)}>
          <Card>
            <CardContent>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  {title ? title : "Solicitar Coleta"} <br />
                  <Alert variant="warning">Selecione abaixo os pedidos que deseja solicitar coleta</Alert>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <FormControl>
                    <small >Observações (Opcional)</small><br />

                    <TextareaAutosize
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                      label="Observação (Opcional)"
                      margin="normal"
                      name="note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                      variant="outlined"
                      cols={200}
                      placeholder="Digite aqui caso tenha algo para especificar dessa coleta."
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ border: '1px solid #ddd', width: '100%' }}
                      fullWidth
                    />
                  </FormControl>

                </Grid>
                <Grid item xs={12} lg={6}>

                  <strong style={{ float: 'right' }}>{`Pedidos selecionados: (${orders.length})`}</strong>
                  <br />
                  <br />
                  <Button
                    color="primary"
                    disabled={isSubmitting || !orders.length}
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ float: 'right' }}
                  >
                    {submitText ? submitText : "SOLICITAR COLETA"}
                  </Button>
                </Grid>

              </Grid>
              <br /><br />

              <Divider />
              <br />
              <br />

              <OrdersListView
                miniVersion
                isPickupPage
                defaultStatus={2}
                limit={100}
                limits={[50, 100, 200]}
                onSelect={(e) => setOrders(e)}
              />
            </CardContent>
          </Card>

        </form>
      )
      }
    </Formik >
  );
};

export default withSnackbar(Form);
