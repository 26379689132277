import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Avatar
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

const OAuth = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  const apiService = new ApiService();
  const urlStore = getQueryVariable('url');
  const flistLogUrl = `${window.location.protocol}//${window.location.host}/settings/integrations/tray`;
  const consumerKey = 'c99b93e7ea78ae25593570674666cc0d128c0808a2517368ff777c79aaebce4c';
  const redirect = `${urlStore}/auth.php?response_type=code&consumer_key=${consumerKey}&callback=${flistLogUrl}`;
  const channelId = 8;

  const isAuth = localStorage.getItem('@flixlog-token');

  const redirectToTray = (url) => {
    setDisableButton(true);
    if (isAuth) {
      window.location.href = url ? `${url}/auth.php?response_type=code&consumer_key=${consumerKey}&callback=${flistLogUrl}` : redirect;
    } else {
      window.location.href = url ? `/login?redirect=${btoa(url)}` : `/login?redirect=${btoa(redirect)}`;
    }
  };

  useEffect(() => {
    apiService
      .getCompanyChannels()
      .then((res) => {
        res.data.forEach((cc) => {
          if (cc.channel_id === channelId && cc.property.store_host == urlStore) {
            return window.location.href = '/settings/integrations/' + cc.id + '/detail';
          }
        });
      });
  }, []);

  return (
    <Page
      className={classes.root}
      title="Tray + Flixlog"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              url: ''
            }}
            onSubmit={(values) => {
              return redirectToTray(values.url);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Avatar
                    style={{ margin: '0 auto', width: 125, height: 55 }}
                    src="https://images.tcdn.com.br/static_inst/site/vendedor/tray-cdn/uploads/logo-header.svg"
                  ></Avatar>
                  <br />
                  <br />
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: 'center' }}
                  >
                  </Typography>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{ textAlign: 'center' }}

                  >
                    Clique no botão abaixo para iniciar o processo de <br /> integração da Tray com a Flixlog
                  </Typography>
                </Box>

                {!urlStore ? (
                  <TextField
                    error={Boolean(touched.url && errors.url)}
                    fullWidth
                    required
                    helperText={touched.url && errors.url}
                    label="Digite a URL da sua loja"
                    margin="normal"
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.url}
                    placeholder="https://trayparceiros.commercesuite.com.br"
                    variant="outlined"
                  />
                ) : null}

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {isSubmitting ? 'AGUARDE ...' : 'INTEGRAR TRAY + FLIXLOG'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(OAuth);
