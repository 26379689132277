import React from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewPasswordView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const params = useParams();
  const token = params.token;

  return (
    <Page
      className={classes.root}
      title="Nova senha"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              password_confirm: '',
            }}
            validationSchema={
              Yup.object().shape({
                password: Yup.string().max(20).required('Senha obrigatória'),
                password_confirm: Yup.string().max(20).required('Confirmação obrigatória'),
              })
            }
            onSubmit={(values) => {
              return apiService
                .newPassowrd({
                  token,
                  password: values.password,
                  password_confirm: values.password_confirm,
                })
                .then(() => {
                  enqueueSnackbar('Senha alterada com sucesso.', {
                    variant: 'success',
                  });
                  navigate('/login');
                }).catch((res) => {
                  enqueueSnackbar(`Ocorreu um erro, tente novamente: ${res.message}`, {
                    variant: 'error',
                  });
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Nova senha
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Digite uma senha que segura.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Digite sua nova senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password_confirm && errors.password_confirm)}
                  fullWidth
                  helperText={touched.password_confirm && errors.password_confirm}
                  label="Confirme a senha"
                  margin="normal"
                  name="password_confirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password_confirm}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ALTERAR SENHA
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Já sabe a senha?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Entrar
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(NewPasswordView);
