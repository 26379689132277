import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FreightRuleView = () => {
  const classes = useStyles();
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(false);

  const reloadList = (filters) => {
    const apiService = new ApiService();
    apiService.getFreightRules(filters).then((res) => {
      setRules(res.data);
      setLoading(false);
    });
  };

  const toggleActive = (freightRule) => {
    const apiService = new ApiService;
    setLoading(true);
    apiService
      .toggleFreightRule(freightRule.id)
      .then(() => {
        reloadList();
      });
  }

  useEffect(() => {
    reloadList();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Regras de Frete"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results rules={rules} toggleActive={toggleActive} loading={loading} />
        </Box>
      </Container>
    </Page>
  );
};

export default FreightRuleView;
