import { Button } from '@material-ui/core';
import React from 'react';
import ApiService from 'src/common/apiService';

class SimpleReactFileUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      submiting: props.submiting
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.apiService = new ApiService();
    this.props = props;
  }

  onFormSubmit(e) {
    e.preventDefault();
    this.setState({ submiting: true });
    this
      .fileUpload(this.state.file)
      .then((response) => {
        this.props.onSuccess(response);
      });
  }

  onChange(e) {
    this.setState({
      file: e.target.files[0]
    });
  }

  fileUpload(file) {
    return this.apiService.upload(file);
  }

  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <h1>{this.props.title}</h1>
        <input type="file" onChange={this.onChange} />
        <Button
          type="submit"
          color="primary"
          autoFocus
          disabled={(!this.state.file && (this.state.file && this.state.submiting))}
          style={{ backgroundColor: '#8BC34A', color: '#fff' }}>
          Enviar
        </Button>
      </form>
    );
  }
}



export default SimpleReactFileUpload
