import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import ApiService from 'src/common/apiService';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FreightTableListView = ({enqueueSnackbar}) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [freightTables, setFreightTables] = useState([]);
  const apiService = new ApiService();
  const [limit, changeLimit] = useState(10);
  const [page, changePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const refresh = (filters) => {
    apiService
      .getFreightTables(filters, limit, page)
      .then((res) => {
        setFreightTables(res.data);
        setTotal(res.total);
      });
  };

  const toggleActive = (freightTable) => {
    const apiService = new ApiService;
    
    if (freightTable.active) {
      freightTable.active = 0;
      apiService.inactiveFreightTable(freightTable.id, freightTable.version)
      .then(() => refresh({}))
      .then(() => enqueueSnackbar('Tabela de frete INATIVADA com sucesso!', {
        variant: 'success',
      }));
    } else {
      freightTable.active = 1;
      apiService
        .activeFreightTable(freightTable.id, freightTable.version)
        .then(() => setLoading(false))
        .then(() => refresh({}))
        .then(() => enqueueSnackbar('Tabela de frete ATIVADA com sucesso!', {
          variant: 'success',
        }));
    }
  }

  const [downloading, setDownloading] = useState(false);

  const downloadFile = (data, fileName, type = "text/plain") => {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  
  const downloadTable = (freightTable) => {
    setDownloading(true);
    const apiService = new ApiService();
    apiService
      .downloadFreightTable(freightTable.id)
      .then((res) => {
        downloadFile(res, `TABELA_${freightTable.company.name}_${freightTable.carrier.canonical_name}.csv`, "application/csv");
        setDownloading(false);
      }).catch(() => {
        setDownloading(false);
      });
  }

  useEffect(() => {
    refresh({});
  }, [limit, page]);

  return (
    <Page
      className={classes.root}
      title="Tabelas de Frete"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results
            freightTables={freightTables}
            total={total}
            changePage={changePage}
            changeLimit={changeLimit}
            toggleActive={toggleActive}
            downloadTable={downloadTable}
            downloading={downloading}
            loading={loading}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(FreightTableListView);
