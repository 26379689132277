import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewProductView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cds, setCds] = useState([]);

  return (
    <Page
      className={classes.root}
      title="Novo - Centro de distribuição"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton />
        <Box mt={3}>
          <Card>
            <CardContent>
              <Form
                initialValues={{}}
                title="Novo"
                submitText="CADASTRAR"
                onSubmit={(values, parcels) => {
                  const apiService = new ApiService();

                  apiService
                    .newProduct({
                      reference: values.reference,
                      description: values.description,
                      price: values.price,
                      distribution_center_id: values.distribution_center_id,
                      width: values.width / 100,
                      height: values.height / 100,
                      length: values.length / 100,
                      weight: values.weight,
                      quantity: values.quantity,
                      expedition_time: values.expedition_time,
                      property: parcels.map((parcel) => {
                        return {
                          reference: values.reference,
                          width: parcel.width / 100,
                          height: parcel.height / 100,
                          length: parcel.length / 100,
                          weight: parcel.weight,
                          quantity: parcel.quantity,
                        };
                      })
                    })
                    .then(() => {
                      enqueueSnackbar('Produto cadastrado com sucesso', {
                        variant: 'success',
                      });
                      navigate('/settings/products');
                    })
                    .catch((res) => {
                      enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                        variant: 'error',
                      });
                    });

                  return true;
                }}
              />
            </CardContent>
          </Card>

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewProductView);
