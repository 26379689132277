import React, { useEffect, useState } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import { withSnackbar } from 'notistack';
import { getToken } from 'src/common/auth';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { JsonToTable } from "react-json-to-table";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Drawer,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';
import Dropzone, { defaultClassNames } from 'react-dropzone-uploader';
import { Eye } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
  return (
    <div>
      {previews}

      <div {...dropzoneProps}>
        {files.length < maxFiles && input}
      </div>

      {files.length > 0 && submitButton}
    </div>
  )
};

const CustomLayout = ({ setSubmiting, setSuccess, enqueueSnackbar}) => {
  const getUploadParams = ({ file }) => {
    const apiService = new ApiService();

    const body = new FormData();
    body.append('file', file);

    return {
      url: `${apiService.getBaseURL()}upload`,
      body,
      headers: {
        'X-Token': getToken()
      }
    };
  };

  const handleSubmit = (files, allFiles) => {
    const apiService = new ApiService();
    setSubmiting(true);
    return apiService.createOrdersFromInvoices({
      invoices: allFiles.map((res) => {
        const response = JSON.parse(res.xhr.responseText);
        return { path: response.path };
      })
    }).then((res) => {
      setSuccess(res);
      setSubmiting(false);
      enqueueSnackbar('Arquivos enviados com sucesso. Analise se o retorno na listagem abaixo é o esperado.', {
        variant: 'success'
      });
    }).catch((res) => {
      setSubmiting(false);
      enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
        variant: 'error'
      });
    });
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      LayoutComponent={Layout}
      multiple
      onSubmit={handleSubmit}
      classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
      inputContent="Clique ou arraste as notas fiscais. Permitido xml ou zip"
    />
  );
};

const NewImportView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState(null);

  const openErrors = (totals) => {
    setErrors(totals?.errors);
  }

  const reloadBatch = (filters) => {
    const apiService = new ApiService();
    apiService.getBatches({type: 1}).then((res) => {
      setBatches(res.data);
    });
  };

  useEffect(() => {
    reloadBatch();
  }, [success, submiting]);

  return (
    <Page
      className={classes.root}
      title="Importar Notas Fiscais"
    >
      <Container maxWidth={false}>
        <Drawer anchor="right" open={Boolean(errors)} onClose={() => setErrors(null)}>
          <Card>
            <CardHeader title="Log de errors"></CardHeader>
            <CardContent>
              {errors?.map((error) => <><small>{error}</small><br/></>)}
            </CardContent>
          </Card>
        </Drawer>
        <Box>
          <Card>
            <CardHeader title="Upload de notas fiscais" />
            <CardContent>
              {submiting === false
                ? <CustomLayout enqueueSnackbar={enqueueSnackbar} setSubmiting={setSubmiting} setSuccess={setSuccess} />
                : 'Processando! Aguarde ...'}
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Lotes enviados" />

            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        #
                      </TableCell>
                      <TableCell>
                        Lote
                      </TableCell>
                      <TableCell>
                        Total
                      </TableCell>
                      <TableCell>
                        Sucesso
                      </TableCell>
                      <TableCell>
                        Erro
                      </TableCell>
                      <TableCell>
                        Criado em
                      </TableCell>
                      <TableCell>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {batches.map((batch) => (
                      <TableRow
                        hover
                        key={batch.id}
                      >
                        <TableCell>
                          {batch.id}
                        </TableCell>
                        <TableCell>
                          {batch.batch}
                        </TableCell>
                        <TableCell>
                          {batch.totals ? batch.totals.total : '-'}
                        </TableCell>
                        <TableCell>
                          <strong>{batch.totals ? batch.totals.total_success : '-'}</strong>
                        </TableCell>
                        <TableCell>
                          {batch.totals ? batch.totals.total_error : '-'}
                        </TableCell>
                        <TableCell>
                          {moment(batch.created_at).format('DD/MM/YYYY HH:mm')}
                        </TableCell>
                        <TableCell>
                          {batch?.totals?.errors?.length ? <Button onClick={() => openErrors(batch.totals)}>
                            <Eye />
                          </Button>: null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewImportView);
