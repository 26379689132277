import React, { useState, useEffect, useCallback } from 'react';

import {
  makeStyles,
  Container,
  Grid
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Header from '../../../../layouts/DashboardLayout/Header';
import OrdersList from './List';
import { Trash } from 'react-feather';
import ShipmentsConfirmed from 'src/views/reports/DashboardView/ShipmentsConfirmed';
import BlockMoney from 'src/views/reports/DashboardView/BlockMoney';
import ConciliationNumbers from 'src/views/reports/DashboardView/ConciliationNumbers';
import CteList from './CteList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f3f3f3',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const WithoutOrders = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [flixlogAmoundFreight, setFlixlogAmountFreight] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [carriersAmoundFreight, setCarriersAmountFreight] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [diff, setDiff] = useState({ total: 0, percentage: 0, last_total: 0 });
  const [conciliation, setConciliation] = useState({ total: 0, percentage: 0, last_total: 0 });

  const [since, setSince] = useState(30);
  const [reprocessing, setReprocessing] = useState(false);

  const reprocess = () => {
    setReprocessing(true);
    return apiService
    .reprocessCtes()
    .then(() => {
      setReprocessing(false);
      alert("CTEs re-processados com sucesso");
      window.location.reload();
    });
  }

  return (
    <Page
      className={classes.root}
      title="Auditoria de Frete - Flixlog"
    >
      <Header
        title="Auditoria de Frete"
        description="Aqui você encontra a listagem de CTEs das transportadoras relacionado com a respectiva NF. Você pode também fazer o upload dos CTEs faltantes para fazer a conciliação do frete."
        // help="https://flixlog.readme.io/"
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          },
          {
            text: 'Auditoria',
            link: ''
          }
        ]}

        tabs={{
          tabSelected: 1,
          data: [
            {
              text: 'Conciliados',
              link: '/audit'
            },
            {
              text: 'Sem Conciliação',
              link: '/audit/without-order'
            }
          ]
        }}
        buttons={[
          {
            text: 'Reprocessar',
            onClick: reprocess,
            isDisabled: reprocessing
          },
          {
            text: 'Importar CTEs',
            link: '/audit/import'
          }
        ]}
      />

      <Container maxWidth={false}>

        <CteList
          height={'100%'}
        />
      </Container>
    </Page>
  );
};


export default WithoutOrders;
