import { ORDER_SEARCH, ORDER_SEARCH_STATUS, ORDER_SEARCH_CARRIER, PRODUCT_FILTER } from 'src/actions/actionTypes';

const initialState = {
  value: '',
  many: '',
  status: 0
};

export const changeOrderSearch = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_SEARCH:
      return {
        ...state,
        value: action.search,
        many: action.many,
      };
    case ORDER_SEARCH_STATUS:
      return {
        ...state,
        status: action.status
      };
    case ORDER_SEARCH_CARRIER:
      return {
        ...state,
        carrier: action.carrier
      };
    default:
      return state;
  }
};

export const filterProduct = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_FILTER:
      return {
        ...state,
        filter: action.filter
      };
    default:
      return state;
  }
};
