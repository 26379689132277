import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import EditDriver from './EditDriver';
import EditPickup from './EditPickup';

import {
  Box,
  Container,
  makeStyles,
  Grid,
  Card,
  Table,
  TableBody,
  CardHeader,
  Typography,
  CardContent, TextareaAutosize, Button, Avatar, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TableCell, TableHead, TableRow, Radio
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import OrderRow from '../OrdersView/OrderRow';
import SimpleReactFileUpload from './FileUpload';
import { isSuperUser } from 'src/common/auth';
import SimpleOrder from './SimpleOrder';
import DropshippingOrder from './DropshippingOrder';

const OrderPreview = (props) => {
  const [order, setOrder] = useState(null);
  const [openChangeCarrier, setOpenChangeCarrier] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [sendingInvoice, setSendingInvoice] = useState(false);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  const [issue, setIssue] = useState(null);
  const [sendingIssue, setSendingIssue] = useState(false);
  const apiService = new ApiService();
  const params = useParams();
  const orderId = params.id;

  useEffect(() => {
    if (openChangeCarrier) {
      const apiService = new ApiService();
      apiService
        .quoteOrder(orderId)
        .then((res) => {
          setCarriers(res.data);
        });
    }
  }, [openChangeCarrier]);

  const changeCarrier = () => {
    if (!selectedCarrier) {
      alert('Selecione uma cotação');
      return;
    }

    apiService
      .changeOrderCarrier(order.id, selectedCarrier)
      .then(() => {
        props.enqueueSnackbar('Transportadora alterada com sucesso!', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };

  const sendIssue = () => {
    setSendingIssue(true);
    apiService.createNewIssue({
      message: issue,
      order_id: orderId
    }).then(() => {
      props.enqueueSnackbar('Mensagem enviada com sucesso!', {
        variant: 'success',
      });
      setSendingIssue(false);
      setIssue(null);
      apiService.getOrder(orderId)
        .then((_order) => {
          setOrder(_order);
        });
    }).catch(() => {
      props.enqueueSnackbar('Ocorreu um erro ao tentar enviar a mensagem, tente novamente.', {
        variant: 'error',
      });
    });
  };

  const cancelOrder = () => {
    apiService
      .cancelOrder(order.reference)
      .then(() => {
        props.enqueueSnackbar('Pedido cancelado com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        props.enqueueSnackbar(error.message, {
          variant: 'error',
        });
      });
  };



  const sendInvoice = (response) => {
    setSendingInvoice(true);
    apiService
      .sendInvoice({
        order: {
          reference: order.reference
        },
        invoice: {
          path: response.data.path
        }
      })
      .then(() => {
        props.enqueueSnackbar('Nota fiscal anexada com sucesso.', {
          variant: 'success',
        });
        window.location.reload();
      }).catch((error) => {
        console.log(error.message);
        props.enqueueSnackbar('Ocorreu um erro ao tentar anexar nota fiscal, tente novamente. ' + error.message, {
          variant: 'error',
        });
      });
  };

  const refreshOrder = () => {
    apiService
      .getOrder(orderId)
      .then((_order) => {
        setOrder(_order);
      });
  }

  useEffect(() => {
    refreshOrder();
  }, [orderId]);

  return (
    order ?
      (order.is_drop_shipping ?
        <DropshippingOrder
          order={order}
          refreshOrder={refreshOrder} /> :
        <SimpleOrder
          order={order}
          refreshOrder={refreshOrder}

        />) : null
  );
};

const mapStateToProps = (store) => ({
  orderSearch: store.orderSearch.value
});

export default connect(mapStateToProps)(withSnackbar(OrderPreview));
