import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import moment from 'moment';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SwipeableDrawer,
  Grid,
  Table,
  TableBody,
  Card,
  CardHeader,
  CardContent,
  LinearProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import OrderRow from '../orders/OrdersView/OrderRow';
import { Calendar, Check, CheckCircle, Package, Truck } from 'react-feather';
import { withStyles } from '@material-ui/styles';
import TrackingCustomView from './TrackingCustomView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  trackerHeaderStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadBar: {
    height: 4,
    width: '100%',
    backgroundColor: '#ddd',
  },
  loadBarCompleted: {
    height: 4,
    width: '100%',
    backgroundColor: '#8BC34A'
  },
  iconCompleted: {
    color: '#8BC34A'
  },
  iconNormal: {
    color: '#727272'
  }
}));

const FlixlogProgress = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#ddd'
  },
  bar: {
    backgroundColor: '#ff6a66',
  },
}))(LinearProgress);

const TrackingLinkCustomView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const { trackingCode, company } = useParams();
  const [order, setOrder] = useState(null);
  const [orders, setOrders] = useState([]);

  const companies = {
    meblo: {
      logo: 'https://recursos.meblo.com.br/i/logo-rodape.png',
      primaryColor: '#ff6a66',
      secundaryColor: '#142032'
    },
    bimper: {
      logo: 'https://17530.cdn.simplo7.net/static/17530/configuracao/logo_152933445342328.png',
      primaryColor: '#dca93a',
      secundaryColor: '#000'
    }
  }

  useEffect(() => {
    if (trackingCode) {
      apiService
        .tracking(trackingCode)
        .then((res) => {
          setOrder(res);
        }).catch((error) => {
          alert(error.message);
        });
    }
  }, [trackingCode]);

  const checkInProgress = (id) => {
    return order.status_transition[id].in_progress;
  }

  const checkCompleted = (id) => {
    return order.status_transition[id].completed;
  }

  const getOccurredAt = (code) => {
    var item = order.tracker.details.filter((res) => res.code == code);

    if (!item.length) {
      return null;
    }

    return item[item.length - 1].occurred_at;
  }

  const getOccurredAtByStatus = (status) => {
    var item = order.tracker.details.filter((res) => res.status == status);

    if (!item.length) {
      return null;
    }

    return item[item.length - 1].occurred_at;
  }

  const hasDetails = () => {
    return order.tracker.details.filter((res) => res.status > 2).length;
  }

  return (
    <Page
      className={classes.root}
      title="Rastrear pedido"
    >
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 0, marginBottom: 60, background: '#f5f6f8' }}>
        <img src={companies[company].logo} style={{ width: 150, margin: '0 auto' }} />
      </div>

      <TrackingCustomView found={trackingCode ? true : false} setOrders={setOrders} />

      {orders.length > 0 ? <Box style={{ margin: '30px auto', maxWidth: '800px' }}>
        <Card>
          <CardHeader title="Pedidos encontrados" />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Table>
                  <TableBody>
                    {orders.map((order) => <OrderRow primaryColor={companies[company].primaryColor} onClick={() => setOrders([])} noStatus order={order} fullWidth newUrl={`/tracking/c/${company}/${order.tracker.tracking_code}`} hidePrice hideOrigin />)}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>: null}

      {orders.length ? null : <Box style={{ margin: '40px auto', maxWidth: '800px' }}>
        {order && <Grid container >
          <Grid item xs={12}>

            {hasDetails() ? <Grid container spacing={1} style={{ marginBottom: 30 }} className="container-status">
              <Grid item xs={3} className={classes.trackerHeaderStatus}>
                <Package style={{ marginBottom: 10 }} className={checkCompleted(0) ? classes.iconCompleted : classes.iconNormal} />
                <strong style={{ marginBottom: 10 }} className={checkCompleted(0) ? classes.iconCompleted : classes.iconNormal}>Coletado</strong>
                <date style={{
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#242424'
                }}>{getOccurredAt(202) ? moment(getOccurredAt(202)).format('DD/MM/YYYY HH:mm:ss') : '-'}</date>

                {checkInProgress(0)
                  ? <FlixlogProgress id="status-1" style={{ width: '100%' }} />
                  : <div id="status-1" className={checkCompleted(0) ? classes.loadBarCompleted : classes.loadBar}></div>}

              </Grid>

              <Grid item xs={3} className={classes.trackerHeaderStatus}>
                <Truck style={{ marginBottom: 10 }} className={checkCompleted(1) ? classes.iconCompleted : classes.iconNormal} />
                <strong style={{ marginBottom: 10 }} className={checkCompleted(1) ? classes.iconCompleted : classes.iconNormal} >Em Trânsito</strong>
                <date style={{
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#242424'
                }}>{getOccurredAt('') ? moment(getOccurredAt('')).format('DD/MM/YYYY HH:mm:ss') : '-'}</date>

                {checkInProgress(1)
                  ? <FlixlogProgress id="status-2" style={{ width: '100%' }} />
                  : <div id="status-2" className={checkCompleted(1) ? classes.loadBarCompleted : classes.loadBar}></div>}
              </Grid>

              <Grid item xs={3} className={classes.trackerHeaderStatus}>
                <Calendar style={{ marginBottom: 10 }} className={checkCompleted(2) ? classes.iconCompleted : classes.iconNormal} />
                <strong style={{ marginBottom: 10 }} className={checkCompleted(2) ? classes.iconCompleted : classes.iconNormal}>Saiu para entrega</strong>
                <date style={{
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#242424'
                }}>{getOccurredAtByStatus(4) ? moment(getOccurredAtByStatus(4)).format('DD/MM/YYYY HH:mm:ss') : '-'}</date>

                {checkInProgress(2)
                  ? <FlixlogProgress id="status-3" style={{ width: '100%' }} />
                  : <div id="status-3" className={checkCompleted(2) ? classes.loadBarCompleted : classes.loadBar}></div>}

              </Grid>

              <Grid item xs={3} className={classes.trackerHeaderStatus}>
                <CheckCircle style={{ marginBottom: 10 }} className={checkCompleted(3) ? classes.iconCompleted : classes.iconNormal} />
                <strong style={{ marginBottom: 10 }} className={checkCompleted(3) ? classes.iconCompleted : classes.iconNormal}>Entregue</strong>
                <date style={{
                  marginBottom: 10,
                  fontSize: 12,
                  color: '#242424'
                }}>{order.delivered_at ? moment(order.delivered_at).format('DD/MM/YYYY HH:mm:ss') : '-'}</date>

                {checkInProgress(3)
                  ? <FlixlogProgress id="status-4" style={{ width: '100%' }} />
                  : <div id="status-4" className={checkCompleted(3) ? classes.loadBarCompleted : classes.loadBar}></div>}

              </Grid>

            </Grid> : null}



            <Grid container style={{ marginBottom: 20 }}>
              <Grid item xs={12}>
                <Card style={{ backgroundColor: companies[company].secundaryColor, padding: 17 }}>
                  <Typography color="textPrimary" variant="h4" style={{ fontSize: 15, color: companies[company].primaryColor }}>Pedido {order.company.name} #{order.reference} {order?.delivery?.delivery_date ? ` - Previsão de entrega ${moment(order.delivery.delivery_date).format('DD/MM/YYYY')}` : null}</Typography>
                </Card>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6} style={{ marginBottom: 20 }}>
                <Card>
                  <CardHeader title="QUEM ESTÁ TRANSPORTANDO" />
                  <CardContent>
                    <p className={classes.content}>
                      <strong>{order.carrier.name}</strong> <br />
                      <strong>Telefone:</strong> <a href={`tel:+55` + order.carrier.phone}>{order.carrier.phone}</a> <br />
                      <br />
                      <br />
                    </p>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card>
                  <CardHeader title="DESTINO" />

                  <CardContent>
                    <p className={classes.content}>
                      <strong>CEP:</strong> {order.to_address.zip} <br />
                      <strong>Endereço:</strong> {order.to_address.street1}, {order.to_address.number}, {order.to_address.street2} <br />
                      <strong>Bairro:</strong> {order.to_address.neighborhood} <br />
                      <strong>Cidade/UF:</strong> {order.to_address.city}/{order.to_address.state} <br />
                    </p>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card>
                  <CardHeader title="Ocorrências" />
                  <CardContent>
                    {hasDetails() ? <ul class="timeline">
                      {order.tracker ? order.tracker.details
                        .filter((detail) => {
                          return detail.code !== '200' && detail.code !== '201';
                        })
                        .map((detail) => {
                          return (<li class={"status-" + detail.status}>
                            <span>
                              {detail.description ? (<span>{detail.description}<br /></span>) : null}
                              <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>{detail.message}</small>
                            </span>
                            <span class="float-right">{moment(detail.occurred_at).format('DD/MM/YYYY HH')}h</span>
                          </li>);
                        }) : null}

                      {order.tracker && order.tracker.details && order.tracker.details.length == 0 ?
                        <li class="status-99">
                          <span>
                            <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>Nenhuma ocorrência registrada até o momento.</small>
                          </span>
                          <span class="float-right">{moment().format('DD/MM/YYYY HH')}h</span>
                        </li>
                        : null}

                    </ul> : <strong>Este pedido ainda não foi coletado.</strong>}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
      </Box>}
    </Page >
  );
};

export default TrackingLinkCustomView;
