import React, { useEffect, useState } from 'react';
import Form from "@rjsf/material-ui";
import { JsonToTable } from "react-json-to-table";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Drawer,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Check, CheckCircle, Eye, Trash } from 'react-feather';
import { Link } from 'react-router-dom';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));


const downloadFile = (data, fileName, type = "text/plain") => {
  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(
    new Blob([data], { type })
  );

  // Use download attribute to set set desired file name
  a.setAttribute("download", fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

const QuotationLog = () => {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyChannels, setCompanyChannels] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [form, setForm] = useState(null);
  const apiService = new ApiService();


  const refresh = () => {
    setLoading(true);
    apiService
      .getQuotationLog()
      .then((res) => {
        setLogs(res.data);
        setLoading(false);
      });
  }

  const exportCsv = () => {
    apiService
      .generateReport('quotations')
      .then((res) => {
        downloadFile(res, `report-quotations-${(new Date()).getTime()}.csv`, "application/csv");
      }).catch(() => {
      });
  }

  const showMore = () => {
    const newLastPage = lastPage + 1;
    setLoading(true);
    apiService
      .getQuotationLog(10, newLastPage)
      .then((res) => {
        setLogs(logs.concat(res.data));
        setLoading(false);
        setLastPage(newLastPage);
      });
  }

  useEffect(() => {
    refresh();
  }, []);

  const channelIconsUrl = (channel) => {
    var urls = {
      3: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/madeiramadeira.png',
      6: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/tiny-logo.png',
      7: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/bling-logo.png',
      8: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/logo-tray.png',
      9: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/skyhub.png',
      10: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/magalu-logo.jpg',
      11: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/viavarejo.png',
      12: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/woo-logo.jpeg',
      13: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      14: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/omie-logo.png',
      15: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/simplo7-logo.png',
      16: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/leroy-logo.png',
      29: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/nuvemshop-logo2.png',
      30: 'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png',
      31: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/anymarket-logo.png',
      32: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/colombo-logo.jpeg',
    }
    return urls[channel] || null;
  }


  return (
    <Page
      className={classes.root}
      title="Histórico de Cotações"
    >
      <Container maxWidth={false}>
        <Toolbar title="Histórico de Cotações" 
            onClickRefresh={() => refresh()} 
            onClickDownload={() => exportCsv()}
            isRefreshing={loading}
            />
        <Box mt={3}>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    #
                  </TableCell>
                  <TableCell>
                    Canal
                  </TableCell>
                  <TableCell>
                    Origem
                  </TableCell>
                  <TableCell>
                    CEP Destino
                  </TableCell>
                  <TableCell>
                    SKUs
                  </TableCell>
                  <TableCell>
                    Resposta
                  </TableCell>
                  <TableCell>
                    Status
                  </TableCell>
                  <TableCell>
                    Data e Hora
                  </TableCell>
                </TableRow>
              </TableHead>
              {logs.length ? (
                <TableBody>
                  {logs.map((log) => (
                    <TableRow
                      hover
                      key={log.id}
                    >
                      <TableCell>
                        {log.id}
                      </TableCell>
                      <TableCell>
                        <Box
                          alignItems="center"
                          display="flex"
                        >

                          <pre style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
                            {channelIconsUrl(log.channel_id) ? <img style={{ width: 30, height: 30, objectFit: 'cover' }} src={channelIconsUrl(log.channel_id)} /> : <strong>{log.channel}</strong>}
                          </pre>

                        </Box>
                      </TableCell>
                      <TableCell>
                        <strong>{log.parameters.zip_from ? log.parameters.zip_from : log.parameters.origin}</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{log.parameters.zip_to}</strong>
                      </TableCell>
                      <TableCell>
                        {log.parameters.parcels.map((res, key) => <>
                            <strong>SKU:</strong>  {res.reference}<br/>
                            <strong>Quantidade:</strong>  {res.quantity}<br/>
                            <strong>Preço:</strong>  {res.cargo_value ? res.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null} <br/>
                            <strong>Dimensões (A L C):</strong>  ({res.height ? (res.height*100).toLocaleString('pt-BR') : '0'}cm x {res.width ? (res.width*100).toLocaleString('pt-BR') : '0'}cm x {res.length ? (res.length*100).toLocaleString('pt-BR') : '0'}cm) <br/>
                            <strong>Peso:</strong>  {res.weight ? res.weight.toLocaleString('pt-BR') : null} <br/>
                            {log.parameters.parcels.length > 1 ? <> <br/> <hr /> <br/></> : null}
                        </>)}

                      </TableCell>
                      <TableCell>
                      
                        {log.response.map((res) => <>
                            <strong>Transportadora:</strong>  {res.carrier} <br/>
                            <strong>Valor do Frete:</strong>  {res.estimated_cost.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <br/>
                            <strong>Valor da Carga:</strong>  {res.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} <br/>
                            <strong>Peso Cubado:</strong>  {res.cubed_weight.toLocaleString('pt-BR')} <br/>
                            <strong>Prazo:</strong>  {res.delivery_days} dias úteis<br/>

                            {log.response.length > 1 ? <> <br/> <hr />
                            <br/></> : null}
                        </>)}
                      </TableCell>
                      <TableCell>
                        <CheckCircle color="green" title="OK" />
                      </TableCell>
                      <TableCell>
                        {moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow

                  >
                    <TableCell colSpan={8}>
                      Nenhuma cotação realizada ainda.
                    </TableCell>

                  </TableRow>
                </TableBody>
              )
              }
            </Table>

            <center>
              <Button onClick={showMore} variant="primary" disabled={loading}>MOSTRAR MAIS</Button>
            </center>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default QuotationLog;
