import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ConfirmEmailView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const params = useParams();
  const token = params.token;

  useEffect(() => {
    apiService
      .confirmEmail({
        token
      })
      .then(() => {
        enqueueSnackbar('E-mail confirmado com sucesso!', {
          variant: 'success',
        });
        navigate('/login');
      }).catch((res) => {
        enqueueSnackbar(`Ocorreu um erro, tente novamente: ${res.message}`, {
          variant: 'error',
        });
      });
  }, [token]);

  return (
    <Page
      className={classes.root}
      title="Confirmando..."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              token
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Aguarde, confirmando email ...
                    </Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.token && errors.token)}
                    fullWidth
                    helperText={touched.token && errors.token}
                    label="Token"
                    margin="normal"
                    name="token"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="hidden"
                    value={values.token}
                    variant="outlined"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      CONFIRMANDO E-MAIL
                    </Button>
                  </Box>
                </form>
              )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(ConfirmEmailView);
