import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { login, setActualCompany } from 'src/common/auth';
import { withSnackbar } from 'notistack';

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const redirect = getQueryVariable('redirect');
  const sid = getQueryVariable('sid');

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Digite um e-mail válido').max(255).required('Email é obrigatório'),
              password: Yup.string().max(255).required('Senha é obrigatória')
            })}
            onSubmit={(values) => {
              const apiService = new ApiService();
              return apiService
                .auth(values.email, values.password, {sid: sid})
                .then((res) => {
                  login(res.token);
                  setActualCompany(res.company);
                  if (redirect) {
                    window.location.href = atob(redirect);
                  } else {
                    if (res.redirect) {
                      window.location.href = res.redirect;
                    } else {
                      window.location.href = "/dashboard"
                    }
                  }
                })
                .catch((res) => {
                  enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                    variant: 'error'
                  });
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Entrar
                  </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Utilize o e-mail e senha liberados pelo time da Flixlog.
                  </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Senha"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ENTRAR AGORA
                  </Button>
                  </Box>


                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    <Link
                      component={RouterLink}
                      to="/reset-password"
                      variant="h6"
                    >
                      Esqueceu sua senha?
                  </Link>
                  </Typography>
 
                </form>
              )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(LoginView);
