import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { JsonToTable } from "react-json-to-table";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { getOptions, isNumber } from 'highcharts';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const KpiByCarrier = ({ className, data, metrics, ...rest }) => {
  const classes = useStyles();
  const [table, setTable] = useState(null);

  useEffect(() => {
setTable(<JsonToTable json={Object.values(data)}></JsonToTable>)
  }, [data])
  
  const options =  {

    chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1
    },


    title: {
        text: 'KPIs por transportadora',
        style: {
            fontSize: '1em'
        }
    },

    tooltip: {
      enabled: false
    },
    

    xAxis: {
        categories: metrics.filter((metric) => metric !== 'Transportadora')
    },

    yAxis: {
        categories: Object.keys(data).map((dt) => dt.toUpperCase()),
        title: null,
        reversed: true
    },

   

    colorAxis: {
        min: 0,
        minColor: '#FFFFFF',
        maxColor: Highcharts.getOptions().colors[0]
    },

    legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280
    },


    series: [{
        borderWidth: 1,
        data: Object.values(data).map((metrics, coluna) => Object.values(metrics).filter((metric) => isNumber(metric)).map((metric, linha) => [linha, coluna, metric] )).flatMap((x) => x),
        dataLabels: {
            enabled: true,
            color: '#000000'
        }
    }],
    credits: {
      enabled: false
    }
  };

 

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="KPIs por transportadora"
      />
      <Divider />
      <CardContent>

      
      <ul style={{listStyle: 'none', fontSize: 12}}>
        {[
        {'Métrica': 'EDP', 'Descrição': 'Entregas dentro do prazo'},
        {'Métrica': 'EFP', 'Descrição': 'Entregas fora do prazo'},
        {'Métrica': 'PA', 'Descrição': 'Pedidos atrasados'},
        {'Métrica': 'TA', 'Descrição': 'Total de avaliações'},
        {'Métrica': 'MA', 'Descrição': 'Média de avaliações'},
        {'Métrica': 'AVA', 'Descrição': 'Total de avarias identificadas'},
        {'Métrica': 'EXT', 'Descrição': 'Total de extravios identificados'},

      ].map((d) => <li><strong>{d['Métrica']}</strong>: {d['Descrição']} </li>)}
        </ul>
 

        <Box
           position="relative"
        >
  
          <HighchartsReact
            options={options}
            highcharts={Highcharts}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
            containerProps={{ className: 'chartContainer' }}
    />
     
        </Box>
        
        {table}
      </CardContent>
    </Card>
  );
};

KpiByCarrier.propTypes = {
  className: PropTypes.string
};

export default KpiByCarrier;
