import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ContingencyTableView = () => {
  const classes = useStyles();
  const [backgroundTasks, setBackgroundTasks] = useState([]);

  const reloadList = (filters) => {
    const apiService = new ApiService();
    apiService
      .getBackgroundTasks(filters)
      .then((res) => {
        setBackgroundTasks(res.data);
      });
  };

  useEffect(() => {
    reloadList({
      task_type: 'GenerateContingencyFreightTable'
    });
  }, []);

  return (
    <Page
      className={classes.root}
      title="Solicitação de tabelas de contingência"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results backgroundTasks={backgroundTasks} />
        </Box>
      </Container>
    </Page>
  );
};

export default ContingencyTableView;
