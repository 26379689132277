import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputMask from "react-input-mask";
import axios from 'axios';
import * as _ from 'underscore';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FlashOffRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const FormRegister = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [users, setUsers] = useState([{ id: 1, name: '', email: '', role: 'admin' }, { id: 2, name: '', email: '', role: 'financeiro' }]);
  const [cd, setCd] = useState({});
  const [loading, setLoading] = useState(false);
  const [error_message, setError] = useState(null);
  const [initValues, setInitValues] = useState({
    address_name: '',
    address_federal_tax_id: '',
    address_phone: '',
    address_email: '',
    address_zip: '',
    address_street1: '',
    address_number: '',
    address_street2: '',
    address_neighborhood: '',
    address_city: '',
    address_state: '',
    address_country: 'BRA',
  });
  const [erp, setErp] = React.useState(null);
  const [ecommerce, setEcommerce] = React.useState(null);
  const [marketplace, setMarketplace] = React.useState([]);
  const [valuex, setValuex] = React.useState();
  const [success, setSuccess] = React.useState(false);

  const [company, setCompany] = useState(null);

  const [step, setStep] = useState(1);

  const handleUser = (id, key, value) => {
    setUsers(
      users.map((user) =>
        user.id === id
          ? { ...user, [key]: value }
          : { ...user }
      )
    );
  };

  const handleMarketplace = (item) => {
    var array = [...marketplace];
    var index = array.indexOf(item)
    if (index !== -1) {
      array.splice(index, 1);
      setMarketplace(array);
    } else {
      setMarketplace([...marketplace, item]);
    }

    console.log(marketplace);
  }

  const cardStyle = (active) => {
    var style = {
      width: '100px',
      float: 'left',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: 10,
      background: '#fff',
      borderRadius: 10,
      marginRight: 10,
      boxShadow: '0 0 7px #ddd',
      cursor: 'pointer',
      marginBottom: 25
    };

    if (active) {
      style.outline = '5px solid #ffcc00';
    }

    return style;
  }

  const handleStep = (newStep, values) => {
    console.log(newStep);
    const requireds = {
      1: ['company_federal_tax_id', 'address_name', 'company_name', 'company_state_tax_id'],
      3: ['address_zip', 'address_street1', 'address_number', 'address_neighborhood', 'address_city', 'address_state', 'confirm_cd'],
      4: ['monthly_expense_with_shipping', 'monthly_shipping'],
      5: [],
    }

    if (newStep == 3) {
      users.forEach((user) => {
        if (!user.email) {
          alert('Preencha os campos obrigatórios');
        } else {
          setStep(newStep);
        }
      });
      return;
    }

    const previows = newStep - 1;
    const fields = requireds[previows].filter((item) => !values[item]);


    if (fields.length) {
      alert('Preencha os campos obrigatórios');
    } else {
      setStep(newStep);
    }
    console.log(fields);

    return
  }

  const handleCnpj = (cnpj, values) => {
    const fti = cnpj.replace(/[^\d]/g, "");
    const apiService = new ApiService;

    if (fti.length === 14) {
      setLoading(true);
      apiService
        .cnpjCard(fti)
        .then((res) => {
          setLoading(false);

          setCompany(res);

          var cp = res;


          values['company_name'] = cp.fantasia;
          values['address_name'] = cp.nome;
          values['address_zip'] = cp.cep.replace(/[^\d]/g, "");
          values['address_phone'] = cp.telefone ? cp.telefone.replace(/[^\d]/g, "") : '';
          values['address_number'] = cp.numero;
          values['address_street1'] = cp.logradouro;
          values['address_street2'] = cp.complemento;
          values['address_city'] = cp.municipio;
          values['address_neighborhood'] = cp.bairro;
          values['address_state'] = cp.uf;
          values['company_federal_tax_id'] = fti;
          values['address_federal_tax_id'] = fti;


          setInitValues({ ...initValues, values });

          setLoading(false);

        });
    }
  }
  
  const handleCep = (cep, values) => {
    const zip = cep.replace('.', '').replace('-', '').replace('_', '');

    if (zip.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${zip}/json/`)
        .then((res) => {
          const { logradouro, complemento, bairro, localidade, uf } = res.data;

          values['address_street1'] = logradouro;
          values['address_street2'] = '';
          values['address_city'] = localidade;
          values['address_neighborhood'] = bairro;
          values['address_state'] = uf;
          values['address_number'] = '';

          setInitValues({ ...initValues, values });

        });
    }
  };

  const channelsMarketplace = () => {
    var urls = {
      madeiramadeira: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/madeiramadeira.png',
      b2w: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/skyhub.png',
      magalu: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/magalu-logo.jpg',
      viavarejo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/viavarejo.png',
      leroy: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/leroy-logo.png',
      mercadolivre: 'https://http2.mlstatic.com/frontend-assets/ui-navigation/5.15.0/mercadolibre/logo-pt__large_plus@2x.png',
    }
    return urls;
  }

  const channelsEcommerce = () => {
    var urls = {
      tray: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/logo-tray.png',
      woo: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/woo-logo.jpeg',
      'tray-corp': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      'fbitsws': 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/traycorp-logo.svg',
      simplo7: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/simplo7-logo.png',
      nuvemshop: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/nuvemshop-logo2.png',
    }
    return urls;
  }

  const channelsERP = () => {
    var urls = {
      tiny: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/tiny-logo.png',
      bling: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/bling-logo.png',
      omie: 'https://flix-files.s3.us-east-2.amazonaws.com/flixlog/channels/omie-logo.png',
    }
    return urls;
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={(values) => {
        const apiService = new ApiService();

        apiService
          .registerCompany({
            users: users,
            company: {
              name: values.company_name,
              state_tax_id: values.company_state_tax_id,
              federal_tax_id: values.company_federal_tax_id,
            },
            integrations: {
              erp: values.no_erp ? false : erp,
              marketplace: values.no_marketplace ? false : marketplace,
              ecommerce: values.no_ecommerce ? false : ecommerce,
              integrator: values.integrator
            },
            expenses: {
              monthly_expense_with_shipping: values.monthly_expense_with_shipping,
              monthly_shipping: values.monthly_shipping
            },
            cd: {
              reference: 'CD1',
              name: 'CD ' + values.company_name,
              address: {
                zip: values.address_zip,
                city: values.address_city,
                country: values.address_country,
                email: users[0].email,
                federal_tax_id: values.address_federal_tax_id,
                name: values.address_name,
                neighborhood: values.address_neighborhood,
                number: values.address_number,
                phone: values.address_phone,
                state: values.address_state,
                street1: values.address_street1,
                street2: values.address_street2
              }
            }
          }).then(() => {
            setSuccess(true);
            setError(null);
          })
          .cath((ev) => {
            setSuccess(FlashOffRounded);
            setError(ev.data.message);
          });

      }}
      validationSchema={Yup.object().shape({
        company_state_tax_id: Yup.number().required('Digite a inscrição estadual.'),
        company_name: Yup.string().required('Digite o nome da empresa'),
        company_federal_tax_id: Yup.string().required('Digite o CNPJ'),
        address_name: Yup.string().max(150, 'Deve ter no máximo 150 caracteres').required('Digite o nome ou razão social'),
        address_federal_tax_id: Yup.string('Apenas números').required('Digite o CNPJ ou CPF, apenas números.'),
        address_zip: Yup.string('Apenas números').required('Digite um CEP válido.'),
        address_street1: Yup.string().required('Digite o endereço.'),
        address_number: Yup.string().required('Digite o número.'),
        address_neighborhood: Yup.string().required('Digite o bairro.'),
        address_city: Yup.string().required('Digite a cidade.'),
        address_state: Yup.string().required('Selecione o estado.')
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Criar uma nova conta
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Digite abaixo os dados para criar a sua conta. Qualquer dúvida, você pode tirar elas com seu consultor comercial.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold' }}>
              DADOS DA EMPRESA

            </Typography>

          </Box>

          <Grid container spacing={2}>
            <Grid item lg={6}>
              <InputMask
                mask={'99.999.999/9999-99'}
                onBlur={handleBlur}
                onChange={handleCnpj}
                onChange={(ev) => {
                  // handleChange(ev);
                  handleCnpj(ev.target.value, values);
                }}
                error={Boolean(touched.company_federal_tax_id && errors.company_federal_tax_id)}
                helperText={touched.company_federal_tax_id && errors.company_federal_tax_id}
                value={values.company_federal_tax_id}
                name="company_federal_tax_id"
                required
              >
                {() => (
                  <TextField
                    label="CNPJ"
                    margin="normal"
                    fullWidth
                    required
                    name="company_federal_tax_id"
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.address_name && errors.address_name)}
                fullWidth
                helperText={touched.address_name && errors.address_name}
                label="Razão Social"
                margin="normal"
                name="address_name"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.address_name}
                variant="outlined"
              />

            </Grid>
            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.company_name && errors.company_name)}
                fullWidth
                helperText={touched.company_name && errors.company_name}
                label="Nome Fantasia"
                margin="normal"
                name="company_name"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.company_name}
                variant="outlined"
              />

            </Grid>

            <Grid item lg={6}>
              <TextField
                error={Boolean(touched.company_state_tax_id && errors.company_state_tax_id)}
                fullWidth
                helperText={touched.company_state_tax_id && errors.company_state_tax_id}
                label="Inscrição Estadual"
                margin="normal"
                type="number"
                name="company_state_tax_id"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                value={values.company_state_tax_id}
                variant="outlined"
              />
            </Grid>
          </Grid>

          {step > 1 ? <Divider style={{ marginTop: 30, marginBottom: 30 }} /> : null}

          {step > 1 ? <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold', marginBottom: 30 }}>
              USUÁRIOS
            </Typography>
          </Box> : null}


          {step > 1 && users.map((user, index) => <Grid key={index} container spacing={2}>
            {user.role == 'admin' ? <Grid item lg={12} style={{ marginBottom: '-18px' }}>
              <span style={{ fontWeight: 'bold' }}>Administrador</span> <br />
              <small>Acesso completo à conta.</small>
            </Grid> : <Grid item lg={12} style={{ marginBottom: '-18px' }}>
              <span style={{ fontWeight: 'bold' }}>Financeiro</span> <br />
              <small>E-mail do responsável pelo financeiro para envio das faturas de frete e mensalidade.</small>
            </Grid>}
            <Grid item lg={4}>
              <TextField
                fullWidth
                label="Nome"
                margin="normal"
                name="user_name"
                value={user.name}
                required
                onChange={(ev) => handleUser(user.id, 'name', ev.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={8}>
              <TextField
                fullWidth
                label="E-mail"
                margin="normal"
                name="user_email"
                required
                type="email"
                onChange={(ev) => handleUser(user.id, 'email', ev.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>)}

          {step > 2 ? <Divider style={{ marginTop: 30, marginBottom: 30 }} /> : null}

          {step > 2 ? <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold', marginBottom: 30 }}>
              CENTRO DE DISTRIBUIÇÃO
              <br /><br />
              <small style={{ fontWeight: 'normal', color: '#000', letterSpacing: 'normal', fontSize: 13 }}>Local de onde sairão os produtos. Esse local também será usado como base para a origem das tabelas de frete.</small>
            </Typography>
          </Box> : null}

          {step > 2 ? <Grid container>
            <Grid item xs={12} lg={5}>
			  
            <InputMask
              mask="99.999-999"
              error={Boolean(touched.address_zip && errors.address_zip)}
              helperText={touched.address_zip && errors.address_zip}
              name="address_zip"
              value={values.address_zip}
              onBlur={(ev) => {
                handleBlur(ev);
                handleCep(ev.target.value, values);
              }}
              onChange={(ev) => {
                handleChange(ev);
                handleCep(ev.target.value, values);
              }}
            >
              {() => (
                <TextField
                  label="CEP"
                  margin="normal"
                  name="address_zip"
                  variant="outlined"
                />
              )}
            </InputMask>
			  
			  
              
            </Grid>
          </Grid> : null}
          {step > 2 ? <Grid container spacing={2}>
            <Grid item xs={12} lg={7}>
              <TextField
                error={Boolean(touched.address_street1 && errors.address_street1)}
                helperText={touched.address_street1 && errors.address_street1}
                label="Endereço"
                margin="normal"
                fullWidth
                name="address_street1"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={cd.address_street1 ? cd.address_street1 : values.address_street1}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} lg={5}>
              <TextField
                error={Boolean(touched.address_number && errors.address_number)}
                helperText={touched.address_number && errors.address_number}
                label="Número"
                margin="normal"
                name="address_number"
                onBlur={handleBlur}
                fullWidth
                required
                onChange={handleChange}
                value={values.address_number}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={7}>
              <TextField
                error={Boolean(touched.address_street2 && errors.address_street2)}
                helperText={touched.address_street2 && errors.address_street2}
                label="Complemento"
                margin="normal"
                fullWidth
                name="address_street2"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_street2}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={5}>
              <TextField
                error={Boolean(touched.address_neighborhood && errors.address_neighborhood)}
                helperText={touched.address_neighborhood && errors.address_neighborhood}
                label="Bairro"
                margin="normal"
                name="address_neighborhood"
                onBlur={handleBlur}
                required
                onChange={handleChange}
                fullWidth
                value={values.address_neighborhood}
                variant="outlined"
              />
            </Grid>

            <Grid item lg={4}>
              <TextField
                error={Boolean(touched.address_city && errors.address_city)}
                helperText={touched.address_city && errors.address_city}
                label="Cidade"
                margin="normal"
                name="address_city"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_city}
                fullWidth
                required
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4}>
              <FormControl className={classes.formControl} variant="outlined" margin="normal" fullWidth>
                <InputLabel margin="normal">Estado</InputLabel>
                <Select
                  error={Boolean(touched.address_state && errors.address_state)}
                  helperText={touched.address_state && errors.address_state}
                  label="Estado"
                  margin="normal"
                  name="address_state"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  required
                  value={values.address_state}
                  variant="outlined"
                >
                  <MenuItem value="AC">AC</MenuItem>
                  <MenuItem value="AL">AL</MenuItem>
                  <MenuItem value="AP">AP</MenuItem>
                  <MenuItem value="AM">AM</MenuItem>
                  <MenuItem value="BA">BA</MenuItem>
                  <MenuItem value="CE">CE</MenuItem>
                  <MenuItem value="DF">DF</MenuItem>
                  <MenuItem value="ES">ES</MenuItem>
                  <MenuItem value="GO">GO</MenuItem>
                  <MenuItem value="MA">MA</MenuItem>
                  <MenuItem value="MT">MT</MenuItem>
                  <MenuItem value="MS">MS</MenuItem>
                  <MenuItem value="MG">MG</MenuItem>
                  <MenuItem value="PA">PA</MenuItem>
                  <MenuItem value="PB">PB</MenuItem>
                  <MenuItem value="PR">PR</MenuItem>
                  <MenuItem value="PE">PE</MenuItem>
                  <MenuItem value="PI">PI</MenuItem>
                  <MenuItem value="RJ">RJ</MenuItem>
                  <MenuItem value="RN">RN</MenuItem>
                  <MenuItem value="RS">RS</MenuItem>
                  <MenuItem value="RO">RO</MenuItem>
                  <MenuItem value="RR">RR</MenuItem>
                  <MenuItem value="SC">SC</MenuItem>
                  <MenuItem value="SP">SP</MenuItem>
                  <MenuItem value="SE">SE</MenuItem>
                  <MenuItem value="TO">TO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12}>
            <FormControlLabel
                control={
                  <Checkbox
                    name="confirm_cd"
                    checked={values.confirm_cd}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                    required
                  />
                }
                label="Confirmo que os meus produtos saem deste endereço."
              />
              <small style={{display: 'block', color: '#607d8b'}}>
                Caso tenha mais de um CD, não se preocupe, você poderá cadastrar outros posteriormente.
              </small>
            </Grid>
          </Grid> : null}


          {step > 3 ? <Divider style={{ marginTop: 30, marginBottom: 30 }} /> : null}

          {step > 3 ? <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold', marginBottom: 30 }}>
              ESTIMATIVAS
              <br /><br />
              <small style={{ fontWeight: 'normal', color: '#000', letterSpacing: 'normal', fontSize: 13 }}>
                Informe os valores da sua operação hoje, ou uma estimativa para os próximos 2 meses.
              </small>
            </Typography>
          </Box> : null}

          {step > 3 ? <Grid container>
            <Grid item xs={12} lg={6}>
              <CurrencyTextField
                error={Boolean(touched.monthly_expense_with_shipping && errors.monthly_expense_with_shipping)}
                helperText={touched.monthly_expense_with_shipping && errors.monthly_expense_with_shipping}
                label="Gasto mensal com frete (R$)"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name="monthly_expense_with_shipping"
                onBlur={handleBlur}

                onChange={(event, value) => {
                  handleChange(event);
                  setValuex(value);
                }}
                value={valuex}

                // onChange={handleChange}
                // value={values.monthly_expense_with_shipping}
                variant="outlined"

                currencySymbol="R$"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <TextField
                error={Boolean(touched.monthly_shipping && errors.monthly_shipping)}
                helperText={touched.monthly_shipping && errors.monthly_shipping}
                label="Envios mensais (Pedidos por transportadora)"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                name="monthly_shipping"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.monthly_shipping}
                variant="outlined"

              />
            </Grid>
          </Grid> : null}


          {step > 4 ? <Divider style={{ marginTop: 30, marginBottom: 30 }} /> : null}

          {step > 4 ? <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold', marginBottom: 30 }}>
              CANAIS DE VENDA
              <br /><br />
              <small style={{ fontWeight: 'normal', color: '#000', letterSpacing: 'normal', fontSize: 13 }}>
                Quais canais de Marketplace ou e-commerce são utilizados para venda na sua operação.
              </small>
            </Typography>
          </Box> : null}

          {step > 4 ? <Grid container>

            <Grid item xs={12} lg={12}>
              <Card>
                <CardHeader title="Plataforma de ERP"></CardHeader>
                <CardContent>

                  <FormControlLabel
                    style={{ marginBottom: 30 }}
                    control={
                      <Checkbox
                        name="no_erp"
                        checked={values.no_erp}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Não tenho ERP"
                  />

                  {values.no_erp ? null : <ul>
                    {Object.keys(channelsERP()).map((item) => <li
                      style={erp === item ? cardStyle(true) : cardStyle(false)}
                      onClick={() => setErp(item)}>
                      <img style={{ width: '100%', height: 40, objectFit: 'cover' }} src={channelsERP()[item]} />
                      <small><strong style={{ fontSize: 10 }}>{item.toUpperCase()}</strong></small>
                    </li>)}

                    <li style={{
                      width: '100px',
                      float: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      padding: 10,
                      background: '#fff',
                      marginTop: '-19px',
                      marginBottom: 25
                    }}>
                      <TextField
                        error={Boolean(touched.erp && errors.erp)}
                        helperText={touched.erp && errors.erp}
                        label="Outro ERP"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="erp"
                        onChange={(ev) => setErp(ev.target.value)}
                        // value={erp}
                        variant="outlined"

                      />
                    </li>
                  </ul>}
                </CardContent>

              </Card>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Card>
                <CardHeader title="Plataforma de E-commerce"></CardHeader>
                <CardContent>

                  <FormControlLabel
                    style={{ marginBottom: 30 }}
                    control={
                      <Checkbox
                        name="no_ecommerce"
                        checked={values.no_ecommerce}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Não tenho plataforma de e-commerce"
                  />

                  {values.no_ecommerce ? null : <ul>
                    {Object.keys(channelsEcommerce()).map((item) => <li
                      style={ecommerce === item ? cardStyle(true) : cardStyle(false)}
                      onClick={() => setEcommerce(item)}>
                      <img style={{ width: '100%', height: 40, objectFit: 'contain' }} src={channelsEcommerce()[item]} />
                      <small><strong style={{ fontSize: 10 }}>{item.toUpperCase()}</strong></small>
                    </li>)}

                    <li style={{
                      width: '100px',
                      float: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      padding: 10,
                      background: '#fff',
                      marginTop: '-19px',
                      marginBottom: 25
                    }}>
                      <TextField
                        error={Boolean(touched.ecommerce && errors.ecommerce)}
                        helperText={touched.ecommerce && errors.ecommerce}
                        label="Outra plataforma"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="ecommerce"
                        onChange={(ev) => setEcommerce(ev.target.value)}
                        // value={erp}
                        variant="outlined"

                      />
                    </li>
                  </ul>}
                </CardContent>

              </Card>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Card>
                <CardHeader
                  title="Plataforma de Marketplace"
                  subheader="Selecione uma ou mais, caso falte alguma, adicione na caixinha 'Outros Canais'"
                ></CardHeader>
                <CardContent>

                  <FormControlLabel
                    style={{ marginBottom: 30 }}
                    control={
                      <Checkbox
                        name="no_marketplace"
                        checked={values.no_marketplace}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label="Não vendo em Marketplaces"
                  />

                  {values.no_marketplace ? null : <ul>
                    {Object.keys(channelsMarketplace()).map((item) => <li
                      style={marketplace.indexOf(item) !== -1 ? cardStyle(true) : cardStyle(false)}
                      onClick={() => handleMarketplace(item)}>
                      <img style={{ width: '100%', height: 40, objectFit: 'contain' }} src={channelsMarketplace()[item]} />
                      <small><strong style={{ fontSize: 10 }}>{item.toUpperCase()}</strong></small>
                    </li>)}

                    <li style={{
                      width: '200px',
                      float: 'left',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      padding: 10,
                      background: '#fff',
                      marginTop: '-19px',
                      marginBottom: 25
                    }}>
                      <small>Separado por virgula</small>
                      <TextField
                        error={Boolean(touched.marketplace && errors.marketplace)}
                        helperText={touched.marketplace && errors.marketplace}
                        label="Outros canais"
                        title="Separador por virgula"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="marketplace"
                        onBlur={(ev) => {
                          var iit = ev.target.value.split(',');
                          iit.forEach((it) => {
                            handleMarketplace(it);
                          })
                        }}
                        variant="outlined"
                      />
                    </li>
                  </ul>}
                </CardContent>

              </Card>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Card>
                <CardHeader title="Integrador" subheader="Utiliza alguma plataforma para integração, entre seu ERP e Marketplaces? Se sim digite abaixo"></CardHeader>
                <CardContent>
                  <TextField
                    error={Boolean(touched.integrator && errors.integrator)}
                    helperText={touched.integrator && errors.integrator}
                    label="Utiliza algum integrador? Qual?"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="integrator"

                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.integrator}
                    variant="outlined"

                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid> : null}

          {step > 5 ? <Divider style={{ marginTop: 30, marginBottom: 30 }} /> : null}

          {step > 5 ? <Box>
            <Typography variant="h3" style={{ color: '#9e9e9e', letterSpacing: 2, fontSize: 12, fontWeight: 'bold', marginBottom: 30 }}>
              TERMOS DE USO
            </Typography>
          </Box> : null}

          {step > 5 ? <Grid container>

            <Grid item xs={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="accept_terms"
                    checked={values.accept_terms}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label={<>Marque aqui que concorda com os <a href="#" target="_blank" style={{ color: '#00bcd4', fontWeight: 'bold' }}>termos de uso.</a></>}
              />
            </Grid>

          </Grid> : null}

          <Grid item xs={12} lg={12}>
            {step >= 6 ? <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!values.accept_terms || success}
              style={{ marginTop: 50 }}
            >
              CONCLUIR
            </Button> : <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={() => {
                if (step < 6) {
                  handleStep(step + 1, values)
                }
              }}
              style={{ marginTop: 50 }}
            >
              AVANÇAR
            </Button>}
          </Grid>

          {success ? <Grid item xs={12} lg={12}>
            <Alert severity="success">
              <AlertTitle>Parabéns!</AlertTitle>
              Cadastro realizado com sucesso! Você receberá nosso contato para tratarmos das integrações.
            </Alert>
          </Grid> : null}

          {error_message ? <Grid item xs={12} lg={12}>
            <Alert severity="error">
              <AlertTitle>Erro!</AlertTitle>
              {error_message}
            </Alert>
          </Grid> : null}
        </form >
      )
      }
    </Formik >
  );
};

export default FormRegister;
