import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  TextareaAutosize,
  TextField
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { withSnackbar } from 'notistack';
import { Trash } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CarriersListView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [carriers, setCarriers] = useState([]);
  const [total, setTotal] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const apiService = new ApiService();
  const [emails, setEmails] = useState(null);
  const [carrierId, setCarrierId] = useState(null);

  const refresh = () => {
    apiService
      .getCarriers()
      .then((res) => {
        setCarriers(res.data);
        setTotal(res.total);
      });
  };

  const openModalEditCarrier = (carrierId) => {
    setCarrierId(carrierId);
    apiService
      .getCarrierEmails(carrierId)
      .then((res) => {
        setOpenDrawer(true);
        setEmails(res.data.map((res) => res.email).join(', '));
      });
  }

  const saveEmails = () => {
    apiService
      .addCarrierEmail(carrierId, emails)
      .then((res) => {
        enqueueSnackbar(`Emails ${emails} adicionados com sucesso!`, {
          variant: 'success'
        });
        setOpenDrawer(false);
      });
  }

  useEffect(() => {
    refresh();
  }, []);


  return (
    <Page
      className={classes.root}
      title="Transportadoras"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Box mt={3} style={{ minWidth: 300, margin: 30 }}>
            <Card>
              <CardHeader title="E-mails de solicitação de coleta"></CardHeader>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >

                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{   marginTop: 30 }}
                >
                  <TextareaAutosize
                    fullWidth
                    
                    label="E-mails"
                    name="emails"
                    onChange={(ev) => setEmails(ev.target.value)}
                    required
                    value={emails}
                    rowsMin={3}
                    variant="outlined"
                  />
                  <p style={{display: 'block', marginTop: 20, marginBottom: 20}}>
                    Digite os e-mails separados por virgula.
                  </p>

                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <Button variant="contained" onClick={saveEmails}>SALVAR</Button>
                </Grid>

              </CardContent>
            </Card>
          </Box>
        </Drawer>

        <Box mt={3}>
          <Results carriers={carriers} total={total} openModalEditCarrier={openModalEditCarrier} />
        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(CarriersListView);
