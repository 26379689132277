import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { AlignCenter } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

const Complete = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  const apiService = new ApiService();
  const urlStore = getQueryVariable('url');
  const flistLogUrl = window.location.host + window.location.pathname;
  const consumerKey = 'c99b93e7ea78ae25593570674666cc0d128c0808a2517368ff777c79aaebce4c';
  const channelId = 8;



  const createCompanyChannel = () => {
    apiService
      .newCompanyChannel({
        channel: {
          id: channelId
        },
        data: {
          store_host: getQueryVariable('store_host'),
          api_address: getQueryVariable('api_address'),
          code: getQueryVariable('code'),
          adm_user: getQueryVariable('adm_user'),
        }
      })
      .then(() => {
        enqueueSnackbar('Integração realizada com sucesso!', {
          variant: 'success',
        });
        setTimeout(function () {
          window.location.href = '/settings/integrations';
        }, 2000);
      });
  };

  useEffect(() => {
    if (!getQueryVariable('code')) {
      return window.location.href = '/connect/tray';
    }
    createCompanyChannel();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Tray + Flixlog"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              url: ''
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Avatar
                    style={{ margin: '0 auto', width: 125, height: 55 }}
                    src="https://images.tcdn.com.br/static_inst/site/vendedor/tray-cdn/uploads/logo-header.svg"
                  ></Avatar>
                  <br />
                  <br />
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: 'center' }}
                  >
                  </Typography>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{ textAlign: 'center' }}

                  >
                    Finalizando a configuração, aguarde ...
                  </Typography>
                </Box>

                <Box my={2}>
                  <center>
                    <CircularProgress disableShrink style={{ margin: '0 auto' }} />
                  </center>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(Complete);
