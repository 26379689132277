import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import { AlignCenter } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log('Query variable %s not found', variable);
}

const Complete = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [disableButton, setDisableButton] = useState(false);
  const apiService = new ApiService();
  const urlStore = getQueryVariable('url');
  const flistLogUrl = window.location.host + window.location.pathname;
  const consumerKey = '3002';
  const channelId = 29;



  const createCompanyChannel = () => {
    apiService
      .newCompanyChannel({
        channel: {
          id: channelId
        },
        data: {
          code: getQueryVariable('code')
        }
      })
      .then(() => {
        enqueueSnackbar('Integração realizada com sucesso!', {
          variant: 'success',
        });
        setTimeout(function () {
          window.location.href = '/settings/integrations';
        }, 2000);
      });
  };

  useEffect(() => {
    if (!getQueryVariable('code')) {
      return window.location.href = '/connect/nuvemshop';
    }
    createCompanyChannel();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Nuvemshop + Flixlog"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              url: ''
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <center>
                    <img src="https://d2r9epyceweg5n.cloudfront.net/assets/insti/img/json-ld-nuvemshop.png" style={{maxWidth: 200}} />
                  </center>

                  <br />
                  <br />
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ textAlign: 'center' }}
                  >
                  </Typography>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                    style={{ textAlign: 'center' }}

                  >
                    Finalizando a configuração, aguarde ...
                  </Typography>
                </Box>

                <Box my={2}>
                  <center>
                    <CircularProgress disableShrink style={{ margin: '0 auto' }} />
                  </center>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withSnackbar(Complete);
