import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import moment from 'moment';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SwipeableDrawer,
  Grid,
  Table,
  TableBody,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import OrderRow from '../orders/OrdersView/OrderRow';
import Recaptcha from 'react-recaptcha';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const script = document.createElement('script');
script.src = 'https://www.google.com/recaptcha/api.js';
script.async = true;
script.defer = true;
document.body.appendChild(script);

const TrackingCustomView = ({ enqueueSnackbar, found, setOrders }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const [pessoa, setPessoa] = useState(1);
  const [recaptchaInstance, setRecaptchaInstance] = useState(null);
  const [key, setKey] = useState(0);
  const { company } = useParams();
  const [isFound, setIsFound] = useState(found || false);
  const [error, setError] = useState(null);

  const companies = {
    meblo: {
      logo: 'https://recursos.meblo.com.br/i/logo-rodape.png',
      primaryColor: '#ff6a66',
      secundaryColor: '#142032'
    },
    bimper: {
      logo: 'https://17530.cdn.simplo7.net/static/17530/configuracao/logo_152933445342328.png',
      primaryColor: '#dca93a',
      secundaryColor: '#000'
    }
  }

  return (



    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            federal_tax_id: '',
            tracking_code: '',
            recaptcha: ''
          }}
          validationSchema={
            Yup.object().shape({
              federal_tax_id: Yup.string().max(255),
              tracking_code: Yup.string().max(255),
              recaptcha: Yup.string().required('Insira a resposta do recaptcha'),
            })
          }
          onSubmit={(values) => {
            if (values.tracking_code) {
              window.location.href = `/tracking/c/${company}/${values.tracking_code}`;
              return;
            }
            return apiService
              .getOrdersFromFederalTaxId(values.federal_tax_id, values.recaptcha, company)
              .then((res) => {
                if (!res.length) {
                  setError('Não foi encontrado nenhum pedido com o documento informado');

                }
                setKey(key + 1);

                if (res.length === 1) {
                  var trackCode = res[0].tracker.tracking_code;
                  setError(null);
                  window.location.href = `/tracking/c/${company}/${trackCode}`;
                  return;
                }

                setOrders(res);
                setIsFound(true);
              }).catch(() => {
                setError('Não foi encontrado nenhum pedido com o documento informado');
              
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              {isFound ? <>
                {error ? <Alert severity="error" style={{ marginTop: 30, marginBottom: 30 }}>
                    <AlertTitle>{error}</AlertTitle> 
                    </Alert>: null}
              </> : <> <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                >
                  Olá! <br />
                  Para rastrear seu pedido, digite um dos dados abaixo.
                </Typography>
              </Box>

                <FormControl component="fieldset" margin="normal">
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel margin="normal" value={1} checked={pessoa === 1} onChange={() => setPessoa(1)} control={<Radio color="primary" />} label="PF" />
                    <FormControlLabel margin="normal" value={2} checked={pessoa === 2} onChange={() => setPessoa(2)} control={<Radio color="primary" />} label="PJ" />
                    <FormControlLabel margin="normal" value={3} checked={pessoa === 3} onChange={() => setPessoa(3)} control={<Radio color="primary" />} label="Código de Rastreio" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth>
                  {pessoa === 1 || pessoa === 2 ? (
                    <InputMask
                      mask={pessoa === 1 ? '999.999.999-99' : (pessoa === 2 ? '99.999.999/9999-99' : '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.federal_tax_id && errors.federal_tax_id)}
                      helperText={touched.federal_tax_id && errors.federal_tax_id}
                      value={values.federal_tax_id}

                    >
                      {() => (
                        <TextField
                          label={pessoa === 1 ? 'Digite o CPF' : (pessoa === 2 ? 'Digite o CNPJ' : 'Digite o código de rastreio')}
                          margin="normal"
                          name="federal_tax_id"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  ) : (

                    <TextField
                      label={'Digite o código de rastreio'}
                      margin="normal"
                      name="tracking_code"
                      variant="outlined"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.tracking_code && errors.tracking_code)}
                      helperText={touched.tracking_code && errors.tracking_code}
                      value={values.tracking_code}
                    />

                  )}
                </FormControl>

                <FormControl component="fieldSet" fullWidth>
                  <Recaptcha
                    key={key}
                    sitekey="6Le9dOQZAAAAAK1KaAKl3KV7S19bUsbVluwJuogd"
                    render="explicit"
                    theme="dark"
                    verifyCallback={(response) => { setFieldValue('recaptcha', response); }}
                    onloadCallback={() => { console.log("done loading!"); }}
                  />
                  {errors.recaptcha
                    && touched.recaptcha && (
                      <p>{errors.recaptcha}</p>
                    )}
                </FormControl>
              </>}

              {isFound ? null : <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: companies[company].primaryColor }}
                >
                  RASTREAR
                </Button>
              </Box>}

              {isFound ? <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={() => window.location.href = '/tracking/c/' + company}
                  style={{ backgroundColor: companies[company].primaryColor }}
                >
                  NOVA BUSCA
                </Button>
              </Box> : null}
            </form>
          )}
        </Formik>
      </Container>

    </Box>
  );
};

export default withSnackbar(TrackingCustomView);
