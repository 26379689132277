import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  makeStyles
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PickupsListView = (props) => {
  const classes = useStyles();
  const [pickups, setPickups] = useState([]);
  const [pickupsToday, setPickupsToday] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(10);
  const [page, changePage] = useState(1);

  const [selectedRows, setSelectedRowsIds] = useState([]);

  const handleSelectAll = (event) => {
    let rowIds;

    if (event.target.checked) {
      rowIds = pickups.map((order) => order.reference);
    } else {
      rowIds = [];
    }

    setSelectedRowsIds(rowIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);
  };

  const reloadOrders = (filters) => {
    const apiService = new ApiService();
    apiService
      .getPickups(filters, limit, page)
      .then((res) => {
        setPickups(res.data);
        setTotalRows(res.total);
      });
    apiService
      .getPickupsToday(filters, limit, page)
      .then((res) => {
        setPickupsToday(res.data);
        setTotalRows(res.total);
      });
  };

  useEffect(() => {
    let filters = {};

    if (props.orderSearch.value && !props.orderSearch.many) {
      filters['reference'] = props.orderSearch.value;
    } else {
      filters['search'] = props.orderSearch.value;
    }

    if (props.orderSearchStatus) {
      filters['status'] = props.orderSearchStatus;
    }

    reloadOrders(filters);

  }, [props.orderSearch, props.orderSearchStatus, limit, page]);

  return (
    <Page
      className={classes.root}
      title="Coletas"
    >
      <Container maxWidth={false}>
        <Toolbar selectedRows={selectedRows} reloadOrders={(filters) => reloadOrders(filters)} />

        {pickupsToday.length ? <Box mt={3}>
          <Card style={{ backgroundColor: '#000' }}>
            <CardHeader style={{ color: '#fff' }} title={`Você tem ${pickupsToday.length} coletas para hoje!`}></CardHeader>
            <CardContent>
              <Results
                noPagination
                totalRows={totalRows}
                changePage={changePage}
                changeLimit={changeLimit}
                pickups={pickupsToday}
                selectedRows={selectedRows}
                handleSelectAll={handleSelectAll}
                handleSelectOne={handleSelectOne}
                setSelectedRowsIds={setSelectedRowsIds}
              />
            </CardContent>
          </Card>
        </Box> : null}


        <Box mt={3}>
          <Results
            totalRows={totalRows}
            changePage={changePage}
            changeLimit={changeLimit}
            pickups={pickups}
            selectedRows={selectedRows}
            handleSelectAll={handleSelectAll}
            handleSelectOne={handleSelectOne}
            setSelectedRowsIds={setSelectedRowsIds}
          />
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = (store) => ({
  orderSearch: store.orderSearch,
  orderSearchStatus: store.orderSearch.status
});

export default connect(mapStateToProps)(PickupsListView);
