import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';

import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DeleteIntegrationView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const match = useParams();
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    const apiService = new ApiService();
    apiService
      .getCompanyChannel(match.id)
      .then((res) => setChannel(res));
  }, [match.id]);

  const handleDelete = () => {
    const apiService = new ApiService();
    apiService
      .removeCompanyChannel(channel.id)
      .then(() => {
        enqueueSnackbar('Integração excluída com sucesso', { variant: 'success' });
        navigate('/settings/integrations');
      })
      .catch((res) => enqueueSnackbar(`Ocorreu um erro: ${res.message}`, { variant: 'error' }));
  };

  return (
    <Page
      className={classes.root}
      title="Remover - Usuário"
    >
      <Container maxWidth={false}>
        <Toolbar hideButton />
        <Box mt={3}>
          <Card>
            <CardContent>
              {channel ? (
                <>
                  <Typography variant="h2">
                    Tem certeza que deseja excluir a integração:
                    {` ${channel.channel.name}?`}
                  </Typography>
                  <Button style={{ backgroundColor: '#dc1d1d', color: '#fff', marginTop: 30 }} onClick={handleDelete}>EXCLUIR DEFINITIVAMENTE</Button>
                </>
              ) : 'Aguarde...'}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(DeleteIntegrationView);
