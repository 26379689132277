import React from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isSuperUser } from 'src/common/auth';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));

const Toolbar = ({className, hideButton, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          Transportadoras
        </Typography>

        {isSuperUser() ? <Button
          color="primary"
          variant="contained"
          style={{ marginRight: 20 }}
          component={Link}
          to="/settings/carriers/new"
        >
          NOVA TRANSPORTADORA
        </Button> : null}
      </Box>
    </div>
  );
};

export default Toolbar;
