import React from 'react';

import { Tooltip } from '@material-ui/core';
import Lozenge from '@atlaskit/lozenge';

export default function StatusIcon({ type, title, description }) {
  const badge = <Lozenge appearance={type}>{title}</Lozenge>;

  return !description ? badge : (
    <Tooltip title={description}>
      <div>{badge}</div>
    </Tooltip>
  );
}
