import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import {
  makeStyles,
  Container,
  IconButton,
  Typography,
  Grid,
  Divider,
  Box,
  Card,
  CardHeader,
  CardContent,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  MenuItem,
  Select
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import { useNavigate } from 'react-router-dom';
import OrdersList from 'src/views/orders/v2/OrdersView/List';

import { LoadingButton } from '@atlaskit/button';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import EmptyState from '@atlaskit/empty-state';

import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/standard-button';
import { Checkbox } from '@atlaskit/checkbox';
import { RadioGroup } from '@atlaskit/radio';
import Textfield from '@atlaskit/textfield';

import Form, { CheckboxField, ErrorMessage, Field, FormFooter, FormHeader, FormSection, HelperMessage } from '@atlaskit/form';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import SectionMessage from '@atlaskit/section-message';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { Check, Edit, ExternalLink, Trash } from 'react-feather';
import { useSnackbar } from 'notistack';
import { CheckCircle, ErrorRounded, Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const CtesEditView = ({ cte, callReload, onCloseWindow }) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [splitIcms, setSplitICMS] = useState(false);
  const [actualIcms, setActualIcms] = useState(cte?.shipment?.delivery?.log[0]?.icms_tax);
  const originalFlixlogTaxes = JSON.parse(JSON.stringify(cte?.shipment?.delivery?.log
    .filter((log) => !log.deadlines)
    .filter((log) => {
      log.fee = log.fee.toLowerCase();
      if (!log.value && log.additional_price) {
        log.value = log.additional_price;
      }
      
      return log;
    })
    
    .filter((log) => log.value) || []));

    console.log(cte.shipment.delivery.log, originalFlixlogTaxes);

  const [flixlogTaxes, setFlixlogTaxes] = useState(JSON.parse(JSON.stringify(originalFlixlogTaxes)));

  const [loadingSubmit, setLoadingSubmit] = useState({
    removeFromCte: false,
    confirmCte: false
  });

  const [changeMap, setChangeMap] = useState(cte.taxes.map((tx) => ({ open: false, fee: tx.fee, mapped: null, value: tx.value })));

  const openChangeMapMenu = (fee) => {
    setChangeMap(changeMap.map((tx) => {
      if (tx.fee == fee.fee) {
        tx.open = true;
      } else {
        tx.open = false;
      }
      return tx;
    }));
  };

  const handleChangeMap = (fee, map) => {
    let key = 0;

    for (let i = 0; i <= originalFlixlogTaxes.length; i++) {
      if (originalFlixlogTaxes[i]?.fee == map) {
        key = i;
      }
    }

    let itemKey = 0;

    let cm = changeMap.map((tx, idx) => {
      if (tx.fee == fee.fee) {
        tx.mapped = map;
        itemKey = idx;
      }
      return tx;
    });

    let val1 = cm[key];
    let val2 = cm[itemKey];
    cm[key] = val2;
    cm[itemKey] = val1;


    setChangeMap(cm);
  };

  const isMapOpen = (fee) => {
    for (let i = 0; i < changeMap.length; i++) {
      if (changeMap[i].fee == fee.fee) {
        return changeMap[i].open;
      }
    }
  };

  const getMapped = (fee) => {
    for (let i = 0; i < changeMap.length; i++) {
      if (changeMap[i].fee == fee.fee) {
        return changeMap[i].mapped;
      }
    }
  };

  const openModal = useCallback((orderId) => {
    setIsOpen(true);
    setSelectedOrder(orderId)
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setSelectedOrder(null);
  }, []);

  const openModalDelete = useCallback((cteId) => {
    setIsOpenDelete(true);
  }, []);

  const closeModalDelete = useCallback(() => {
    setIsOpenDelete(false);
  }, []);

  const removeFromCte = () => {
    setLoadingSubmit({ ...loadingSubmit, removeFromCte: true });

    apiService
      .removeFromCte(selectedOrder.id)
      .then(() => {
        enqueueSnackbar('Pedido #' + selectedOrder.reference + ' removido com sucesso.', {
          variant: 'success',
        });
        setLoadingSubmit({ ...loadingSubmit, removeFromCte: false });
        closeModal();
      }).catch((error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
        setLoadingSubmit({ ...loadingSubmit, removeFromCte: false });
        closeModal();
      });
  };

  const downloadCte = (id) => {
    apiService.downloadCte(id);
  };


  const handleSetSplitIcms = (split) => {
    setSplitICMS(split);

    if (split) {
      let txs = flixlogTaxes.map((tx) => {
        tx.value = tx.value - (tx.value * actualIcms);
        return tx;
      });
      const totalIcms = flixlogTaxes.map((tx) => tx.value * actualIcms).reduce((a, b) => a + b, 0);
      txs.push({ fee: 'ICMS', value: totalIcms });
      console.log(txs);
      setFlixlogTaxes(txs)
    } else {
      setFlixlogTaxes(originalFlixlogTaxes);
    }
  }


  return (
    <Container maxWidth={false} style={{ padding: 24 }}>



      <div style={{
        margin: '0 8px',
        borderBottom: '1px solid #ddd',
        paddingBottom: '10px'
      }}>
        <Typography
          variant='h2'
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 19,
            marginBottom: 10
          }}>CTE #{cte.number} <small style={{
            fontSize: 12,
            marginLeft: 10,
            color: '#6d798e'
          }}>Importado em {moment(cte.created_at).format('DD/MM/YYYY HH:mm:ss')}</small></Typography>
      </div>
      <Tabs
        onChange={(index) => console.log('Selected Tab', index + 1)}
        id="default"
      >
        <TabList>
          <Tab>Comparativo</Tab>
        </TabList>

        <TabPanel>
          <Grid container>
            <Grid item lg={12}>
              <Card style={{ marginTop: 20 }}>
                <CardHeader title={"Comparativo dos principais ofensores de valor de frete"} />
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableCell>
                        Peso Flixlog
                      </TableCell>
                      <TableCell>
                        Peso Transportadora
                      </TableCell>
                      <TableCell>
                        Diferença
                      </TableCell>
                      <TableCell>
                        Avaliação
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: '25%' }} >
                          {cte.shipment.delivery.cubed_weight.toLocaleString('pt-BR')}KG
                        </TableCell>
                        <TableCell style={{ width: '25%' }} >
                          {cte.total_weight.toLocaleString('pt-BR') + 'KG'}
                        </TableCell>
                        <TableCell style={{ width: '25%' }}> {(cte.total_weight - cte.shipment.delivery.cubed_weight).toLocaleString('pt-BR') + 'KG'} </TableCell>
                        <TableCell style={{ width: '25%' }}>
                          {(cte.total_weight - cte.shipment.delivery.cubed_weight) == 0 ? <HtmlTooltip title={<>
                            <Typography color="inherit">Tudo ok!</Typography>
                            {"O peso está correto"}
                          </>}><CheckCircle style={{ color: 'green' }} /></HtmlTooltip> : null}
                          {(cte.total_weight - cte.shipment.delivery.cubed_weight) < 0 ? <HtmlTooltip title={<>
                            <Typography color="inherit">O peso na cotação da Flixlog está maior!</Typography>
                            {"E isso pode dar um frete mais caro na Flixlog do que na transportadora"}
                          </>}><Warning style={{ color: '#ff9800' }} /></HtmlTooltip> : null}
                          {(cte.total_weight - cte.shipment.delivery.cubed_weight) > 0 ? <HtmlTooltip title={<>
                            <Typography color="inherit">O peso na cotação da Flixlog está menor!</Typography>
                            {"E isso pode dar um frete mais barato na Flixlog do que na transportadora"}
                          </>}><ErrorRounded style={{ color: 'red' }} /></HtmlTooltip> : null}

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table>
                    <TableHead>
                      <TableCell>
                        NF Flixlog
                      </TableCell>
                      <TableCell>
                        NF Transportadora
                      </TableCell>
                      <TableCell>
                        Diferença
                      </TableCell>
                      <TableCell>
                        Avaliação
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: '25%' }}  >
                          {cte.shipment.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </TableCell>
                        <TableCell style={{ width: '25%' }} >
                          {cte.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </TableCell>
                        <TableCell style={{ width: '25%' }}> {(parseFloat(cte.amount) - parseFloat(cte.shipment.cargo_value)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                        <TableCell style={{ width: '25%' }}>
                          {(parseFloat(cte.amount) - parseFloat(cte.shipment.cargo_value)) == 0 ? <HtmlTooltip title={<>
                            <Typography color="inherit">Tudo ok!</Typography>
                            {"O valores são iguais"}
                          </>}><CheckCircle style={{ color: 'green' }} /></HtmlTooltip> : null}
                          {(parseFloat(cte.amount) - parseFloat(cte.shipment.cargo_value)) < 0 ?
                            <HtmlTooltip title={<>
                              <Typography color="inherit">O valor da NF na cotação da Flixlog está maior!</Typography>
                              {"E isso pode dar um frete um pouco mais caro na Flixlog do que na transportadora."}
                            </>}><Warning style={{ color: '#ff9800' }} /></HtmlTooltip> : null}
                          {(parseFloat(cte.amount) - parseFloat(cte.shipment.cargo_value)) > 0 ? <HtmlTooltip
                            title={<>
                              <Typography color="inherit">O valor da NF na cotação da Flixlog está menor!</Typography>
                              {"E isso pode dar um frete um pouco mais barato na Flixlog do que na transportadora."}
                            </>}
                          ><ErrorRounded style={{ color: 'red' }} /></HtmlTooltip> : null}

                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>

            </Grid>

            <Grid item lg={12}>
              <FormSection>
                <CheckboxField name="split_icms">
                  {({ fieldProps }) => (
                    <Checkbox
                      {...fieldProps}
                      isChecked={splitIcms}
                      onClick={(ev) => handleSetSplitIcms(ev.target.checked)}
                      label={`Marque aqui para destacar o ICMS: (${Math.round(actualIcms * 100)}%)`}
                    />
                  )}
                </CheckboxField>
              </FormSection>
            </Grid>
            <Grid item lg={4} >
              {(cte?.shipment?.delivery && cte?.shipment?.delivery?.log) ? (
                <Grid item xs={12} md={12}>


                  <Card style={{ marginTop: 20 }}>
                    <CardHeader title={"Cotação Flixlog"} />
                    <CardContent>
                      <Table>
                        <TableHead>
                          <TableCell>
                            Taxa
                          </TableCell>
                          <TableCell>
                            Valor
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {flixlogTaxes.map((log) => {
                            var percentage = (<TableCell style={{ height: 53 }}>{log.percentage ? log.percentage + '%' : '-'}</TableCell>);
                            var translageFee = {
                              pickup: 'Taxa de Coleta',
                              toll: 'Pedágio',
                              weight: 'Frete peso',
                              gris: 'GRIS',
                              adv: 'ADV (Seguro)',
                              tdc: 'Taxa de despacho',
                              icms: 'ICMS',
                              'additional-price': 'KG Excedente',
                              'increase-table-value': 'Adicional da tabela',
                              'increase-freight-value': 'Adicional da conta',

                              'freight_rule_increase_value': 'Regra de Frete: Aumentar valor',
                              'freight_rule_increase_percentage': 'Regra de Frete: Aumentar percentual',
                              'freight_rule_decrease_value': 'Regra de Frete: Diminuir valor',
                              'freight_rule_decrease_percentage': 'Regra de Frete: Diminuir percentual',
                              'freight_rule_fixed_value': 'Regra de Frete: Valor fixo'
                            }
                            if (log.icms_percentage) {
                              percentage = (<TableCell style={{ height: 53 }}>{log.icms_percentage ? log.icms_percentage + '%' : '-'}</TableCell>);
                            }
                            if (log.gris_percentage) {
                              percentage = (<TableCell style={{ height: 53 }}>{log.gris_percentage ? (log.gris_percentage * 100).toFixed(2) + '%' : '-'}</TableCell>);
                            }
                            if (log.adv_percentage) {
                              percentage = (<TableCell style={{ height: 53 }}>{log.adv_percentage ? (log.adv_percentage * 100) + '%' : '-'}</TableCell>);
                            }
                            if (log.fee == 'freight_rule_increase_percentage' || log.fee == 'freight_rule_decrease_percentage') {
                              percentage = (<TableCell style={{ height: 53 }}>{log.value ? log.value + '%' : '-'}</TableCell>);
                            }

                            if (log.fee == 'freight_rule_increase_value' || log.fee == 'freight_rule_decrease_value') {
                              var price = <TableCell style={{ height: 53 }}>{log.value > 0 ? log.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</TableCell>
                            } else if (log.fee == 'freight_rule_fixed_value') {
                              var price = <TableCell style={{ height: 53 }}>{log.value > 0 ? '-' : '-'}</TableCell>
                            } else {
                              var price = <TableCell style={{ height: 53 }}>{log.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                            }
                            return (
                              <StyledTableRow>
                                <TableCell style={{ height: 53 }}>{translageFee[log.fee] ? translageFee[log.fee] : log.fee}</TableCell>
                                {price}
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>

                    </CardContent>
                  </Card>
                </Grid>
              ) : null}
            </Grid>
            <Grid item lg={8} >
              {changeMap ? (
                <Grid item xs={12} md={12}>
                  <Card style={{ marginTop: 20 }}>
                    <CardHeader title={"Cotação CTE"} />
                    <CardContent>
                      <Table variant>
                        <TableHead>
                          <TableCell>
                            Taxa
                          </TableCell>
                          <TableCell>
                            Valor
                          </TableCell>
                          <TableCell>
                            Diferença
                          </TableCell>
                          <TableCell>
                            Avaliação
                          </TableCell>
                        </TableHead>
                        <TableBody>
                          {changeMap.map((log, key) => {
                            const flixValue = flixlogTaxes[key]?.value || 0;
                            var translageFee = {
                              pickup: 'Taxa de Coleta',
                              toll: 'Pedágio',
                              weight: 'Frete peso',
                              gris: 'GRIS',
                              adv: 'ADV (Seguro)',
                              tdc: 'Taxa de despacho',
                              icms: 'ICMS',
                              'additional-price': 'KG Excedente',
                              'increase-table-value': 'Adicional da tabela',
                              'increase-freight-value': 'Adicional da conta',

                              'freight_rule_increase_value': 'Regra de Frete: Aumentar valor',
                              'freight_rule_increase_percentage': 'Regra de Frete: Aumentar percentual',
                              'freight_rule_decrease_value': 'Regra de Frete: Diminuir valor',
                              'freight_rule_decrease_percentage': 'Regra de Frete: Diminuir percentual',
                              'freight_rule_fixed_value': 'Regra de Frete: Valor fixo'
                            }
                            return (
                              <StyledTableRow>
                                <TableCell style={{ height: 53 }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>{log.fee} <Tooltip title="Alterar mapeamento de taxas">
                                    <Edit style={{ width: 15, marginLeft: 5 }} onClick={() => openChangeMapMenu(log)} />
                                  </Tooltip>
                                    {isMapOpen(log) || getMapped(log) !== null ? <Select value={getMapped(log)} onChange={(ev) => handleChangeMap(log, ev.target.value)}  >
                                      <MenuItem value={null}></MenuItem>
                                      {originalFlixlogTaxes.map((tx) => <MenuItem key={tx.fee} value={tx.fee}>{translageFee[tx.fee] || tx.fee.toUpperCase()}</MenuItem>)}
                                    </Select> : null}
                                  </div> </TableCell>
                                <TableCell style={{ height: 53 }}>{log.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>

                                <TableCell style={{ height: 53 }}>{getMapped(log) ? (log.value - flixValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-'}</TableCell>
                                <TableCell style={{ height: 53 }}>
                                  {getMapped(log) && Math.round((log.value - flixValue), 2) == 0 ? <Tooltip title="Valor exato"><CheckCircle style={{ color: 'green' }} /></Tooltip> : null}
                                  {getMapped(log) && Math.round((log.value - flixValue), 2) < 0 ? <Tooltip title="Valor da transportadora está menor"><Warning style={{ color: '#ff9800' }} /></Tooltip> : null}
                                  {getMapped(log) && Math.round((log.value - flixValue), 2) > 0 ? <Tooltip title="Valor da transportadora está maior"><ErrorRounded style={{ color: 'red' }} /></Tooltip> : null}
                                </TableCell>

                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>

              ) : null}
            </Grid>
            <Grid item lg={12}>
              <a onClick={() => downloadCte(cte.id)} style={{

                color: '#03a9f4', marginTop: '10px', display: 'block', fontWeight: 'bold', cursor: 'pointer', display: 'flex',
                alignItems: 'center',
              }} target="_blank">Abrir Dacte <ExternalLink style={{ marginLeft: 5, width: 20 }} /></a>
            </Grid>
          </Grid>
        </TabPanel>

      </Tabs>


    </Container >
  );
};


export default CtesEditView;
