import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  TextareaAutosize,
  makeStyles, FormControl, InputLabel, Select, MenuItem, TextField, Switch, Input
} from '@material-ui/core';

import ApiService from 'src/common/apiService';
import Chip from '@material-ui/core/Chip';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    width: '100%'
  }
}));

const StepOne = ({ className, setLayout, openModalCarrier, setFile, setCarrier, setCd, setRegion, setReadjustment, setCubage, setApplyIcms, setTipoCorredor, params, ...rest }) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [regions, setRegions] = useState([]);
  const [cds, setCds] = useState([]);
  const [carriers, setCarriers] = useState([]);


  useEffect(() => {
    apiService
      .getCarriers(true)
      .then((res) => setCarriers(res.data));
    apiService
      .getRegions()
      .then((res) => setRegions(res.data));
    apiService
      .getDistributionCenters()
      .then((res) => setCds(res.data));
  }, []);

  return (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Etapa 1 - Cabeçalho da tabela"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>


              <FormControl variant="outlined" style={{ marginRight: 20, width: 500 }}>

                <Autocomplete
                  disablePortal
                  id="carrier-list"

                  options={carriers}
                  sx={{ width: 500 }}
                  onChange={(ev, carrier) => {
                    setCarrier(carrier ? carrier.id : null);
                  }}
                  getOptionLabel={(carrier) => `${carrier.canonical_name} - ${carrier.name} - ${carrier.federal_tax_id} (${carrier.property?.address?.city}/${carrier.property?.address?.state})`}
                  renderInput={(params) => <TextField variant="outlined" required {...params} label="Transportadora" />}
                />
                {/* <a onClick={openModalCarrier} href="#">+ add nova transportadora</a> */}
              </FormControl>


              <FormControl variant="outlined" style={{ marginRight: 20, width: 200 }}>
                <InputLabel id="demo-simple-select-outlined-label">Tipo de Modal</InputLabel>
                <Select
                  label="Tipo de moda"
                  style={{ width: '100%' }}
                  value={params.tipo}
                  required
                  onChange={(ev) => setTipoCorredor(ev.target.value)}
                >
                  <MenuItem value="rodo">Rodoviário</MenuItem>
                  <MenuItem value="aereo">Aéreo</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>


              <FormControl variant="outlined" style={{ marginRight: 20, width: 200 }}>

                <Autocomplete
                  disablePortal
                  id="regions-list"

                  options={regions}
                  onChange={(ev, region) => setRegion(region ? region : null)}
                  sx={{ width: 300 }}
                  getOptionLabel={(region) => region}

                  renderInput={(params) => <TextField variant="outlined" required {...params} label="Origem" />}
                />
              </FormControl>


              <FormControl variant="outlined" style={{ marginRight: 20, width: 200 }}>
                <Autocomplete
                  disablePortal
                  id="cds-list"
                  options={cds}

                  onChange={(ev, cd) => setCd(cd ? cd.id : null)}
                  sx={{ width: 300 }}
                  getOptionLabel={(cd) => `${cd.reference} - ${cd.name} - ${cd.address.city}/${cd.address.state}`}

                  renderInput={(params) => <TextField variant="outlined" {...params} label="CD de Origem" />}
                />
              </FormControl>

            </Grid>

            <Grid item lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="apply_icms"
                    checked={params.applyIcms}
                    required
                    onChange={(ev) => setApplyIcms(ev.target.checked)}
                    color="primary"
                    required
                  />
                }
                label="Aplicar alíquota de ICMS?"
              />
              <small style={{ display: 'block', color: '#607d8b' }}>
                Se estiver na dúvida, deixe marcado.
              </small>
            </Grid>
            <Grid item lg={12}>
              <TextField
                label="Fator de cubagem"
                margin="normal"
                name="cubage"
                required
                type="number"
                onChange={(ev) => setCubage(ev.target.value)}
                value={params.cubage}
                variant="outlined"
              />
            </Grid>

            <Grid item sx={12}>
              <TextField
                label="% de Reaguste"
                margin="normal"
                name="readjustment"
                type="number"
                required
                onChange={(ev) => setReadjustment(ev.target.value)}
                value={params.readjustment}
                variant="outlined"
              />
              <br />

              <strong>{params.readjustment ? `Será adicionado ${params.readjustment.toLocaleString('pt-BR')}% a mais nas taxas em R$ da tabela.` : ''}</strong>

            </Grid>

          </Grid>

        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="Etapa 2 - Tabela de Frete"
          subheader="Selecione a tabela de frete para fazer upload"
        />
        <Divider />
        <CardContent>
          <Grid container>
            {console.log(params.file)}
            <input type="file" onChange={(ev) => {
              setFile(ev.target.files[0])
            }} />
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default StepOne;
