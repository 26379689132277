import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';

import { connect } from 'react-redux';
import { Edit, ExternalLink, Target, Trash, Truck } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  },
  successButton: {
    backgroundColor: '#8BC34A'
  }
}));

const Toolbar = ({ order, title, isDropShipping, className, setOpenChangeCarrier, setOpenCancel, setOpenCheckout, ...rest }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >
          {(isDropShipping ? title : `Pedido #${order.reference}`)} {isDropShipping && order.status.id === 9 ? 'Remessa cancelada' : (order.status.id === 9 ? ' - Cancelado' : null)}
        </Typography>

        {order.tracker ? (<strong><a style={{
          display: 'flex',
          marginRight: 20,
          color: '#000',
          display: 'flex',
          alignItems: 'center'
        }} target="_blank" href={`/tracking/${order.tracker.tracking_code}`}><ExternalLink style={{ marginRight: 5 }} /> Link de rastreio</a></strong>) : null}

        {(order.status.id === 1 || order.status.id === 2 || order.status.id === 11 || order.status.id === 12|| order.status.id === 10) ?
          <>
            <Button variant="contained" color="primary" aria-controls="edit-order-menu" aria-haspopup="true" onClick={handleClick} style={{marginRight: 20}}>
              EDITAR
            </Button>
            <Menu
              id="edit-order-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} onClick={() => window.location.href = `/orders/${isDropShipping ? order.order_id : order.id}/edit`}><Edit style={{width: 30, marginRight: 10}} /> Editar Pedido</MenuItem>
              <MenuItem onClick={handleClose} onClick={setOpenChangeCarrier}><Truck style={{width: 30, marginRight: 10}} /> Alterar Transportadora</MenuItem>
              <MenuItem onClick={handleClose} onClick={setOpenCancel}><Trash style={{width: 30, marginRight: 10}} /> Cancelar Pedido</MenuItem>
            </Menu>
          </>


          : null}

        {order.status.id === 1 ?
          (
            <Button
              color="primary"
              variant="contained"
              onClick={setOpenCheckout}
              className={classes.successButton}
            >
              ENVIAR NOTA
            </Button>
          ) : null}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ changeOrderSearch }, dispatch);
// };

export default connect(null, null)(Toolbar);
