import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Close } from '@material-ui/icons';
import { Check, Edit, Trash } from 'react-feather';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, products, changeLimit, changePage, total, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  SKU
                </TableCell>
                <TableCell>
                  Descrição
                </TableCell>
                <TableCell>
                  Volumes
                </TableCell>
                <TableCell>
                  Preço (R$)
                </TableCell>
                <TableCell>
                  CD de Origem
                </TableCell>
                <TableCell>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.slice(0, limit).map((product) => (
                <>
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell>
                      {product.id}
                    </TableCell>
                    <TableCell>
                      {product.reference}
                    </TableCell>
                    <TableCell>
                      {product.description}
                    </TableCell>
                    <TableCell>

                      {!product.height && !product.length && !product.length ? <small style={{ whiteSpace: 'nowrap' }}>
                        Produto sem medidas</small> : <small style={{ display: 'block', whiteSpace: 'nowrap', padding: '5px', background: '#ddd', borderRadius: '5px', border: '1px solid #ababab' }}>
                        (A:{product.height * 100} L:{product.width * 100} C:{product.length * 100}) - {product.weight ? product.weight.toLocaleString('pt-BR') : '0'}kg x {product.quantity}
                      </small>}


                      {product.property && product.property.length ? product.property.map((res) => {
                        return (
                          <small style={{ display: 'block', marginTop: '5px', whiteSpace: 'nowrap', padding: '5px', background: '#ddd', borderRadius: '5px', border: '1px solid #ababab' }}>
                            (A:{res.height * 100} L:{res.width * 100} C:{res.length * 100}) - {res.weight ? res.weight.toLocaleString('pt-BR') : '0'}kg x {res.quantity}
                          </small>
                        );
                      }) : null}

                    </TableCell>

                    <TableCell>
                      {product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </TableCell>

                    <TableCell>
                      {product.distribution_center ? product.distribution_center.reference : '-'}
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex' }}>
                        <Button component={Link} to={"/settings/products/edit/" + product.id}>
                          <Edit />
                        </Button>

                        <Button component={Link} to={"/settings/products/remove/" + product.id}>
                          <Trash />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired
};

export default Results;
