import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { getOptions } from 'highcharts';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles(() => ({
  root: {}
}));

const StatusGeralBlockGrouped = ({ className, title, description, categories, series, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme(); 
  

const options = {
    chart: {
        type: 'column',
        height: 300
    }, 
    title: {
        text: description
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories,
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Total de Pedidos'
        },
        stackLabels: {
            enabled: true,
            style: {
                fontWeight: 'bold',
                color: ( // theme
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || 'gray',
                textOutline: 'none'
            }
        }
    },
	
    credits: {
      enabled: false,
    },
	
    legend: {
        align: 'center',
        x: -30,
        verticalAlign: 'top',
        y: -10,
        floating: false,
        backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
    },
    // tooltip: {
  //       headerFormat: '<b>{point.x}</b><br/>',
  //       pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  //   },
	
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} pedidos</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
	
    plotOptions: {
        column: {
			
            stacking: 'percent',
            dataLabels: {
                enabled: true
            }
        }
    },
    series
};

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title={title}
      />
      <Divider />
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <HighchartsReact
            options={options}
            highcharts={Highcharts}
            allowChartUpdate={true}
            updateArgs={[true, true, true]}
            containerProps={{ className: 'chartContainer' }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

StatusGeralBlockGrouped.propTypes = {
  className: PropTypes.string
};

export default StatusGeralBlockGrouped;
