import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import ApiService from 'src/common/apiService';

import Page from 'src/components/Page';
import StepOne from './StepOne';
import Sheet from './Sheet';
import Toolbar from './Toolbar';
import NewCarrier from './NewCarrier';
import { useSnackbar, withSnackbar } from 'notistack';

import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ReuseView = ({ enqueueSnackbar }) => {

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [file, setFile] = useState();
  const [carrier, setCarrier] = useState(null);
  const [cd, setCd] = useState(null);
  const [region, setRegion] = useState(null);
  const [cubage, setCubage] = useState(300);
  const [applyIcms, setApplyIcms] = useState(true);
  const [readjustment, setReadjustment] = useState(null);
  const [tipo, setTipoCorredor] = useState('rodo');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [submiting, setSubmiting] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);

  const [freightTables, setFreightTables] = useState([]);
  const [freightTable, setFreightTable] = useState(null);

  const apiService = new ApiService;

  const submitTable = () => {

    const errors = {
      company: 'Selecione o Cliente',
      freightTable: 'Selecione a tabela de frete'
    };

    if (!company) {
      enqueueSnackbar(errors['company'], { variant: 'error' });
      return;
    }
    if (!freightTable) {
      enqueueSnackbar(errors['freightTable'], { variant: 'error' });
      return;
    }

    setSubmiting(true);

    apiService.reuseFreightTable(
      {
        company_id: company.id,
        freight_table_id: freightTable.id
      }
    ).then(() => {
      setSubmiting(false);
      setSuccess(true);
      setError(null);
    }).catch((res) => {
      setSubmiting(false);
      setSuccess(false);
      setError(res.message);
    })
  }

  useEffect(() => {
    apiService
      .getCompanies(true)
      .then((res) => setCompanies(res.data));
  }, []);

  useEffect(() => {
    if (company) {
      apiService
        .getFreightTables({
          company_id: company.id
        }, 1000, 1)
        .then((res) => setFreightTables(res.data.filter((tb) => tb.version == 3)));
    }

  }, [company]);


  return (
    <Page
      title='Tabela de Frete'
      className={classes.root}
    >
      <Container maxWidth={false}>
        <Toolbar title="Reaproveitar tabela de outro cliente" hideButtons />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>


              <FormControl variant="outlined" style={{ marginRight: 20, width: 500 }}>
                <Autocomplete
                  disablePortal
                  id="companies-list"

                  options={companies}
                  sx={{ width: 500 }}
                  onChange={(ev, company) => {
                    setCompany(company ? company : null);
                  }}
                  getOptionLabel={(company) => `${company.id} - ${company.name}`}
                  renderInput={(params) => <TextField variant="outlined" required {...params} label="Cliente" />}
                />
              </FormControl>



            </Grid>

            {company ? <Grid item xs={12}>

              <FormControl variant="outlined" style={{ marginRight: 20, width: 500 }}>
                <Autocomplete
                  disablePortal
                  id="freightTables-list"

                  options={freightTables}
                  sx={{ width: 500 }}
                  onChange={(ev, freightTable) => {
                    setFreightTable(freightTable ? freightTable : null);
                  }}
                  getOptionLabel={(freightTable) => `${freightTable.id} - ${freightTable.name} - ${freightTable.carrier.canonical_name} - (${freightTable.origin}) - ${freightTable.active ? 'Ativa' : 'Inativa'}`}
                  renderInput={(params) => <TextField variant="outlined" required {...params} label="Tabela de Frete" />}
                />
              </FormControl>

            </Grid> : null}

          </Grid>


        </Box>

        <Card>
          <CardContent>

            <Grid container>

              {success ? <Alert severity="success">
                <AlertTitle>Tabela cadastrada com sucesso</AlertTitle>

                A tabela foi clonada com sucesso, vá até a listagem de tabelas para ativar!</Alert> : null}
              <br />
              {error ? <Alert severity="error" style={{ color: 'red' }}>
                <AlertTitle>Oops! Ocorreu um erro.</AlertTitle>

                Encontramos o seguinte erro: {error}</Alert> : null}

              <br />

              <Button color="primary"
                size="large"
                type="submit"
                variant="contained"
                style={{ marginRight: 30, backgroundColor: '#2c3357', color: '#fff' }}
                disabled={submiting || !company || !freightTable}
                onClick={submitTable}>{submiting ? 'AGUARDE ...' : 'REAPROVEITAR TABELA'}</Button>

            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}


export default withSnackbar(ReuseView);
