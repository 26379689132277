import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import { CheckBox } from '@material-ui/icons';
import { isAdmin, isSuperUser } from 'src/common/auth';

const Form = ({ enqueueSnackbar, initialValues, onSubmit, submitText, title }) => {

  const [permissions, setPermissions] = useState(initialValues.permissions || []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255, 'Deve ter no máximo 255 caracteres').required('Digite o nome'),
        email: Yup.string().email('Digite um e-mail válido').max(255, 'Deve ter no máximo 255 caracteres').required('Obrigatório'),
        password: Yup.string('Digite uma senha').max(50, 'Deve ter no máximo 50 caracteres').required('Obrigatório'),
      })}
      onSubmit={(vals) => onSubmit(vals, permissions)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              {title ?? 'Novo'}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={3}>
              <Grid item lg={12}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  label="Nome"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="E-mail"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              {values.password !== false ? (
                <Grid item lg={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label="Senha"
                    margin="normal"
                    name="password"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
              ) : null}
            </Grid>
            {isAdmin() || isSuperUser() ? <Grid item lg={9}>
              <Typography variant="h6">Permissões</Typography>
              <Grid item lg={12}>


                <FormControl component="fieldset" margin="normal">

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.is_admin}
                        onChange={handleChange}
                        name="is_admin"
                        color="primary"
                      />
                    }
                    label="Administrador? - Acesso completo"
                  />

                </FormControl>

                <br />
                <Divider />
                <FormControl component="fieldset" margin="normal">

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={permissions.indexOf('dashboard') !== -1}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            setPermissions(permissions => [...permissions, 'dashboard']);
                          } else {
                            setPermissions(permissions.filter((item) => item !== 'dashboard'));
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Dashboard"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={permissions.indexOf('orders') !== -1}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            setPermissions(permissions => [...permissions, 'orders']);
                          } else {
                            setPermissions(permissions.filter((item) => item !== 'orders'));
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Pedidos"
                  />


                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={permissions.indexOf('pickups') !== -1}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            setPermissions(permissions => [...permissions, 'pickups']);
                          } else {
                            setPermissions(permissions.filter((item) => item !== 'pickups'));
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Coletas"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={permissions.indexOf('quotation') !== -1}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            setPermissions(permissions => [...permissions, 'quotation']);
                          } else {
                            setPermissions(permissions.filter((item) => item !== 'quotation'));
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Cotações"
                  />


                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={permissions.indexOf('settings') !== -1}
                        onChange={(ev) => {
                          if (ev.target.checked) {
                            setPermissions(permissions => [...permissions, 'settings']);
                          } else {
                            setPermissions(permissions.filter((item) => item !== 'settings'));
                          }
                        }}
                        color="primary"
                      />
                    }
                    label="Configurações"
                  />

                </FormControl>



              </Grid>
            </Grid> : null}
          </Grid>
          <Box my={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              {submitText ? submitText : "CADASTRAR"}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withSnackbar(Form);
