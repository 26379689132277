import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isSuperUser } from 'src/common/auth';
import { withSnackbar } from 'notistack';

import {
  Box,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  makeStyles,
  LinearProgress,
  withStyles,
  Checkbox,
  Grid,
  IconButton,
  Button
} from '@material-ui/core';

import {
  ArrowUp as ArrowUpIcon,
  ArrowDown as ArrowDownIcon,
  Check,
  CheckCircle,
  Eye,
  Download,
  Edit,
  Zap,
  DownloadCloud,
  ExternalLink,
} from 'react-feather';
import { Link } from 'react-router-dom';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  reference: {
    fontSize: '.8125rem',
    whiteSpace: 'nowrap',
    display: 'block',
    color: '#525f7f'
  },
  last_status: {
    fontSize: '22px !important',
    color: '#000',
    marginTop: 5,
    marginBottom: 7,
    display: 'flex',
    alignItems: 'center'
  },
  city: {
    fontSize: '22px !important',
    color: '#000',
    marginTop: 5,
    marginBottom: 7,
    display: 'flex'
  },
  date: {
    color: '#aaa',
    fontWeight: 'bold',
    fontSize: '13px'
  },
  iconUp: {
    padding: 5,
    color: '#fff',
    backgroundColor: '#000',
    bpickupRadius: '50%',
    marginRight: 10
  },
  iconDown: {
    padding: 5,
    color: '#fff',
    backgroundColor: '#fc0',
    bpickupRadius: '50%',
    marginRight: 10
  },
  progressbars: {
    width: '100%',
    display: 'flex',
    marginTop: 10,
    height: 10,
    background: '#cecece',
    borderRadius: '4px',
    overflow: 'hidden'
  },
  progressbar: {
    backgroundColor: '#8BC34A',
  },

}));

const FlixlogProgress = withStyles(() => ({
  colorPrimary: {
    backgroundColor: '#ddd'
  },
  bar: {
    backgroundColor: '#ffcc00',
  },
}))(LinearProgress);

const PickupRow = ({ className, pickup, handleSelectOne, openEditPickup, selectedRows, newUrl, hidePrice, hideOrigin, ...rest }) => {
  const classes = useStyles();
  const apiService = new ApiService();


  return (
    <TableRow
      hover
      key={pickup.id}
    >

      <TableCell>
        <span className={classes.reference} style={hideOrigin ? { whiteSpace: 'inherit' } : {}}>{pickup.id} - {pickup.pickup_date && !pickup.pickuped_at ? "Coleta Aceita" : (pickup.pickuped_at ? "Coleta Realizada" : "Aguardando Aceite")}{pickup.driver ? ` - Motorista ${pickup.driver}` : null}</span>
        <Typography
          color="textPrimary"
          variant="h2"
          className={classes.last_status}
        >
          {pickup.carrier.canonical_name.toUpperCase()} {pickup.pickuped_at ? <Tooltip
          enterDelay={300}
          title="Coleta Realizada"
        ><CheckCircle  style={{color: '#8bc34a', marginLeft: 5}} /></Tooltip> : null}

        </Typography>
        {pickup.pickup_date
          ? <date className={classes.date}>Previsão de coleta {moment(pickup.pickup_date).format('DD/MM/YYYY HH')}h</date>
          : <date className={classes.date}>Criado em {moment(pickup.created_at).format('DD/MM/YYYY HH')}h</date>}

        {pickup.pickuped_at
          ? <><br /><strong className={classes.date}>Coletado em {moment(pickup.pickuped_at).format('DD/MM/YYYY HH')}h</strong>  </>
          : null}

        {pickup.integration_code
          ? <><br /><div style={{display: 'flex', fontSize: '14px', fontWeight: 'bold',  marginTop: '12px'}}><Tooltip
          enterDelay={300}
          title={"Integrado ao sistema da transportadora em " + moment(pickup.integration_date).format('DD/MM/YYYY HH:mm:ss')}
        ><Zap  style={{color: '#00ceff', width: '19px', marginRight: '3px'}} /></Tooltip> Código: {pickup.integration_code}</div></>
          : null}  
      </TableCell>

      <TableCell>
        {isSuperUser() ? <Tooltip
          enterDelay={300}
          title="Editar Coleta"
        >
          <IconButton onClick={() => openEditPickup(pickup)}>
            <Edit />
          </IconButton>
        </Tooltip> : null}

        <Tooltip
          enterDelay={300}
          title="Visualizar pedidos"
        >


          <IconButton component={Link} to={`/orders?pickup=${pickup.id}`} >
            <Eye />
          </IconButton>
        </Tooltip>

        <Tooltip
          enterDelay={300}
          title="Gerar romaneio"
        >
          <IconButton onClick={() => apiService.downloadRomaneio(pickup.id)} >
            <ExternalLink />
          </IconButton>
        </Tooltip>

        {/* <Tooltip
          enterDelay={300}
          title="Baixar relatório de pedidos"
        >
          <IconButton> <Download /> </IconButton>
        </Tooltip> */}

      </TableCell>

      <TableCell>
        NFs: <strong>{pickup.totals.total}</strong> <br />
        Volumes: <strong>{pickup.volumes.total_volumes}</strong> <br />
        Valor da carga: <strong>{pickup.volumes.cargo_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>

      </TableCell>

      <TableCell>
        <Box className={classes.progressbars}>
          <div className={classes.progressbar} style={{ width: `${pickup.milestone}%` }} />
        </Box>
        <Grid container style={{ color: '#676767', marginTop: '10px' }}>
          <Grid item xs={4}><strong>{pickup.milestone}%</strong> completo</Grid>
          <Grid item xs={4}><strong>{pickup.totals.total}</strong> pedidos</Grid>
          <Grid item xs={4}><strong>{pickup.totals.delivered}</strong> entregues</Grid>
          <Grid item xs={4}><strong>{pickup.totals.in_transit}</strong> em trânsito</Grid>
        </Grid>
      </TableCell>

    </TableRow>
  )
};

PickupRow.propTypes = {
  className: PropTypes.string,
  pickup: PropTypes.object.isRequired
};

export default PickupRow;

