import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { Edit } from 'react-feather';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  successButton: {
    backgroundColor: '#8BC34A'
  }
}));



const EditPickup = props => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [estimated_date, setDate] = useState(props.estimated_date);
  const [estimated_time_start, setTimeStat] = useState('10:00');
  const [estimated_time_end, setTimeEnd] = useState('15:00');
  const [phone, setPhone] = useState(props.phone);
  const apiService = new ApiService();

  const send = () => {
    apiService
      .changePickupDate(props.reference, {
        estimated_date,
        estimated_time_start,
        estimated_time_end,
      }, props.isShipment)
      .then((res) => {
        props.props.enqueueSnackbar('Data de coleta alterada com sucesso', { 
          variant: 'success',
        });
        props.refreshOrder();
      });
  };

  return (
    <div> 
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"Cadastrar motorista de coleta"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container>
              <Grid xs={12} item>
                <TextField
                  type="date"
                  value={estimated_date}
                  onChange={(e) => setDate(e.target.value)}
                  label="Data da coleta"
                  variant="outlined"
                  style={{marginBottom: '30px'}}
                  InputLabelProps={{
                    shrink: true,
                  }}/>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={6} item>
                <TextField
                  type="time"
                  value={estimated_time_start}
                  onChange={(e) => setTimeStat(e.target.value)}
                  label="Hora inicial"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}/>
              </Grid>
              <Grid xs={6} item>
                <TextField
                  type="time"
                  value={estimated_time_end}
                  onChange={(e) => setTimeEnd(e.target.value)}
                  label="Hora final"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}/>
              </Grid>

            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fechar
          </Button>
          <Button onClick={() => send()} color="primary" autoFocus>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Button color="primary"
          variant="contained"
          onClick={() => setOpenDialog(true)}
          className={classes.successButton}><Edit /> {props.label ? props.label : "Editar"}</Button>
    </div>
  );
};

export default EditPickup;
