import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import Header from 'src/layouts/DashboardLayout/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  }
}));

const DistributionCenterView = () => {
  const classes = useStyles();
  const [cds, setCds] = useState([]);

  const reloadList = (filters) => {
    const apiService = new ApiService();
    apiService.getDistributionCenters(filters).then((res) => {
      setCds(res.data);
    });
  };

  useEffect(() => {
    reloadList();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Centros de Distribuição - Flixlog"
    >
      <Header
        title="Centros de Distribuição"
        description="Aqui você encontra a listagem de Centros de Distribuição da sua operação."
        // help="https://flixlog.readme.io/"
        breadcrumbs={[
          {
            text: 'Início',
            link: '/'
          },
          {
            text: 'Centros de Distribuição',
            link: '/distribution-centers'
          }
        ]}
        buttons={[
          {
            text: 'Cadastrar CD',
            link: '/settings/distribution-centers/new'
          }
        ]}
      />

      <Container maxWidth={false}>
        <Box mt={3}>
          <Results cds={cds} />
        </Box>
      </Container>
    </Page>
  );
};

export default DistributionCenterView;
