import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import InputMask from "react-input-mask";
import moment from 'moment';
import queryString from 'query-string';

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  SwipeableDrawer,
  Grid,
  Table,
  TableBody,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import OrderRow from '../orders/OrdersView/OrderRow';
import Recaptcha from 'react-recaptcha';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const script = document.createElement('script');
script.src = 'https://www.google.com/recaptcha/api.js';
script.async = true;
script.defer = true;
document.body.appendChild(script);

const TrackingCompanyView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const apiService = new ApiService();
  const [pessoa, setPessoa] = useState(1);
  const [orders, setOrders] = useState([]);
  const [recaptchaInstance, setRecaptchaInstance] = useState(null);
  const [key, setKey] = useState(0);
  const { company } = useParams();

  const { ref } = queryString.parse(window.location.search);

  useEffect(() => {
    if (orders.length === 0 && ref && company) {
      apiService
        .trackingCompany(company, ref)
        .then((res) => {
          setOrders([res]);
        }).catch((error) => {
          enqueueSnackbar(error.message ? error.message : 'Não foi encontrado nenhum pedido com o documento informado', {
            variant: 'error',
          });
        });
    }
  }, [ref, company]);

  return (
    <Page
      className={classes.root}
      title="Rastrear pedido"
    >
      <SwipeableDrawer
        anchor="right"
        open={orders.length}
        onClose={() => setOrders([])}
      >
        <Box style={{ width: '50vw' }} className="drawer1">
          <Grid container>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  {orders.map((order) => <OrderRow order={order} fullWidth newUrl={`/tracking/${order.tracker.tracking_code}`} hidePrice hideOrigin />)}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>

      {company && ref ? (
        <SwipeableDrawer
          anchor="right"
          open={orders.length}
          onClose={() => setOrders([])}
        >
          <Box style={{ width: '40vw' }} className="drawer2">
            <Grid container>
              <Grid item xs={12}>

                {orders
                  .map((order) => (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <Card style={{ backgroundColor: '#eee', padding: 17 }}>
                            <Typography color="textPrimary" variant="h4" style={{ fontSize: 15 }}>Pedido {order.company.name} - Previsão de entrega {order.delivery ? moment(order.delivery.delivery_date).format('DD/MM/YYYY') : null}</Typography>
                          </Card>
                        </Grid>
                      </Grid>
                      <Table>
                        <TableBody>
                          <OrderRow order={order} fullWidth newUrl={`/tracking/${order.tracker.tracking_code}`} hidePrice hideOrigin />
                        </TableBody>
                      </Table>
                      <Grid container>
                        <Grid item xs={12}>
                          <Card style={{ backgroundColor: '#eee', boxShadow: '0px 0px 10px #ddd' }}>
                            <CardContent>
                              <Typography color="textPrimary" variant="h4" style={{ fontSize: 15 }}>Quem está transportando:</Typography>
                              <Typography color="textPrimary" variant="h4" style={{ fontSize: 13 }}>{order.carrier.name}</Typography>

                              <p className={classes.content} style={{ fontSize: 12, marginTop: 5 }}>
                                <strong>CNPJ:</strong> {order.carrier.federal_tax_id}<br />
                                <strong>Telefone:</strong> <a href={`tel:+55` + order.carrier.phone}>{order.carrier.phone}</a><br />
                              </p>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12}>
                          <Card style={{ backgroundColor: '#eee', boxShadow: '0px 0px 10px #ddd' }}>
                            <CardContent>
                              <Typography color="textPrimary" variant="h4" style={{ fontSize: 15 }}>Endereço de entrega:</Typography>
                              <br />
                              <p className={classes.content}>
                                <strong>CEP:</strong> {order.to_address.zip} <br />
                                <strong>Endereço:</strong> {order.to_address.street1}, {order.to_address.number}, {order.to_address.street2} <br />
                                <strong>Bairro:</strong> {order.to_address.neighborhood} <br />
                                <strong>Cidade/UF:</strong> {order.to_address.city}/{order.to_address.state} <br />
                              </p>
                            </CardContent>
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Card>
                            <CardHeader title="Ocorrências" />
                            <CardContent>
                              <ul class="timeline">
                                {order.tracker ? order.tracker.details
                                  .filter((detail) => {
                                    return detail.code !== '200' && detail.code !== '201';
                                  })
                                  .map((detail) => {
                                    return (<li class={"status-" + detail.status}>
                                      <span>
                                        {detail.description ? (<span>{detail.description}<br /></span>) : null}
                                        <small style={{ color: '#5a5a5a', fontWeight: 'normal' }}>{detail.message}</small>
                                      </span>
                                      <span class="float-right">{moment(detail.occurred_at).format('DD/MM/YYYY HH')}h</span>
                                    </li>);
                                  }) : 'null'}

                                  {order.tracker && order.tracker.details.length == 0 ? <li>Ainda sem movimentação da carga</li> : null}

                              </ul>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Grid>
          </Box>
        </SwipeableDrawer>
      ) : null}
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              federal_tax_id: '',
              tracking_code: '',
              recaptcha: ''
            }}
            validationSchema={
              Yup.object().shape({
                federal_tax_id: Yup.string().max(255),
                tracking_code: Yup.string().max(255),
                recaptcha: Yup.string().required('Insira a resposta do recaptcha'),
              })
            }
            onSubmit={(values) => {
              if (values.tracking_code) {
                window.location.href = `/tracking/${values.tracking_code}`;
                return;
              }
              return apiService
                .getOrdersFromFederalTaxId(values.federal_tax_id, values.recaptcha)
                .then((res) => {
                  if (!res.length) {
                    enqueueSnackbar('Não foi encontrado nenhum pedido com o documento informado', {
                      variant: 'error',
                    });
                  } else {
                    enqueueSnackbar('Eba, encontramos algo.', {
                      variant: 'success',
                    });
                  }
                  setKey(key + 1);
                  setOrders(res);
                  navigate('/tracking');
                }).catch(() => {
                  enqueueSnackbar('Não foi encontrado nenhum pedido com o documento informado', {
                    variant: 'error',
                  });
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Olá! <br />
                      Acompanhe o envio do seu pedido pelo rastreio da Flixlog. 😃
                    </Typography>
                </Box>

                <FormControl component="fieldset" margin="normal">
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel margin="normal" value={1} checked={pessoa === 1} onChange={() => setPessoa(1)} control={<Radio color="primary" />} label="PF" />
                    <FormControlLabel margin="normal" value={2} checked={pessoa === 2} onChange={() => setPessoa(2)} control={<Radio color="primary" />} label="PJ" />
                    <FormControlLabel margin="normal" value={3} checked={pessoa === 3} onChange={() => setPessoa(3)} control={<Radio color="primary" />} label="Código de Rastreio" />
                  </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth>
                  {pessoa === 1 || pessoa === 2 ? (
                    <InputMask
                      mask={pessoa === 1 ? '999.999.999-99' : (pessoa === 2 ? '99.999.999/9999-99' : '')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.federal_tax_id && errors.federal_tax_id)}
                      helperText={touched.federal_tax_id && errors.federal_tax_id}
                      value={values.federal_tax_id}

                    >
                      {() => (
                        <TextField
                          label={pessoa === 1 ? 'Digite o CPF' : (pessoa === 2 ? 'Digite o CNPJ' : 'Digite o código de rastreio')}
                          margin="normal"
                          name="federal_tax_id"
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  ) : (

                      <TextField
                        label={'Digite o código de rastreio'}
                        margin="normal"
                        name="tracking_code"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.tracking_code && errors.tracking_code)}
                        helperText={touched.tracking_code && errors.tracking_code}
                        value={values.tracking_code}
                      />

                    )}
                </FormControl>

                <FormControl component="fieldSet" fullWidth>
                  <Recaptcha
                    key={key}
                    sitekey="6Le9dOQZAAAAAK1KaAKl3KV7S19bUsbVluwJuogd"
                    render="explicit"
                    theme="dark"
                    verifyCallback={(response) => { setFieldValue('recaptcha', response); }}
                    onloadCallback={() => { console.log("done loading!"); }}
                  />
                  {errors.recaptcha
                    && touched.recaptcha && (
                      <p>{errors.recaptcha}</p>
                    )}
                </FormControl>

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    RASTREAR
                    </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page >
  );
};

export default withSnackbar(TrackingCompanyView);
