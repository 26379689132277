import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Hidden, makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import ApiService from 'src/common/apiService';
import { getActualCompany, login, setActualCompany } from 'src/common/auth';

import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 215
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

const changeCompany = (cmp) => {
  const apiService = new ApiService();
  apiService
    .generateCompanyToken(cmp.id)
    .then((res) => {
      if (window.confirm('Deseja alterar para a conta: ' + res.company.name)) {
        login(res.token);
        setActualCompany(res.company);
        window.location.reload();
      }
    });
};



const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [onboardingCompleted, setOnboardingCompleted] = useState(true);
  const apiService = new ApiService();

  const inadimplentes = {};

  const estaInadimplente = inadimplentes[getActualCompany().id] || 0;

  const verifyIfOnboardingIsCompleted = () => {
    apiService
      .onboarding()
      .then((res) => {
        setOnboardingCompleted(res.is_complete);
      });
  }

  if (getQueryVariable('cc') && getQueryVariable('cc') != getActualCompany().id) {
    changeCompany({
      id: getQueryVariable('cc')
    });
  }


  const userData = JSON.parse(localStorage.getItem('@flixlog/me'));



  useEffect(() => {
    //verifyIfOnboardingIsCompleted();
  }, []);

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </Hidden>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        onboardingCompleted={onboardingCompleted}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>


            {estaInadimplente ? <Alert severity="error" style={{ color: 'red' }}>
              <AlertTitle>Falha na cobrança</AlertTitle>

              Sua conta possui {estaInadimplente} faturas em aberto. Regularize para não perder acesso ao seu painel!</Alert> : null}

            <Outlet />

          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
