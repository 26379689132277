import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Form from './Form';
import Page from 'src/components/Page';
import ApiService from 'src/common/apiService';
import Results from './Results';
import Toolbar from './Toolbar';
import { Formik } from 'formik';
import { getActualCompany } from 'src/common/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const NewOrderView = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cds, setCds] = useState([]);

  const reloadCdList = (filters) => {
    const apiService = new ApiService();
    apiService.getDistributionCenters(filters).then((res) => {
      setCds(res.data);
    });
  };

  useEffect(() => {
    reloadCdList();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Novo Pedido"
    >
      <Container maxWidth={false}>
        <Box>

          <Form
            cds={cds}
            initialValues={{
              reference: '',
              address_residential: 1,
              from_cd_reference: '',
              address_name: '',
              address_federal_tax_id: '',
              address_phone: '',
              address_email: '',
              address_zip: '',
              address_street1: '',
              address_number: '',
              address_street2: '',
              address_neighborhood: '',
              address_city: '',
              address_state: '',
              address_country: 'BRA',
            }}
            submitText="CADASTRAR"
            onSubmit={(values, parcels, products) => {
              var hasParcel = parcels.filter((parcel) => {
                if (products[parcel.reference]) {
                  return true;
                }
                return parcel.width && parcel.length && parcel.height && parcel.weight && parcel.cargo_value && parcel.quantity;
              });

              if (!hasParcel.length) {
                enqueueSnackbar('Preencha todos os dados dos volumes.', {
                  variant: 'error',
                });
                return true;
              }

              if (values.is_drop_shipping) {
                var hasParcelWithoutOrigin = parcels.filter((parcel) => {
                  if (!parcel.from_cd_reference) {
                    return true;
                  }
                  return false;
                });

                if (hasParcelWithoutOrigin.length) {
                  enqueueSnackbar('Selecione a origem de cada volume!', {
                    variant: 'error',
                  });
                  return true;
                }
              }

              const apiService = new ApiService();

              return apiService
                .newOrder({
                  reference: values.reference,
                  from_cd_reference: values.from_cd_reference,
                  shipments: parcels.map((parcel) => {
                    if (!parcel['reference']) {
                      delete parcel['reference'];
                    }
                    
                    return {
                      reference: parcel['reference'],
                      from_cd_reference: parcel['from_cd_reference'],
                      quantity: parseFloat(parcel['quantity']),
                      width: parseFloat(parcel['width'] / 100),
                      height: parseFloat(parcel['height'] / 100),
                      length: parseFloat(parcel['length'] / 100),
                      weight: parseFloat(parcel['weight']),
                      cargo_value: parseFloat(parcel['cargo_value'])
                    };
                  }),
                  to_address: {
                    residential: values.address_residential,
                    name: values.address_name,
                    federal_tax_id: values.address_federal_tax_id,
                    phone: values.address_phone,
                    email: values.address_email,
                    zip: values.address_zip,
                    street1: values.address_street1,
                    number: values.address_number,
                    street2: values.address_street2,
                    neighborhood: values.address_neighborhood,
                    city: values.address_city,
                    state: values.address_state,
                    country: 'BRA'
                  }
                })
                .then((res) => {
                  enqueueSnackbar('Pedido cadastrado com sucesso', {
                    variant: 'success',
                  });
                  localStorage.removeItem('lastQuote');
                  navigate('/orders');
                })
                .catch((res) => {
                  enqueueSnackbar(`Ocorreu um erro: ${res.message}`, {
                    variant: 'error',
                  });
                });
            }}
          />

        </Box>
      </Container>
    </Page>
  );
};

export default withSnackbar(NewOrderView);
