import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { JsonToTable } from "react-json-to-table";

import {
  Box,
  Card,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Tooltip
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Clock, Edit, Eye, RefreshCw, Trash } from 'react-feather';
import { Link } from 'react-router-dom';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, backgroundTasks, ...rest }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [form, setForm] = useState(null);

  const previewTaskResult = (task) => {
    setOpenDrawer(true);
    setForm(<JsonToTable json={task.property} />);
  };

  const downloadFile = (path) => {
    const apiService = new ApiService();
    apiService.download(path).then((res) => console.log(res));
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box mt={3} style={{ minWidth: 300, margin: 30 }}>
          {form}
        </Box>
      </Drawer>

      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Tipo de tabela
                </TableCell>
                <TableCell>
                  Solicitado por
                </TableCell>
                <TableCell>
                  Data de solicitação
                </TableCell>
                <TableCell>
                  Data de início do processamento
                </TableCell>
                <TableCell>
                  Data de Término
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Detalhes
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {backgroundTasks.map((task) => (
                <TableRow
                  hover
                  key={task.id}
                >
                  <TableCell>
                    {task.id}
                  </TableCell>
                  <TableCell>
                    {task.property.type}
                  </TableCell>
                  <TableCell>
                    {task.user.name}
                  </TableCell>
                  <TableCell>
                    {moment(task.created_at).format('DD/MM/YYYY HH:mm')}
                  </TableCell>
                  <TableCell>
                    {task.start_at ? moment(task.start_at).format('DD/MM/YYYY HH:mm') : 'Aguarde o início'}
                  </TableCell>
                  <TableCell>
                    {task.end_at ? moment(task.end_at).format('DD/MM/YYYY HH:mm') : (task.start_at ? 'Aguarde a finalização' : '-')}
                  </TableCell>
                  <TableCell>
                    {!task.start_at && !task.end_at ? <Tooltip enterDelay={300} title="Pendente: Aguardando início" key={task.id}><Clock /></Tooltip> : null}
                    {task.start_at && !task.end_at ? <Tooltip enterDelay={300} title="Processo iniciado, aguarde a finalização" key={task.id}><RefreshCw className="rotate-center" /></Tooltip> : null}
                    {task.start_at && task.end_at ? <Button
                      color="primary"
                      onClick={(ev) => downloadFile(task.property.file_path)}
                      variant="contained"
                    >
                      BAIXAR TABELA
                    </Button> : null}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => previewTaskResult(task)} title="Detalhes">
                      <Eye />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

              {!backgroundTasks.length ? (
                <TableRow
                  hover
                >
                  <TableCell colSpan={8}>
                    Nenhuma tabela solicitada até o momento
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
