import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Switch
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import { Edit, Trash } from 'react-feather';
import { Link } from 'react-router-dom';
import ApiService from 'src/common/apiService';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));


const Results = ({ className, rules, toggleActive, loading, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  #
                </TableCell>
                <TableCell>
                  Canal
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Início da Vigência
                </TableCell>
                <TableCell>
                  Término da Vigência
                </TableCell>
                <TableCell>
                  Total de Regras
                </TableCell>
                <TableCell>
                  Ativar/Desativar
                </TableCell>
                <TableCell>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.map((rule) => (
                <TableRow
                  hover
                  key={rule.id}
                >
                  <TableCell>
                    {rule.id}
                  </TableCell>
                  <TableCell>
                    {rule.channel ? <Avatar
                      alt="channel"
                      src={rule.channel.icon}
                      variant="square"
                    /> : 'Todos'}
                  </TableCell>
                  <TableCell>
                    {rule.name}
                  </TableCell>
                  <TableCell>
                    {moment(rule.start_at).format('DD/MM/YYYY HH:mm')}
                  </TableCell>
                  <TableCell>
                    {rule.end_at ? moment(rule.end_at).format('DD/MM/YYYY HH:mm') : 'Indeterminado'}
                  </TableCell>
                  <TableCell>
                    {rule.total_rules}
                  </TableCell>
                  <TableCell>
                    
                    <Switch
                     disabled={loading}
                     checked={rule.enabled}
                     onChange={() => {
                      toggleActive(rule);
                    }} />
                    
                  </TableCell>
                  <TableCell>
                    <Button component={Link} to={"/settings/freight-rules/edit/" + rule.id}>
                      <Edit />
                    </Button>
                    <Button component={Link} to={"/settings/freight-rules/remove/" + rule.id}>
                      <Trash />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

              {!rules.length ? (
                <TableRow
                  hover
                >
                  <TableCell colSpan={6}>
                    Nenhuma regra de frete cadastrada
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
