import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
  Box
} from '@material-ui/core';
import ApiService from 'src/common/apiService';
import DataTable from '../../../../layouts/DashboardLayout/DataTables';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StatusIcon from 'src/layouts/DashboardLayout/DataTables/StatusIcon';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { ArrowDown, ArrowUp, ExternalLink, Flag, MapPin } from 'react-feather';
import { LoadingButton } from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import AvatarGroup from '@atlaskit/avatar-group';
import { FlagRounded, WarningRounded } from '@material-ui/icons';
import EditView from './Edit.js';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  link: {
    fontWeight: 'bold',
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  variationBad: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'red'
  },
  variationGood: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'green'
  }
}));

const CteList = (props) => {
  const classes = useStyles();
  const apiService = new ApiService();
  const [ctes, setCtes] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, changeLimit] = useState(props.limit ? props.limit : 20);
  const [page, changePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(props.filters ? props.filters : {wo: 1});
  const [carriers, setCarriers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  const [highlightedPickup, setHighlightedPickup] = useState(null);
  const [window, setWindow] = useState(null);

  const [selectedRows, setSelectedRowsIds] = useState(props.selectedRows ? props.selectedRows : []);

  const handleSelectAll = (event) => {
    let rowIds;
    let entities = [];

    if (event.target.checked) {
      rowIds = ctes.map((order) => order.id);
      entities = ctes;
    } else {
      rowIds = [];
      entities = [];
    }

    setSelectedRowsIds(rowIds);
    if (props.onSelect) {
      props.onSelect(rowIds);
    }
    if (props.onSelectEntities) {
      props.onSelectEntities(entities);
    }
  };

  const handleSelectOne = (id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let rowIds = [];

    if (selectedIndex === -1) {
      rowIds = rowIds.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      rowIds = rowIds.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      rowIds = rowIds.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      rowIds = rowIds.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRowsIds(rowIds);

    if (props.onSelect) {
      props.onSelect(rowIds);
    }

    if (props.onSelectEntities) {
      props.onSelectEntities(ctes.filter((order) => rowIds.indexOf(order.id) !== -1));
    }
  };


  const [statuses, setStatuses] = useState([
    { value: 12, label: 'Aguardando Cotação', badge: 'removed' },
    { value: 11, label: 'Aguardando Aprovação', badge: 'moved' },
    { value: 1, label: 'Aguardando Nota', badge: 'new' },
    { value: 2, label: 'Aguardando aceite', badge: 'inprogress' },
    { value: 3, label: 'Em trânsito', badge: 'success' },
    { value: 4, label: 'Saiu para entrega', badge: 'success' },
    { value: 5, label: 'Entregue', badge: 'success' },
    { value: 6, label: 'Coleta aceita', badge: 'success' },
    { value: 7, label: 'Retornando', badge: 'moved' },
    { value: 8, label: 'Falha', badge: 'removed' },
    { value: 9, label: 'Cancelado', badge: undefined },
    { value: 10, label: 'Erro', badge: 'removed' },
  ]);

  const diffStatus = [
    { value: 'up', label: 'Diferença de frete para cima' },
    { value: 'down', label: 'Diferença de frete para baixo' },
    { value: 'no_diff', label: 'Frete exato' },
  ];

  const handleFilters = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  }

  const getCarriers = () => {
    apiService
      .getCarriers(true, true)
      .then((res) => setCarriers(res.data));
  };

  const getCompanies = () => {
    apiService
      .getCompanies()
      .then((res) => setCompanies(res.data));
  };

  const reloadCtes = (filters, sort) => {
    filters['wo'] = 1;
    setIsLoading(true);
    apiService
      .getCtes(filters, limit, page)
      .then((res) => {
        setCtes(res.data);
        setTotalRows(res.total);
        setIsLoading(false);
      });
  };


  useEffect(() => {
    reloadCtes(filters);
  }, [filters, limit, page, props.reloadCtes]);

  useEffect(() => {
    getCarriers(true);
    getCompanies();
  }, []);

  return (
    <>

      <DataTable
        rows={ctes}
        caption={props.caption}
        filters={
          [
            {
              placeholder: 'Buscar por CTE, Pedido ou Nota',
              type: 'search',
              filter: 'reference',
              // onBlur: handleFilters,
              onEnter: handleFilters,
            },
            {
              placeholder: 'Transportadora',
              type: 'select',
              filter: 'carrier',
              options: carriers.map((carrier) => ({ value: carrier.id, label: `${carrier.id} - ${carrier.canonical_name.toUpperCase()}` })),
              onChange: handleFilters
            }
          ].filter(res => res)
        }
        head={{
          key: `table-order`,
          isHighlighted: false,
          cells: [
            {
              key: 'cte',
              content: 'CTE',
              isSortable: false,
            },
            {
              key: 'carrier',
              content: 'Transportadora',
              isSortable: false
            },
            {
              key: 'addressee',
              content: 'Destinatário',
              isSortable: false,
              width: 20
            },
            {
              key: 'estimated_cost',
              width: 20,
              content: 'FRT. Cobrado',
              isSortable: false
            }, {
              key: 'cargo_value',
              width: 20,
              content: 'NF. Declarado',
              isSortable: false
            },
            {
              key: 'weight',
              width: 20,
              content: 'P. Cobrado',
              isSortable: false
            },
            {
              key: 'created_at',
              content: 'Importado em',
              isSortable: false
            },
            {
              key: 'actions',
              content: '',
              isSortable: false
            }
          ].filter(res => res)
        }}
        rows={ctes.map((cte, index) => ({
          key: `row-${index}-${cte.id}`,
          isHighlighted: highlightedPickup == cte.id,
          cells: [
            {
              key: cte.id,
              content: <Tooltip title={"Número do CTE"}><strong>{cte.number}</strong></Tooltip>
            },
            {
              key: cte.carrier.canonical_name,
              content: <strong>{cte.carrier.canonical_name.toUpperCase()}</strong>
            },
            {
              key: cte.to_address,
              content: <>
                <Tooltip title={cte.to_address.name}><strong style={{ display: 'block', width: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cte.to_address.name}</strong></Tooltip>
                <small style={{ display: 'flex', alignItems: 'center' }}><MapPin style={{ width: 10, marginRight: 5 }} /> {cte.to_address.city} / {cte.to_address.state}</small>
              </>
            },
            {
              key: 'estimated_cost',
              content: <>
                {cte.freight_value ? <Tooltip title={"Valor do frete cobrado pela transportadora."}><span>T: <strong>{cte.freight_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></span></Tooltip> : null}
              </>
            },

            {
              key: 'cargo_value',
              content: <>
                {cte.amount ? <Tooltip title={"Valor de nota declarado pela transportadora."}><span>T: {cte.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></Tooltip> : ' - '}
              </>
            },
            {
              key: 'weight',
              content: <>
                {cte.total_weight ? <Tooltip title={"Peso declarado pela transportadora."}><span>T: {cte.total_weight.toLocaleString('pt-BR') + 'KG'}</span></Tooltip> : ' - '}
              </>
            },
            {
              key: 'created_at',
              content: <Tooltip title={moment(cte.created_at).format()}><span>{moment(cte.created_at).format('DD/MM HH:mm')}</span></Tooltip>
            },
            {
              key: 'actions',
              content: <> </>
            }
          ].filter(res => res)
        }))}
        limit={limit}
        page={page}
        height={props.height ? props.height : 500}
        changePage={changePage}
        totalRows={totalRows}
        isLoading={isLoading}
        window={window}
        onCloseWindow={() => setWindow(null)}
      />
    </>
  );
};


export default CteList;
