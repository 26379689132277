import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItem,
  ListItemIcon,
  Divider,
  List,
  ListItemText,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Input,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  TextareaAutosize
} from '@material-ui/core';
import { Filter, Search as SearchIcon } from 'react-feather';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changeOrderSearch, changeOrderSearchStatus, changeOrderSearchCarrier } from 'src/actions';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ApiService from 'src/common/apiService';
import { isSuperUser, isAdmin } from 'src/common/auth';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Sync } from '@material-ui/icons';
import {DelayInput} from 'react-delay-input';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  search: {
    marginLeft: 20,
    '& input': {
      paddingBottom: 10,
      paddingTop: 10
    }
  }
}));

const Toolbar = ({ changeOrderSearch, miniVersion, changeOrderSearchStatus, changeOrderSearchCarrier, className, reloadOrders, selectedRows, enqueueSnackbar, ...rest }) => {
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [carrier, setCarrier] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [estimatedDate, setDate] = useState(null);
  const [estimatedTimeStart, setTimeStat] = useState('10:00');
  const [estimatedTimeEnd, setTimeEnd] = useState('15:00');
  const [deliveryDate, setDeliveryDate] = useState('15:00');
  const [driver, setDriver] = useState(null);
  const apiService = new ApiService();
  const [statuses, setStatuses] = useState([
	
    { id: 1, name: 'Aguardando Nota' },
    { id: 2, name: 'Com NF ou Aguardando aceite' },
    { id: 3, name: 'Em trânsito' },
    { id: 4, name: 'Saiu para entrega' },
    { id: 5, name: 'Entregue' },
	{ id: 14, name: 'Devolvido'},
    { id: 6, name: 'Coleta aceita' },
    { id: 7, name: 'Retornando' },
    { id: 8, name: 'Falha' },
    { id: 9, name: 'Cancelado' },

    { id: 12, name: 'Aguardando Cotação' },
    { id: 11, name: 'Aguardando Aprovação' },
    { id: 10, name: 'Erro' },
  ]);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    if (miniVersion) {
      setStatuses([
        { id: 1, name: 'Aguardando Nota' },
        { id: 2, name: 'Aguardando aceite', selected: true },
        { id: 6, name: 'Coleta aceita' },
      ]);
    }

    getCarriers();
  }, []);

  const changeStatus = (ev) => {
    setStatus(ev.target.value);
    changeOrderSearchStatus(ev.target.value);
  };

  const getCarriers = () => {
    apiService
      .getCarriers()
      .then((res) => setCarriers(res.data));
  };

  const changeCarrier = (ev) => {
    setCarrier(ev.target.value);
    changeOrderSearchCarrier(ev.target.value);
  };

  const updateDeliveryInfo = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .changeDeliveryDate(reference, deliveryDate)
          .then((res) => {
            enqueueSnackbar(reference + ' - Data de entrega alterada com sucesso', {
              variant: 'success',
            });
          }).catch(() => {
            enqueueSnackbar(reference + ' - Erro ao alterar data de entrega', {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };


  const confirmShipment = () => {
    selectedRows.forEach((reference) => {
      apiService
        .confirmShipment(reference)
        .then((res) => {
          enqueueSnackbar(reference + ' - Remessa confirmada com sucesso!', {
            variant: 'success',
          });
        }).catch((error) => {
          enqueueSnackbar(reference + ' - ' + error.message, {
            variant: 'error',
          });
        });
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const cancelShipment = () => {
    selectedRows.forEach((reference) => {
      apiService
        .cancelOrder(reference)
        .then((res) => {
          enqueueSnackbar(reference + ' - Remessa cancelada com sucesso!', {
            variant: 'success',
          });
        }).catch((error) => {
          enqueueSnackbar(reference + ' - ' + error.message, {
            variant: 'error',
          });
        });
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const goOutDelivery = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .goOutDelivery(reference)
          .then((res) => {
            enqueueSnackbar(reference + ' - Pedido saiu para entrega!', {
              variant: 'success',
            });
          }).catch((error) => {
            enqueueSnackbar(reference + ' - ' + error.message, {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };

  const deliver = () => {
    selectedRows.forEach((reference) => {
      if (deliveryDate) {
        apiService
          .deliver(reference, true)
          .then((res) => {
            enqueueSnackbar(reference + ' - Pedido entregue com sucesso!', {
              variant: 'success',
            });
          }).catch((error) => {
            enqueueSnackbar(reference + ' - ' + error.message, {
              variant: 'error',
            });
          });
      }
    });

    setTimeout(() => {
      reloadOrders();
    }, 1000);
  };


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <DialogTitle>{"Cole abaixo as informações para busca, uma por linha"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextareaAutosize onChange={(e) => setSearchData(e.target.value)}></TextareaAutosize>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={(e) => changeOrderSearch(searchData.split("\n").join('|'), true)} >
              Filtrar
            </Button>
            <Button onClick={() => setOpenModal(false)} color="primary" autoFocus>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Typography
          color="textPrimary"
          variant="h1"
          className={classes.title}
        >{miniVersion ? '' : "Pedidos "}

          <DelayInput
            minLength={2}
            delayTimeout={300}
            placeholder="Buscar..."
            variant="outlined"
            style={{padding: 10}}
            onChange={ev => changeOrderSearch(ev.target.value, false)} />

          {/* <TextField
            className={classes.search}
            onChange={(ev) => changeOrderSearch(ev.target.value, false)}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Buscar..."
            variant="outlined"
          /> */}


          <IconButton>
            <Filter onClick={() => setOpenModal(true)} />
          </IconButton>


        </Typography>

        <FormControl variant="outlined" style={{ width: '150px' }} >
          <InputLabel margin="normal">Status</InputLabel>
          <Select
            value={status}
            label="Status"
            margin="normal"
            onChange={changeStatus}
            style={{ float: 'right', marginLeft: 20 }}
            input={<Input />}
          >
            {miniVersion ? null : <MenuItem key={0} value={0} selected={true} >
              Todos
            </MenuItem>}
            {statuses.map((val) => (
              <MenuItem key={val.id} value={val.id} selected={val.selected} >
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" style={{ width: '200px', marginRight: 20 }} >
          <InputLabel margin="normal">Transportadora</InputLabel>
          <Select
            value={carrier}
            label="Transportadora"
            margin="normal"
            onChange={changeCarrier}
            style={{ float: 'right', marginLeft: 20 }}
            input={<Input />}
          >
            {miniVersion ? null : <MenuItem key={0} value={0} selected={true} >
              Todas
            </MenuItem>}
            {carriers.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.canonical_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {miniVersion ? null : <>
          {/* <Button
            color="default"
            variant="contained"
            style={{ marginRight: 20 }}
            onClick={() => setOpenDrawer(true)}
          >
            <Sync /> SINCRONIZAR
          </Button> */}
          <Button
            color="default"
            variant="contained"
            style={{ marginRight: 20 }}
            onClick={() => setOpenDrawer(true)}
            disabled={!selectedRows.length}
          >
            ALTERAR REMESSAS
          </Button>
          <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <PerfectScrollbar>
              <Grid container>
                {isSuperUser()
                  ? (
                    <Grid xs={6} item>
                      <Card style={{ minWidth: 300 }}>
                        <CardHeader title="Dados de Entrega" />
                        <CardContent>
                          <Grid container>
                            <Grid xs={12} item>
                              <TextField
                                type="date"
                                onChange={(e) => setDeliveryDate(e.target.value)}
                                label="Data de Entrega"
                                variant="outlined"
                                style={{ marginBottom: '30px' }}
                                InputLabelProps={{
                                  shrink: true,
                                }} />
                            </Grid>
                            <Grid xs={12} item>
                              <Button color="success" variant="contained" onClick={updateDeliveryInfo}>Salvar</Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ) : null}
              </Grid>

              <Card style={{ minWidth: 300 }}>
                <CardHeader title="Alterar Status" />
                <CardContent>
                  <Grid container>
                    <Grid xs={12} item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => confirmShipment()}
                      >
                        CONFIRMAR REMESSA
                      </Button>


                      <br />

                      {isSuperUser()
                        ? (
                          <>
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginTop: 20 }}
                              onClick={() => goOutDelivery()}
                            >
                              SAIU PARA ENTREGA
                            </Button>
                            <br /></>) : null}
	                        {isAdmin() || isSuperUser()
	                          ? (
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginTop: 20 }}
                              onClick={() => deliver()}
                            >
                              MARCAR COMO ENTREGUE
							  </Button>) : null}
                          
                        

                      <Divider style={{ marginTop: '30px' }} />
                      <br />
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ backgroundColor: '#f30b0b' }}
                        onClick={() => cancelShipment()}
                      >
                        CANCELAR REMESSA
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </PerfectScrollbar>
          </Drawer>
        </>}

        {miniVersion ? null : <Button
          color="default"
          variant="contained"
          style={{ marginRight: 20 }}
          component={Link}
          to="/orders/import"
        >
          IMPORTAR NOTAS
        </Button>}

        {miniVersion ? null : <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/orders/new"
        >
          CADASTRAR PEDIDO
        </Button>}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeOrderSearch, changeOrderSearchStatus, changeOrderSearchCarrier }, dispatch);
};

export default connect(null, mapDispatchToProps)(withSnackbar(Toolbar));
